import React, { Fragment, useContext, useState, useEffect, useRef, createContext, Component } from "react";
import { useHistory } from "react-router-dom";
import { Modal, Row, Col, Card, Tooltip, Table, Divider, Button, Input, Menu, Spin, Badge, message, Select, DatePicker, Form, Tabs } from "antd";
import {
  ShoppingCartOutlined,
  CloseOutlined,
  LoadingOutlined,
  CloseSquareFilled,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import "react-aspect-ratio/aspect-ratio.css";
import "../../style.css";
// import "./styles.css";
import "../../globalStyles.css";
import SearchIcon from "../../../assets/images/searchIcon.svg";
import FilterIcon from "../../../assets/images/filterIcon.svg";
import data from "../../../constants/retailPos.json";
import Offers from "../../../assets/images/offers.png";
import DeleteProduct from "../../../assets/images/delete1.svg";
import RightArrow from "../../../assets/images/rightArrow.svg";
import LeftArrow from "../../../assets/images/leftArrow.svg";
import CustomerIcon from "../../../assets/images/customerIcon.png";
import AddProduct from "./AddProduct";
import ProductPanel from "./ProductPanel";
import QuantityUpdate from "./QuantityUpdate";
import MoreOptionsIcon from "../../../assets/images/moreOptionsIcon.svg";

const CartPage = (props) => {
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const {
    addProduct,
    cart,
    checkIsManualWeight,
    clearProductSearchResults,
    decreaseProductQty,
    getMoreProducts,
    getSearchedProducts,
    increaseProductQty,
    isProductsFilter,
    isQtyUpdate,
    isSearchProducts,
    openPaymentModal,
    parkBill,
    productCategories,
    productSearchInput,
    productSearchInputRef,
    productsList,
    selectProductCategory,
    selectProductInCart,
    selectedProductInCart,
    setSelectedProductInCart,
    selectedRowKeys,
    setDisplayCustomerSearch,
    setCloseCustomerFlag,
    setIsProductsFilter,
    setIsQtyUpdate,
    setIsSearchProducts,
    setProductSearchInput,
    tillData,
    clearSelectedProductInCart,
    productListCardRef,
    removeCutomer,
    selectedProductCategory,
    handleKeyvalue,
    selectdata,
    removeProductModal,
    productItems,
    setProductItems,
    productArray,
    setProductArray,
    setIsProductsVisible,
    setEvent,
    pickProduct,
    event
  } = props;

  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const customerId = tillData.tillAccess.csBunit.b2cCustomer.cwrCustomerId;
  const SubMenu = Menu.SubMenu;
  const MenuItem = Menu.Item;
  const { Option } = Select;

  const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
  const [isScrolledToStart, setIsScrolledToStart] = useState(false);


  const getScrollby = (direction) => {
    // Calculate the distance you want to scroll, e.g., 100 pixels for each click
    const scrollDistance = 100;
    // Get the current scroll position
    const currentScroll = ref.current.scrollLeft;

    if (direction === "left") {
      // Scroll to the left
      ref.current.scrollTo({
        left: currentScroll - scrollDistance,
        behavior: "smooth",
      });
    } else if (direction === "right") {
      // Scroll to the right
      ref.current.scrollTo({
        left: currentScroll + scrollDistance,
        behavior: "smooth",
      });
    }
  };
  const getScrollbyRight = () => {
    document.getElementById('yellow').scrollLeft -= 600;
    // setscrollLeft(true)
  }
  const ref = useRef(null);
  const handleScroll = (event) => {
    setIsScrolledToStart(true);
    const element = event.target;
    if (element.scrollWidth - element.scrollLeft === element.clientWidth) {
      setIsScrolledToEnd(true);
    } else if (element.scrollLeft === 0) {
      setIsScrolledToStart(false);
    } else {
      setIsScrolledToEnd(false);
    }
  };

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft = element.scrollLeft + step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };




  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 40, marginTop: "18vh", color: "#2F3856" }} spin />} spinning={false}>
      <div style={{ width: "100%", display: "flex" }}>
        {/* LEFT SIDE */}
        <div style={{ paddingTop: "1.8vh", width: isProductsFilter ? "25%" : "65.5%", backgroundColor: "#F3F4F9" }}>

          <div style={{ paddingLeft: "1vw", marginBottom: "1.8%", display: "flex", width: "100%" }}>
            <div
              style={{ width: "93.1%" }}
              id="step7"
            >
              <Input
                placeholder="Search for products by code/name"
                ref={productSearchInputRef}
                onChange={(e) => {
                  e.target.value === "" ? clearProductSearchResults() : setProductSearchInput(e.target.value);
                }}
                className="productSearch"
                style={{ borderRadius: "7px", padding: "0.8vh 0.8vw", borderColor: "rgba(0, 0, 0, 0.25)", width: "100%" }}
                value={productSearchInput}
                onFocus={() => setIsSearchProducts(true)}
                onPressEnter={getSearchedProducts}
                suffix={
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    {productSearchInput.length > 0 ? (
                      <CloseSquareFilled style={data.posScreenStyles.productSearchSuffix} onClick={() => clearProductSearchResults("")} />
                    ) : null}
                  </div>
                }
                prefix={<img src={SearchIcon} alt="" onClick={getSearchedProducts} />}
              />
            </div>
            <Button
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "6.5%",
                margin: "0 1.5vw 0 0.5vw",
                borderRadius: "7px",
                height: "inherit",
                borderColor: "rgba(0, 0, 0, 0.25)",
                border: "none"
              }}
            // onClick={() => setIsProductsFilter(true)}
            >
              <img src={FilterIcon} style={{ height: "2.4vh" }} />
            </Button>
          </div>

          <div
            style={{
              marginTop: 0,
              height: "5.8vh",
              borderRadius: "0.5vw",
              fontSize: "1vw",
              marginLeft: "1vw",
              marginRight: "3%",
              width: "96%",
              display: "flex",
              backgroundColor: "#fff",
            }}
          >
            <div style={{ alignItems: "center", display: "flex", width: "4%", justifyContent: "center", paddingLeft: "1%" }}>
              <img src={LeftArrow} style={{ height: "0.8vw", cursor: "pointer" }} alt="" onClick={() => handleHorizantalScroll(ref.current, 35, 100, -360)} />
            </div>
            <div onScroll={handleScroll} style={{ overflowX: "hidden", display: "flex", scrollBehavior: "smooth", overflowY: "hidden", textAlign: "center", width: "92%", }} ref={ref}>
              {productCategories.map((item, i) => {
                return (
                  <span
                    key={i}
                    onClick={() => { selectProductCategory(item.mProductCategoryId); }}
                    style={{ whiteSpace: "nowrap", alignSelf: "center", padding: "3px 15px", backgroundColor: selectedProductCategory === item.mProductCategoryId ? "#2F3856" : "#fff", borderRadius: "7px", color: selectedProductCategory === item.mProductCategoryId ? "#fff" : "#000", cursor: "pointer" }}
                  >
                    {item.name}
                  </span>
                );
              })}
            </div>
            <div style={{ alignItems: "center", display: "flex", width: "4%", justifyContent: "center", paddingRight: "1%" }}>
              <img src={RightArrow} style={{ height: "0.8vw", cursor: "pointer" }} alt="" onClick={() => handleHorizantalScroll(ref.current, 35, 100, 360)} />
            </div>
          </div>
          <Row id="step8" style={{ paddingTop: "1.7%", width: "100%", paddingLeft: "1vw" }}>
            {isSearchProducts ? (
              <ProductPanel
                checkIsManualWeight={checkIsManualWeight}
                setIsSearchProducts={setIsSearchProducts}
                getMoreProducts={getMoreProducts}
                productsList={productsList}
                productItems={productItems}
                setProductItems={setProductItems}
                selectProductInCart={selectProductInCart}
                productArray={productArray}
                setProductArray={setProductArray}
                isProductsFilter={isProductsFilter}
                getSearchedProducts={getSearchedProducts}
                productListCardRef={productListCardRef}
                setIsProductsVisible={setIsProductsVisible}
                pickProduct={pickProduct}
              />
            ) : isQtyUpdate ? (<QuantityUpdate
              selectedProductInCart={selectedProductInCart}
              addProduct={addProduct}
              setIsQtyUpdate={setIsQtyUpdate}
              isQtyUpdate={isQtyUpdate}
              increaseProductQty={increaseProductQty}
              decreaseProductQty={decreaseProductQty}
              removeProductModal={removeProductModal}
              setIsSearchProducts={setIsSearchProducts}
            />
            ) :
              <ProductPanel
                checkIsManualWeight={checkIsManualWeight}
                setIsSearchProducts={setIsSearchProducts}
                getMoreProducts={getMoreProducts}
                productsList={productsList}
                productItems={productItems}
                setProductItems={setProductItems}
                selectProductInCart={selectProductInCart}
                productArray={productArray}
                setProductArray={setProductArray}
                isProductsFilter={isProductsFilter}
                getSearchedProducts={getSearchedProducts}
                productListCardRef={productListCardRef}
                setIsProductsVisible={setIsProductsVisible}
                pickProduct={pickProduct}
              />}
          </Row>
        </div>
        {isProductsFilter ? (
          <div style={{ border: "1px solid #ebeded", borderRight: "none", borderBottom: "none", marginTop: "-10px", width: "27%" }}>
            <Menu defaultSelectedKeys={["itemAllProducts"]} defaultOpenKeys={["item01"]} mode="inline">
              <MenuItem defaultOpenKeys={["item01"]} style={data.posScreenStyles.productFilterMenu.menuItem}>
                FILTERS
                <span style={data.posScreenStyles.productFilterMenu.filterCloseIcon}>
                  <CloseOutlined onClick={() => setIsProductsFilter(false)} />
                </span>
              </MenuItem>
              <SubMenu
                key="item01"
                icon={<ShoppingCartOutlined style={data.posScreenStyles.productFilterMenu.shoppingCartIcon} />}
                title={<span style={data.posScreenStyles.productFilterMenu.categoryTitle}>Category</span>}
              >
                {/* <MenuItem
                  key={"itemAllProducts"}
                  onClick={() => {
                    selectProductCategory("allProducts");
                  }}
                >
                  All Products
                </MenuItem>*/}
                {productCategories.map((item, index) => (
                  <MenuItem
                    key={`item${index + 1}`}
                    onClick={() => {
                      selectProductCategory(item.mProductCategoryId);
                    }}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </SubMenu>
            </Menu>
          </div>
        ) : (
          ""
        )}

        {/* RIGHT SIDE */}
        <div style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.14)", width: "34.5%", backgroundColor: "#fff" }}>
          <Row style={{ padding: "6px 10px 10px 10px", height: "100vh" }}>
            <Col span={24} style={{ paddingTop: "1.7vh" }}>
              <Row>
                <Col span={9} className="cartStyle">
                  <span>CART</span>
                  <span style={{ fontSize: "1vw", paddingLeft: "0.5vw", paddingBottom: "0.3vw", color: "rgba(0, 0, 0, 0.35)", fontWeight: "600" }}>
                    {cart.items.length} Item(s)
                  </span>
                </Col>
                <Col span={10} />
                <Col id="step9" span={5} style={{alignItems: "end", display: "flex", justifyContent: "end"}}>
                  <Select
                    className="selecItem"
                    onSelect={selectdata}
                    value={event === "" ? 'More Actions' : event}
                    placeholder={<span className="cartSelect">ns</span>}

                    suffixIcon={<img src={MoreOptionsIcon} alt="" style={{ height: "0.9vh" }} />}
                  >
                    <Option className="cartOption" key="discount" value="discount">
                      Add Discount
                    </Option>
                    {posConfig.allowBillParking === "Y" ? (
                      <Option className="cartOption" key="parkBill" value="parkBill">
                        Park Bill
                      </Option>
                    ) : null}

                    {/* <Option className="cartOption" key="carryBag" value="carryBag">
                              Carry Bag
                            </Option> */}
                    {posConfig.allowBillParking === "Y" ? (
                      <Option className="cartOption" key="clearCart" value="clearCart">

                        Clear Cart
                      </Option>
                    ) : null}

                  </Select>
                </Col>
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Col span={24} id="step10">
                  {cart.customer.cwrCustomerId === customerId ? (
                    <Input
                      className="customer-search"
                      style={{borderRadius: "0.5vw", height: "4.9vh"}}
                      placeholder="Enter customer number/name"
                      prefix={<img src={SearchIcon} alt="" style={{ height: "2.4vh" }} />}
                      onClick={() => {
                        setDisplayCustomerSearch(true);
                        setCloseCustomerFlag(true);
                      }}
                    />
                  ) : (
                    <span style={{ padding: "0.9vh 1.3vh", border: "1px solid rgba(0, 0, 0, 0.3) ", borderRadius: "4px" }}>
                      <img src={CustomerIcon} style={{ height: "1.7vh", paddingBottom: "3px" }}></img> {cart?.customer?.name} &ensp;{" "}
                      <CloseOutlined
                        style={{ fontSize: "1.8vh" }}
                        onClick={() => {
                          removeCutomer();
                        }}
                      ></CloseOutlined>
                    </span>
                  )}
                </Col>
              </Row>
              <Row style={{ paddingTop: "8px", height: "85vh" }}>
                <Col span={24}>
                  <div style={data.posScreenStyles.cardDetails} bodyStyle={{ padding: 0 }}>
                    <Table
                      className="cart-table"
                      style={data.posScreenStyles.cartTable.tableStl}
                      pagination={false}
                      dataSource={[...cart.items]}
                      sticky
                      scroll={{ y: "56vh" }}
                      rowClassName={(record) => (selectedRowKeys[0] === record.key ? "tableRowSelectedBlue" : "tableRowNonSelectedWhite")}
                      columns={[
                        {
                          title: "Item name",
                          dataIndex: "name",
                          key: "name",
                          width: "53%",
                          render: (text, record, index) => {
                            return (
                              <>
                                <div
                                  onClick={() => {
                                    selectProductInCart(record, "1");
                                    setIsSearchProducts(false);
                                    if (selectedRowKeys.length > 0 && selectedProductInCart.productId === record.productId) {
                                      setIsQtyUpdate(true)
                                    }
                                  }}
                                  className="productName1"
                                >
                                  {record.name}
                                </div>
                                <div className="offerName">
                                  {cart.discount > 0 ? record.discountName || "" : ""}{" "}
                                  {cart.discount > 0 ? record.discountName ? <img src={Offers} alt="" width={15} height={15} style={{ marginTop: "-2px" }} /> : "" : ""}
                                </div>
                              </>
                            );
                          },
                        },
                        {
                          title: "Qty",
                          dataIndex: "weight",
                          key: "weight",
                          width: "15%",
                          render: (text, record, index) => {
                            const wt = parseFloat(text);
                            if (Number.isInteger(wt)) {
                              return (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                  {selectedProductInCart.productId === record.productId ? (
                                    <MinusOutlined
                                      title="Decrease Qty"
                                      onClick={() =>
                                        selectedRowKeys.length > 0 && parseFloat(selectedProductInCart.weight) > 1 ? decreaseProductQty(selectedProductInCart) : ""
                                      }
                                      style={{
                                        marginRight: "5px",
                                        padding: "0.5vh 0.5vh",
                                        border: "1px solid #929098",
                                        fontSize: "1vh",
                                        borderRadius: "2px",
                                        cursor: "pointer",
                                        fontWeight: "500",
                                        color: "#929098"
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  <div style={{ margin: "0 3px", fontWeight: "500", fontSize: "0.9vw" }}>{wt}</div>
                                  <div className="offerName">
                                    {record.salesRepName && posConfig.showOrderSalesRep === "Y" && posConfig.showLineSalesRep === "Y" ? record.salesRepName : ""}
                                  </div>
                                  {selectedProductInCart.productId === record.productId ? (
                                    <PlusOutlined
                                      title="Increase Qty"
                                      onClick={() => (selectedRowKeys.length > 0 ? increaseProductQty(selectedProductInCart) : "")}
                                      style={{
                                        marginLeft: "5px",
                                        padding: "0.5vh 0.5vh",
                                        border: "1px solid #2F3856",
                                        fontSize: "1vh",
                                        borderRadius: "2px",
                                        cursor: "pointer",
                                        backgroundColor: "#2F3856",
                                        color: "#FFF"
                                      }}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              );
                            } else {
                              return (
                                <>
                                  <div>{parseFloat(text).toFixed(2)}</div>
                                  <div className="offerName">
                                    {record.salesRepName && posConfig.showOrderSalesRep === "Y" && posConfig.showLineSalesRep === "Y" ? record.salesRepName : ""}
                                  </div>
                                </>
                              );
                            }
                          },
                        },
                        {
                          title: "Amount",
                          dataIndex: "nettotal",
                          key: "nettotal",
                          render: (text, record) => {

                            return (
                              <div style={{ position: "relative" }}>
                                <span style={{ fontWeight: "600", fontSize: "0.9vw", paddingRight: "2vw" }}>
                                  {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                                  &nbsp;
                                  {parseFloat(text).toFixed(2)}
                                  &nbsp;
                                  {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}

                                </span>

                                <img
                                  src={DeleteProduct}
                                  onClick={() => {
                                    clearSelectedProductInCart();
                                    removeProductModal(record);
                                  }}
                                  style={{ height: "14px", width: "13px", cursor: "pointer", marginLeft: "1.6vw", position: "absolute", right: 0, top: "15%" }}
                                />
                              </div>
                            );
                          },
                        },
                      ]}
                      onRow={(record) => ({
                        onClick: () => {
                          selectProductInCart(record);
                        },
                      })}
                    />
                    <div style={data.posScreenStyles.cartTable.prodTotal}>
                      <div style={{ backgroundColor: "#fff", borderTop: "1px dashed #929098" }}>
                        <Row style={{ padding: "0px 5px 0 5px", paddingTop: "3vh" }}>
                          <Col span={6} offset={1}>
                            <label style={data.posScreenStyles.cartTable.taxDisLbl} className="taxDisLbl">
                              Tax
                            </label>
                          </Col>
                          <Col span={16} style={data.posScreenStyles.cartTable.amtTextAlign}>
                            <label style={data.posScreenStyles.cartTable.taxDisLbl} className="taxDisLbl">
                              {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                              &nbsp;
                              {parseFloat(cart.tax).toFixed(2)}
                              <span style={{ marginLeft: "2px" }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                            </label>
                          </Col>
                          <Col span={1} />
                        </Row>
                        <Row style={{ padding: "0px 5px 0 5px", backgroundColor: "" }}>
                          <Col span={6} offset={1}>
                            <label style={data.posScreenStyles.cartTable.taxDisLbl} className="taxDisLbl">
                              Discounts
                            </label>
                          </Col>
                          <Col span={16} style={data.posScreenStyles.cartTable.amtTextAlign}>
                            <label style={data.posScreenStyles.cartTable.taxDisLbl} className="taxDisLbl">
                              {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                              &nbsp;
                              {parseFloat(cart.discount).toFixed(2)}
                              <span style={{ marginLeft: "2px" }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                            </label>
                          </Col>
                          <Col span={1} />
                        </Row>
                        <Row style={{ padding: "0px 5px 0 5px", backgroundColor: "" }}>
                          <Col span={6} offset={1}>
                            <label style={data.posScreenStyles.cartTable.taxDisLbl} className="taxDisLbl">
                              Items / Qty
                            </label>
                          </Col>
                          <Col span={16} style={data.posScreenStyles.cartTable.amtTextAlign}>
                            <label style={data.posScreenStyles.cartTable.taxDisLbl} className="taxDisLbl">
                              &nbsp;
                              {cart.items.length} / {parseFloat(cart.totalQty).toFixed(2)}
                            </label>
                          </Col>
                          <Col span={1} />
                        </Row>
                        <Row style={{ padding: "1.4vh 0.14vh 0.7vh 1.4vh", display: "flex", justifyContent: "center", paddingTop: "1.5vh" }}>
                          <Button

                            className="totalBtn"
                            style={data.posScreenStyles.cartTable.totalBtn}


                            onClick={



                              cart.items.length > 0 ?


                                localStorage.getItem("salesReturn") === "Y" && posConfig.allowReturnRefund === "Y"
                                  ? () => {
                                    handleKeyvalue("7");
                                    openPaymentModal();
                                  }
                                  : localStorage.getItem("salesReturn") === "N"
                                    ? () => {
                                      handleKeyvalue("7");
                                      openPaymentModal();
                                    }
                                    : posConfig.allowReturnExchange === "Y" && posConfig.allowReturnRefund === "N" && cart.items.length > 0 && cart.total >= 0
                                      ? () => {
                                        handleKeyvalue("7");
                                        openPaymentModal();
                                      }
                                      : null
                                : null

                            }
                            id='step11'>
                            <span>Pay</span>&nbsp;&nbsp;

                            {currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""}
                            &nbsp;
                            {cart.total !== undefined ? <span> {(cart.total.toFixed(2))}</span> : <span style={{ fontSize: "1.9vw" }}> 0.00</span>}
                            <span style={{ marginLeft: "2px" }}>{currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</span>
                          </Button>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div >
    </Spin >
  );
};

export default CartPage;
