import { dateValidator } from "../../../../utility/clock";
import Axios from "axios";
import { message } from "antd";
import db from "../../../../database";
import * as Sentry from '@sentry/react';

export const redeemLoyality = async (paymentMethod, value, cart, setCart, setAmount, setSelectedPaymentMethod, setAuthTokens, completePayment) => {
  const serverUrl = process.env.REACT_APP_serverUrl;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillaccess = JSON.parse(tillData?.tillAccess?.userAccessController);
  const tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);
  let loyaliryData = await db.loyalityData.toArray();
  let cartObj = cart;
  let amount = 0;
  let convertionValue = 0;
  let redeemptionValue = 0;
  if (value <= Math.abs(cartObj.customer.retlLoyaltyBalance)) {
    loyaliryData.forEach((loyality) => {
      if (loyality.loyaltylevelId === cartObj.customer.loyaltyLevel.cwrLoyaltyLevelId) {
        loyality.loyaltyRedemption.forEach((redeemItem) => {
          if (dateValidator(redeemItem.startDate, redeemItem.endDate) && redeemItem.minPurchase <= cartObj.total && redeemItem.pointsRequired <= parseFloat(value)) {
            convertionValue = cart.customer.retlLoyaltyBalance - parseFloat(value);
            amount = redeemItem.redemptionValue * value;
            redeemptionValue = redeemItem.redemptionValue;
            if(cart.total < amount){
              value = cart.total === amount ? value : parseFloat((cart.total/redeemItem.redemptionValue).toFixed(2));
            }
          }
        });
      }
    });
  }
  if (cart.customer.loyaltyLevel.cwrLoyaltyLevelId && amount > 0 && amount <= (cart.total - cart.paid)) {
    // const redeemptionRate = cart.customer.loyaltyLevel.redemptionRate;
    // const redeemPoints = parseFloat(value);
    // const amountForRedeem = parseFloat(redeemptionRate) * redeemPoints;
    // setvalue(0)
    // setLoyalityOtpModalVisible(false);
    // if (redeemPoints <= parseFloat(cart.customer.retlLoyaltyBalance)) {
      if(value <= parseFloat(cart.customer.retlLoyaltyBalance)){
        cart.customer.retlLoyaltyBalance = parseFloat(convertionValue.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
        cart.customer.redeemptionValue = parseFloat(redeemptionValue.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
        Axios({
          url: serverUrl,
          method: "POST",
          data: {
            query: `mutation {
                      upsertLoyaltyTransaction(transaction: [{
                          csBunitId: "${tillData.tillAccess.csBunit.csBunitId}"
                          loyaltyPoints: ${value}
                          b2cCustomerId: "${cart.customer.cwrCustomerId}"
                          referenceId: "${cart.referenceId}"
                        }]) {
                        status 
                        message
                      }
                    }
                    `,
          },
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${setAuthTokens}`,
          },
        }).then(async (loyalityResponse) => {
          if (loyalityResponse?.data?.data?.upsertLoyaltyTransaction?.status) {
            let rem = parseInt(cart.redemptionPoints) + parseFloat(value);
            cart.redemptionPoints = rem;
            completePayment(paymentMethod, amount, cart, setCart, setAmount, setSelectedPaymentMethod);
          }
        }).catch((err)=>{
          message.error("Error processing loyalty points redemption.lease try again later.If the problem persists please contact technical support for assistance")
          Sentry.captureException(err);
          Sentry.captureException(new Error("Loyalty payment Error"), {
            extra: `{
              csBunitId: "${tillData.tillAccess.csBunit.csBunitId}"
              loyaltyPoints: ${value}
              b2cCustomerId: "${cart.customer.cwrCustomerId}"
              referenceId: "${cart.referenceId}"
            }`
          });
        })
      }else {
        message.error("Entered loyalty points exceed your available balance.Please enter valid points.")
      }
    // .then((loyalityResponse) => {
    //   // const loyalityResponse = {data:{data:{verifyLoyalty:{ status: "Success", otp: "12345", message: "OTP Generated !"}}}};
    //   const { status, otp } = loyalityResponse.data.data.verifyLoyalty;
    //   const responseMessage = loyalityResponse.data.data.verifyLoyalty.message;
    //   if (status === "Success") {
    //     message.success(responseMessage);
    //     setLoyalityOtpData({
    //       otp: otp,
    //       paymentMethod: paymentMethod,
    //       value: amountForRedeem,
    //       redeemPoints: redeemPoints,
    //     });
    //     setLoyalityOtpModalVisible(true);
    //   } else {
    //     message.warning(responseMessage);
    //   }
    // })
    // .catch((err) => {
    //   console.error(err);
    //   message.error("Payment Failed !");
    // })
    // .finally(() => {
    //   setLoader(false);
    //   if (tillLayout === 2) {
    //     setPaymentModal(true);
    //   }
    // });
    // } else {
    //   message.warning("Insuffient loyality points !");
    //   setLoader(false);
    //   if (tillLayout === 2) {
    //     setPaymentModal(true);
    //   }
    // }
  }else if(amount > (cart.total - cart.paid)){
    message.warning("It is not possible to add Overpayment with this Payment Method");
  }
  //    else {
  //     message.warning("Loyalty not found for selected customer !");
  //     setLoader(false);
  //     if (tillLayout === 2) {
  //       setPaymentModal(true);
  //     }
  //   }
};
