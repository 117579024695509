import React, { useState, useEffect } from "react";
import { Drawer, Table, Input, Button } from "antd";
import Close from "../../../../assets/images/close-x.svg";

const BOMModals = (props) => {
  const { isStockModal, setIsStockModal, selectedProduct, setSelectedProduct, searchedProductsData, setSearchedProductsData, handleCancel, updateCartTotals } = props;

  const [itemWeights, setItemWeights] = useState(() => {
    // Initialize with the cart items' weights
    // const initialWeights = {};
    // selectedProduct?.bomproducts.forEach((item) => {
    //   initialWeights[item.m_product_bom_id] = item.stock - item.qty; // Store as string to allow flexible input
    // });
    // return initialWeights;
  });
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const themeJSON = posConfig?.themeJSON && posConfig?.themeJSON !== "N" && Object.keys(posConfig.themeJSON).length > 0 ? JSON.parse(posConfig.themeJSON)?.layout4 : {};
  const [tempCart, setTempCart] = useState({});
  const handleQtyChange = (value, recordIndex, productId) => {
    const updatedWeight = parseFloat(value);
    const updatedTempCart = { ...tempCart };
    let tempData = { ...itemWeights };
    tempData[productId] = isNaN(updatedWeight) ? 0 : updatedWeight;
    setItemWeights(tempData);
    setTempCart(updatedTempCart);
  };

  return (
    <Drawer
      closable={true}
      placement="bottom"
      title={
        <div style={{ display: "flex", alignItems: "center", height: "2vh", fontSize: "1vw" }}>
          <p style={{ fontSize: "1.5em", marginTop: "3vh", fontWeight: "500", color: "#0F0718", flex: 1, textAlign: "center" }}>Stock Confirmation</p>
          <img
            src={Close}
            onClick={() => {
              setIsStockModal(false);
            }}
            style={{ float: "right", cursor: "pointer", marginTop: "-3vh", marginRight: "-0.5vw", width: "1.5vw" }}
          />
        </div>
      }
      closeIcon={null}
      visible={isStockModal}
      className="filter"
      bodyStyle={{ padding: "0 4vw" }}
      onClose={() => {
        setIsStockModal(false);
      }}
      //  id="sm-drawer-close"
      height="90vh"
    >
      <Table
        className="sm-cart-table-pos-stock"
        style={{
          width: "100%",
          overflowY: "auto",
          border: "none",
          borderRadius: "7px",
          padding: "0px 7px",
          height: "67vh",
          background: themeJSON.productTableBGColor || "#fff",
        }}
        pagination={false}
        dataSource={searchedProductsData?.filter((product) => product.isBom === "Y") || []}
        sticky
        id="sm-cart-table"
        columns={[
          {
            title: "Item Name",
            dataIndex: "product_name",
            key: "product_name",
            width: "32%",
            render: (text, record, index) => {
              return (
                <>
                  <div onClick={() => {}} id="sm-product-name" className="tableRow">
                    {record.product_name}
                  </div>
                  <div className="offerName">
                    {record.stock >= record.weight ? (
                      <p style={{ margin: 0, color: "#009000" }}>Available</p>
                    ) : record.stock > 0 && record.weight > 0 ? (
                      <p style={{ margin: 0, color: "#FF5E0E" }}>Only {record.stock - record.weight} Available</p>
                    ) : (
                      <p style={{ margin: 0, color: "red" }}>Out Of Stock</p>
                    )}
                  </div>
                </>
              );
            },
          },
          {
            title: "UOM",
            dataIndex: "bom_uomname",
            key: "bom_uomname",
            width: "12%",
            render: (text, record, index) => {
              return <div style={{ textAlign: "left" }}>{record?.uomData && Array.isArray(record?.uomData) && record?.uomData?.length > 0 ? record.uomData[0].name : ""}</div>;
            },
            align: "center",
          },
          {
            title: "Required Qty",
            dataIndex: "weight",
            key: "weight",
            width: "15%",
            align: "right",
            render: (text, record) => {
              return <div style={{ textAlign: "right" }}>{parseFloat(record.weight).toFixed(2)}</div>;
            },
          },
          {
            title: "Available Qty",
            dataIndex: "stcok",
            key: "stock",
            width: "15%",
            align: "right",
            render: (text, record) => {
              return (
                <div style={{ textAlign: "right", color: record.stock - record.weight > 0 ? "#0F0718" : "red" }}>
                  {record?.stock?.toFixed(2).length < 8 ? parseFloat(record.stock)?.toFixed(2) : `${record.stock?.toString().substring(0, 8)}`}
                </div>
              );
            },
          },
          //   {
          //     title: "Update Order Qty",
          //     dataIndex: "availableQty",
          //     key: "availableQty",
          //     width: "22%",
          //     align: "center",
          //     paddingLeft: "3vw",
          //     textAlign: "right",
          //     render: (text, record, index) => {
          //       return (
          //         <Input
          //           style={{ width: "10vw", textAlign: "right" }}
          //           value={itemWeights[record.m_product_bom_id]} // Use local state to control the value
          //           onChange={(e) => handleQtyChange(e.target.value, index, record.productId)} // Handle input change
          //         />
          //       );
          //     },
          //   },
        ]}
      />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          style={{
            backgroundColor: "#fff",
            color: "#2F3856",
            height: "7vh",
            width: "15vw",
            borderRadius: "7px",
            marginTop: "1rem",
          }}
          onClick={() => {
            setIsStockModal(false);
            handleCancel();
          }}
        >
          Cancel
        </Button>
        <Button
          style={{
            backgroundColor: "#2F3856",
            color: "#fff",
            height: "7vh",
            width: "15vw",
            borderRadius: "7px",
            marginTop: "1rem",
            marginLeft: "1rem",
          }}
          onClick={() => {
            setIsStockModal(false);
            let filteredData = searchedProductsData
              .map((product) => {
                if (product.isBom === "Y" && product.weight && product.weight > product.stock) {
                  const updatedWeight = product.weight < product.stock ? product.weight : 0;
                  return updatedWeight !== 0 ? { ...product, weight: updatedWeight } : null;
                }
                return product;
              })
              .filter((product) => product !== null);
            updateCartTotals(filteredData);
            setSearchedProductsData(filteredData);
          }}
        >
          Confirm
        </Button>
      </div>
    </Drawer>
  );
};

export default BOMModals;
