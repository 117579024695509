import { v4 as uuidv4 } from "uuid";

// cartUtils.js (or any other filename you choose)
const tillData = JSON.parse(localStorage.getItem("tillData"));
export const defaultCustomer = tillData?.tillAccess?.csBunit?.b2cCustomer;

export const initializeCart = () => {
  const tillDocumentSequence = parseFloat(localStorage.getItem("documentSequence"));
//   console.log("tillDocumentSequence:", tillDocumentSequence);
  return {
    items: [],
    total: 0,
    tax: 0,
    discount: 0,
    paid: 0,
    change: 0,
    totalQty: 0,
    roundOff: 0,
    payments: [],
    redemptionPoints: 0,
    accumulationPoints: 0,
    creditAmount: 0,
    sOrderID: uuidv4().replace(/-/g, "").toUpperCase(),
    customer: defaultCustomer,
    salesRepId: null,
    documentNo: tillDocumentSequence + 1,
  };
};

