import Axios from "axios";
import CWGiftCardHCPrint from "./CWGiftCardHCPrinter";

const CWHCPrint = (reciptData) => {
    let printReciptData = { ...reciptData };
    const tillData = JSON.parse(localStorage.getItem("tillData"));
    const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
    const posConfig = JSON.parse(localStorage.getItem("posConfig"));
    const sales_receipt_copies = posConfig?.sales_receipt_copies && posConfig?.sales_receipt_copies !== null ? posConfig?.sales_receipt_copies : 0;
    const return_receipt_copies = posConfig?.return_receipt_copies && posConfig?.return_receipt_copies !== null ? posConfig?.return_receipt_copies : 0;
    const layaway_receipt_copies = posConfig?.layaway_receipt_copies && posConfig?.layaway_receipt_copies !== null ? posConfig?.layaway_receipt_copies : 0;
    const layaway_paid_receipt_copies = posConfig?.layaway_paid_receipt_copies && posConfig?.layaway_paid_receipt_copies !== null ? posConfig?.layaway_paid_receipt_copies : 0;

    let itemsDetails = [];

    let decodedData = JSON.parse(tillData?.tillAccess?.cwrTill?.printTemplate?.xmlcode2 || "{}");

    itemsDetails = printReciptData.items.map((item) => ({
        item: item.name, 
        qty: item.weight,
        price: item.realPrice ? item.realPrice : item.salePrice
    }));

    // Function to replace placeholders in content
    const replacePlaceholders = (contentArray) => {
        return contentArray.map((item) => {
            if (typeof item.value === "string") {
                item.value = item.value
                    .replace("@@date@@", `${printReciptData.orderDate?.split("-").reverse().join("-")}`)
                    .replace("@@tillIdReceipt@@", printReciptData.documentno || "")
                    .replace("@@Total@@", printReciptData.total ? parseFloat(printReciptData.total).toFixed(2) : "")
                    .replace("@@taxTotal@@", parseFloat(printReciptData.tax).toFixed(2));
            }
            
            if (item.type === "table" && item.rows === "@@itemDetails@@") {
                // Assign the array of item details to the "rows" key in the table
                item.rows = itemsDetails;
            }
            if (typeof item === "string" && item.includes("@@OrderType@@")) {
                if (printReciptData.layAway === "Y" && printReciptData.paid === 0) {
                    item = {
                        type: "text",
                        value: "LAYAWAY",
                        align: "center",
                        font: "bold",
                        size: "x-large",
                        FontSize: 10
                    };
                } else {
                    item =  item = {
                        type: "text",
                        value: "Sales Order",
                        align: "center",
                        font: "bold",
                        size: "x-large",
                        FontSize: 10
                    }; // Remove @@orderType@@
                }
            }

            // Replace @@paymentStatus@@
            if (typeof item === "string" && item.includes("@@paymentStatus@@")) {
                if (printReciptData.layAway === "Y" && printReciptData.paid === 0) {
                    item = {
                        type: "text",
                        value: "Not Paid",
                        align: "center",
                        font: "bold",
                        size: "x-large",
                        FontSize: 10
                    };
                } else {
                    item = {
                        type: "text",
                        value: "Paid",
                        align: "center",
                        font: "bold",
                        size: "x-large",
                        FontSize: 10
                    };
                }
            }
            return item;
        });
    };

    // Replace placeholders in content
    decodedData.document.content = replacePlaceholders(decodedData.document.content);

    console.log("decodedData", decodedData);

    // Send the print request
    Axios.post(`${printerURL}printer`, decodedData)
        .then((response) => {
            console.log("Print success!", response);
            printReciptData.items.forEach((data) => {
                if (printReciptData.isReturn && data?.giftCardType === "DIG" && printReciptData.layAway !== "Y") {
                    CWGiftCardHCPrint(data, "Credit Note");
                } else if (data?.giftCardType === "DIG") {
                    CWGiftCardHCPrint(data, "Gift Card");
                }
            });
        })
        .catch((error) => {
            console.error("Print failed!", error);
        });
};

export default CWHCPrint;
