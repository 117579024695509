import Axios from "axios";
import db from "../../database";

const GiftCardHCPrint = async (printData,type) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const printerURL = tillData?.tillAccess?.cwrTill?.hardwareController?.imageUrl;

  let GiftCardHCPrintPayload
  let giftCardData = await db.giftCardData?.toArray();

  giftCardData?.map((GCData) => {
    if (GCData?.type === "DIG") {
      GiftCardHCPrintPayload = GCData?.printTemplate?.xmlcode2;
    }
  })
  GiftCardHCPrintPayload = GiftCardHCPrintPayload.replaceAll("@@giftCardName@@", `${type === "Credit Note" ? "Credit Note" : printData?.name}`);
  GiftCardHCPrintPayload = GiftCardHCPrintPayload.replaceAll("@@giftCardNumber@@", `${printData?.cardNo}`);
  GiftCardHCPrintPayload = GiftCardHCPrintPayload.replaceAll("@@giftCardAmount@@", `${printData?.nettotal} `);
  GiftCardHCPrintPayload = GiftCardHCPrintPayload.replaceAll("@@validThrough@@", `${printData?.expiryGiftCard} `);

  Axios.post(`${printerURL}printer`, GiftCardHCPrintPayload, {
    headers: {
      "Content-Type": "application/xml; charset=UTF-8",
      Accept: "application/xml",
    },
  })
    .then(() => {
      console.log("Gift Card Print success!");
    })
    .catch((response) => {
      console.log("Gift Card Print failed!", response);
    });
};

export default GiftCardHCPrint;