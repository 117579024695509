import React, { useState, useEffect, useRef } from "react";
import { Row, Button, Col, Checkbox, Card, Input, Space, Typography, message } from "antd";
import Scrollbars from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroll-component";
import { CloseOutlined, LoadingOutlined, StopOutlined, SearchOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import Back from "../../../assets/images/backIcon.svg";
import CancelArrow from "../../../assets/images/cancelArrow.svg";
import Cash1 from "../../../assets/images/cash.svg";
import Card1 from "../../../assets/images/card.svg";
import Credit from "../../../assets/images/creditCardIcon.svg";
import Loyality from "../../../assets/images/loyalityIcon.svg";
import Gift from "../../../assets/images/giftIcon.svg";
import giftVoucher from "../../../assets/images/giftVoucher.svg";
import creditNote from "../../../assets/images/creditNote.svg";
import UPI from "../../../assets/images/upiIcon.svg";
import qrCodeIcon from "../../../assets/images/qrCodeIcon.svg";
import paymentSuccess from "../../../assets/images/payment-success.svg";
import _ from "lodash";
import "./restaurantStyle.css";
import moment from "moment";
import db from "../../../database";

const OrderListComponent = ({
  cart,
  selectedTable,
  setSelectedTable,
  tillDataPaymentMethods,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  setIsCardPaymentFlag,
  processOrder,
  amount,
  onChangeAmount,
  paymentModalInputRef,
  setIsInputFocused,
  requestPayment,
  denaminationsKeyboard,
  handleCashPayment,
  handleAmount,
  setDenaminationsKeyboard,
  setNumb,
  setKeyValue,
  paymentVisible,
  setPaymentVisible,
  showOrderCompleteFlag,
  setShowOrderCompleteFlag,
  userPreferences,
}) => {
  const { Text, Title } = Typography;
  const [orderTypeData, setOrderTypeData] = useState(0);
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillaccess = JSON.parse(tillData.tillAccess.userAccessController);
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  const [tableOrderData, setTableOrderData] = useState([]);
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const filteredPaymentMethods = _.sortBy(
    tillDataPaymentMethods.filter((pm) => {
      return !(
        (posConfig.loyaltyApplicable === "N" && pm.name === "Loyalty") ||
        (!cart?.customer?.loyaltyLevel?.cwrLoyaltyLevelId && pm.name === "Loyalty") ||
        (!cart?.customer?.iscredit && pm.name === "Credit") ||
        pm.name.toLowerCase() === "cafe tips"
      );
    }),
    "sequenceNo"
  );

  const initialShowPayments = filteredPaymentMethods.length > 2 ? filteredPaymentMethods.slice(0, 2) : filteredPaymentMethods;
  const [showPayments, setShowPayments] = useState(initialShowPayments);
  const [isShowingAll, setIsShowingAll] = useState(false);
  const [OrderedItem, setOrderedItem] = useState([]);

  // const showMoreItems = () => {
  //   let newData = _.sortBy(filteredPaymentMethods, "sequenceNo").slice(2, 4);
  //   console.log(newData, tillDataPaymentMethods);
  //   setShowPayments(newData);
  //   setIsShowingAll(true);
  // };

  // const showPreviousItems = () => {
  //   setShowPayments(initialShowPayments);
  //   setIsShowingAll(false);
  // };

  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(2);


  const showMoreItems = () => {
    let newData;
    if (startIndex >= filteredPaymentMethods.length) {
      setStartIndex(0);
      setEndIndex(2);
      newData = _.sortBy(filteredPaymentMethods, "sequenceNo").slice(0, 2);
    } else {
      newData = _.sortBy(filteredPaymentMethods, "sequenceNo").slice(startIndex, endIndex);

      setStartIndex(prevStartIndex => prevStartIndex + 2);
      setEndIndex(prevEndIndex => prevEndIndex + 2);
    }

    setShowPayments(newData);
  };

  const showPreviousItems = () => {
    const initialPayments = _.sortBy(filteredPaymentMethods, "sequenceNo").slice(0, 2);
    setShowPayments(initialPayments);
    setStartIndex(2);
    setEndIndex(4);
    setIsShowingAll(false);
  };

  useEffect(() => {
    if (orderTypeData === 0) {
      db.tableData.toArray().then((response) => {
        let finalData = response.filter((data) => data.statusType === "OCU");
        setTableOrderData(finalData);
      });
    } else if (orderTypeData === 2) {
      db.orders
        .where("isSynced")
        .equals(1)
        .toArray()
        .then((response) => {
          setTableOrderData(response);
        });
    }
  }, [orderTypeData]);

  const printReceipt = () => { };

  useEffect(() => {
    db.orders
      .orderBy("orderTime")
      .reverse()
      .limit(1)
      .toArray()
      .then((response) => {
        setOrderedItem(response);
      });
  }, [cart]);

  console.log(OrderedItem);

  return (
    <>
      {showOrderCompleteFlag ? (
        <div style={{ width: "100%", height: "100vh", padding: "2vh 1vw" }}>
          <Row gutter={[16]}>
            <Col
              span={12}
              style={{
                width: "100%",
                height: "96vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "20vh 6vw 20vh 5vw",
                flexDirection: "column",
                fontSize: "1vw",
              }}
            >
              <div
                style={{
                  fontSize: "2em",
                  paddingBottom: "4vh",
                  fontWeight: 600,
                  color: "#0F0718",
                }}
              >
                Order Successful <img src={paymentSuccess} style={{ height: "4rem" }} />
              </div>
              <Space
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  paddingTop: "2vh",
                  paddingTop: "2vh",
                }}
              >
                <Text
                  style={{
                    fontSize: "1.5em",
                    fontWeight: 400,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  Order No
                </Text>
                <Text
                  style={{
                    fontSize: "1.5em",
                    fontWeight: 400,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  {parseFloat(localStorage.getItem("documentSequence"))}
                </Text>
              </Space>
              {/* <Space
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  paddingTop: "2vh",
                }}
              >
                <Text
                  style={{
                    fontSize: "1.5em",
                    fontWeight: 400,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  Table No
                </Text>
                <Text
                  style={{
                    fontSize: "1.5em",
                    fontWeight: 400,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  5
                </Text>
              </Space> */}
              <Space
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  paddingTop: "2vh",
                }}
              >
                <Text
                  style={{
                    fontSize: "1.5em",
                    fontWeight: 400,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  Payment Method
                </Text>
                <Text
                  style={{
                    fontSize: "1.5em",
                    fontWeight: 400,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  {OrderedItem[0]?.payments[0]?.name}
                </Text>
              </Space>
              <Space
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  paddingTop: "2vh",
                }}
              >
                <Text
                  style={{
                    fontSize: "1.5em",
                    fontWeight: 400,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  Total Items
                </Text>
                <Text
                  style={{
                    fontSize: "1.5em",
                    fontWeight: 400,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  {OrderedItem[0]?.totalQty}
                </Text>
              </Space>
              <Space
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  paddingTop: "2vh",
                }}
              >
                <Text
                  style={{
                    fontSize: "1.5em",
                    fontWeight: 400,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  Subtotal
                </Text>
                <Text
                  style={{
                    fontSize: "1.5em",
                    fontWeight: 400,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  {(OrderedItem[0]?.total - OrderedItem[0]?.tax).toFixed(2)}
                </Text>
              </Space>
              {/* <Space
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  paddingTop: "2vh",
                }}
              >
                <Text
                  style={{
                    fontSize: "1.5em",
                    fontWeight: 400,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  Discount Sales
                </Text>
                <Text
                  style={{
                    fontSize: "1.5em",
                    fontWeight: 400,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  -4
                </Text>
              </Space> */}
              <Space
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  paddingTop: "2vh",
                }}
              >
                <Text
                  style={{
                    fontSize: "1.5em",
                    fontWeight: 400,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  Total Sales Tax
                </Text>
                <Text
                  style={{
                    fontSize: "1.5em",
                    fontWeight: 400,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  {typeof OrderedItem[0]?.tax === "number" && OrderedItem[0].tax % 1 !== 0 ? parseFloat(OrderedItem[0].tax.toFixed(2)) : OrderedItem[0]?.tax}
                </Text>
              </Space>
              <Space
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  paddingTop: "2vh",
                }}
              >
                <Text
                  style={{
                    fontSize: "1.5em",
                    fontWeight: 600,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  Total Amount
                </Text>
                <Text
                  style={{
                    fontSize: "1.5em",
                    fontWeight: 600,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  {OrderedItem[0]?.total}
                </Text>
              </Space>
              <Space
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  paddingTop: "2vh",
                }}
              >
                <Text
                  style={{
                    fontSize: "1.5em",
                    fontWeight: 600,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  Amount Paid
                </Text>
                <Text
                  style={{
                    fontSize: "1.5em",
                    fontWeight: 600,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  {OrderedItem[0]?.paid + OrderedItem[0]?.change}
                </Text>
              </Space>
              <Space
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  paddingTop: "2vh",
                }}
              >
                <Text
                  style={{
                    fontSize: "1.5em",
                    fontWeight: 600,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  Change
                </Text>
                <Text
                  style={{
                    fontSize: "1.5em",
                    fontWeight: 600,
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  {OrderedItem[0]?.change}
                </Text>
              </Space>
            </Col>
            <Col
              span={12}
              style={{
                width: "100%",
                height: "96vh",
                backgroundColor: "#fff",
                borderRadius: "15px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "1vw",
              }}
            >
              <Text
                style={{
                  color: "#0F0718",
                  fontSize: "1.75em",
                  paddingBottom: "8vh",
                  fontWeight: 500,
                }}
              >
                How would you like your receipt ?
              </Text>
              <Space direction="vertical" size="large">
                <Button
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#2F3856",
                    height: "7.5vh",
                    color: "#fff",
                    fontSize: "1em",
                    fontWeight: 500,
                    width: "23vw",
                  }}
                  onClick={printReceipt()}
                >
                  Print Receipt
                </Button>
                {/* <Button
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#2F3856",
                    height: "7.5vh",
                    color: "#fff",
                    fontSize: "1em",
                    fontWeight: 500,
                    width: "23vw",
                  }}
                >
                  Digital Receipt
                </Button> */}
                <Button
                  style={{
                    borderRadius: "10px",
                    backgroundColor: "#fff",
                    height: "7.5vh",
                    color: "#0F0718",
                    fontWeight: 500,
                    fontSize: "1em",
                    width: "23vw",
                  }}
                  onClick={() => {
                    localStorage.setItem("dineIn", "Y");
                    setKeyValue("0");
                    setShowOrderCompleteFlag(false);
                    setPaymentVisible(false);
                  }}
                >
                  New Order
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
      ) : (
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: cart.items.length > 0 ? "69%" : "100%",
              height: "100vh",
            }}
          >
            {paymentVisible ? (
              <div>
                <Row style={{ padding: "2vh 1.5vw 0 1.5vw", fontSize: "1vw" }}>
                  <Col span={24}>
                    <p
                      style={{
                        fontWeight: 500,
                        paddingLeft: "3px",
                        fontSize: "1.25em",
                        color: "#0F0718",
                      }}
                    >
                      <img
                        src={Back}
                        style={{
                          height: "4vh",
                          cursor: "pointer",
                          paddingBottom: "0.1em",
                        }}
                        onClick={() => setPaymentVisible(false)}
                      />{" "}
                      Order Checkout
                    </p>
                  </Col>
                </Row>
                <Row style={{ textAlign: "center", fontSize: "1vw" }}>
                  <Col
                    span={24}
                    style={{
                      fontSize: "1.5em",
                      color: "#0F0718",
                      fontWeight: 500,
                    }}
                  >
                    Amount To Pay {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                    {cart.total - cart.paid}
                    {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
                  </Col>
                  <Col span={5}></Col>
                  <Col span={14}>
                    <Row justify="center" style={{ padding: "5% 1% 0 3.5%", height: "18.5vh" }} gutter={5}>
                      {_.sortBy(showPayments, "sequenceNo").map((pm, ind) => {
                        if (
                          (posConfig.loyaltyApplicable === "N" && pm.name === "Loyalty") ||
                          (!cart?.customer?.loyaltyLevel?.cwrLoyaltyLevelId && pm.name === "Loyalty") ||
                          (!cart?.customer?.iscredit && pm.name === "Credit") ||
                          pm.name.toLowerCase() === "cafe tips"
                        ) {
                          return null;
                        }
                        return (
                          <Col span={8} key={pm.finPaymentmethodId}>
                            <button
                              onClick={() => {
                                if (cart.items.length > 0) {
                                  // paymentModalInputRef?.current?.select();
                                  // paymentModalInputRef?.current?.focus();
                                  // setNumb(0);
                                  let orderTimeDetails = JSON.parse(localStorage.getItem("orderTimeDetails")) || {
                                    orderStartTime: "",
                                    orderEndTime: "",
                                    paymentStartTime: "",
                                  };
                                  if (orderTimeDetails.paymentStartTime === "") {
                                    orderTimeDetails = {
                                      ...orderTimeDetails,
                                      paymentStartTime: moment(new Date()).format(userPreferences.dateTimeFormat),
                                    };
                                    localStorage.setItem("orderTimeDetails", JSON.stringify(orderTimeDetails));
                                  }
                                  if (pm.name.toLowerCase() === "card" && !pm.integratedPayment && posConfig.enablePaymentData === "Y") {
                                    setIsCardPaymentFlag(true);
                                  }
                                  setSelectedPaymentMethod(pm);
                                }
                              }}
                              id={`sm-payment-${pm.name.toLowerCase()}`}
                              // ref={ind === 0 ? paymentCardRef : null}
                              style={{
                                backgroundColor: pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "#2F3856" : "#fff",
                                border: "1px solid #fff",
                                borderRadius: "7px",
                                height: "16vh",
                                width: "7.5vw",
                                cursor: "pointer",
                              }}
                              tabIndex={0}
                              onKeyPress={(e) => (e.charCode === 13 ? setSelectedPaymentMethod(pm) : null)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  flexDirection: "row",
                                  position: "relative",
                                  fontSize: "1vw",
                                }}
                              >
                                {/* {pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? (
                                  <img src={ticSelect} style={{ top: "-2vh", right: "0", height: "2.1vh", position: "absolute" }} />
                                ) : null} */}
                                <span
                                  className="payment"
                                  style={{
                                    color: pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "#fff" : "#0F0718",
                                    fontSize: "1.5em",
                                  }}
                                >
                                  {pm.name.toLowerCase() === "cash" ? (
                                    <>
                                      {/* {currenciesList[0]
                                        ? currenciesList[0].currSymbol
                                        : ""} */}
                                      <img
                                        src={Cash1}
                                        style={{ height: "3vh", marginTop: "-4vh", filter: pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "invert(1)" : "invert(0)", }}
                                      // color={pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "#fff" : "#0F0718"}
                                      />
                                      <p
                                        style={{
                                          position: "absolute",
                                          bottom: "-7.5vh",
                                          left: "25%",
                                        }}
                                      >
                                        {pm.name}
                                      </p>
                                    </>
                                  ) : pm.name.toLowerCase() === "card" ? (
                                    <>
                                      {/* <img
                                        src={Back}
                                        style={{ height: "2.5vh" }}
                                        color={
                                          pm.finPaymentmethodId ===
                                          selectedPaymentMethod.finPaymentmethodId
                                            ? "#fff"
                                            : "#0F0718"
                                        }
                                      /> */}
                                      <img
                                        src={Card1}
                                        style={{ height: "3.5vh", marginTop: "-4vh", filter: pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "invert(1)" : "invert(0)", }}
                                      // color={pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "#fff" : "#0F0718"}
                                      />
                                      <p
                                        style={{
                                          position: "absolute",
                                          bottom: "-7.5vh",
                                          left: "25%",
                                        }}
                                      >
                                        {pm.name}
                                      </p>
                                    </>
                                  ) : pm.name.toLowerCase() === "upi" ? (
                                    <>
                                      <img
                                        // src={Back}
                                        src={qrCodeIcon}
                                        style={{ height: "2.5vh", filter: pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "invert(1)" : "invert(0)", }}
                                      // color={pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "#fff" : "#0F0718"} 
                                      />
                                      <p
                                        style={{
                                          position: "absolute",
                                          bottom: "-7.5vh",
                                          left: "25%",
                                        }}
                                      >
                                        {pm.name}
                                      </p>
                                    </>
                                  ) : pm.name.toLowerCase() === "credit" ? (
                                    <>
                                      <img src={Credit} style={{ height: "2.5vh", filter: pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "invert(1)" : "invert(0)", }}
                                      // color={pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "#fff" : "#0F0718"}
                                      />
                                      <p
                                        style={{
                                          position: "absolute",
                                          bottom: "-7.5vh",
                                          left: "25%",
                                        }}
                                      >
                                        {pm.name}
                                      </p>
                                    </>
                                  ) : pm.name.toLowerCase() === "loyalty" ? (
                                    <>
                                      <img
                                        src={Loyality}
                                        style={{
                                          height: "2.5vh",
                                          left: "0.5em",
                                          filter: pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "invert(1)" : "invert(0)",
                                        }}
                                      // color={pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "#fff" : "#0F0718"}
                                      />
                                      <p
                                        style={{
                                          position: "absolute",
                                          bottom: "-7.5vh",
                                          left: "25%",
                                        }}
                                      >
                                        {pm.name}
                                      </p>
                                    </>
                                  ) : pm.name.toLowerCase() === "gift card" ? (
                                    <>
                                      <img
                                        src={Gift}
                                        style={{
                                          height: "2.5vh",
                                          marginBottom: "0.2em",
                                          filter: pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "invert(1)" : "invert(0)",
                                        }}
                                      // color={pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "#fff" : "#0F0718"}
                                      />
                                      <p
                                        style={{
                                          position: "absolute",
                                          bottom: "-7.5vh",
                                          left: "25%",
                                        }}
                                      >
                                        {pm.name}
                                      </p>
                                    </>
                                  ) : pm.name.toLowerCase() === "gift voucher" ? (
                                    <>
                                      <img
                                        src={giftVoucher}
                                        style={{
                                          height: "2.5vh",
                                          marginBottom: "0.2em",
                                          filter: pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "invert(1)" : "invert(0)",
                                        }}
                                      // color={pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "#fff" : "#0F0718"}
                                      />
                                      <p
                                        style={{
                                          position: "absolute",
                                          bottom: "-7.5vh",
                                          left: "25%",
                                        }}
                                      >
                                        {pm.name}
                                      </p>
                                    </>
                                  ) : pm.name.toLowerCase() === "credit note" ? (
                                    <>
                                      <img src={creditNote} style={{ height: "2.5vh", filter: pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "invert(1)" : "invert(0)", }}
                                      // color={pm.finPaymentmethodId === selectedPaymentMethod.finPaymentmethodId ? "#fff" : "#0F0718"}
                                      />
                                      <p
                                        style={{
                                          position: "absolute",
                                          bottom: "-7.5vh",
                                          left: "25%",
                                        }}
                                      >
                                        {pm.name}
                                      </p>
                                    </>
                                  ) : (
                                    ""
                                    // <p style={{ position: "absolute", bottom: "-7.5vh", left: "25%" }}>{pm.name}</p>
                                  )}
                                </span>
                              </div>
                            </button>
                          </Col>
                        );
                      })}
                      {cart.layAway === "Y" && posConfig.advanceLayaway === "Y" ? (
                        <Col
                          span={8}
                          onClick={() => {
                            setShowOrderCompleteFlag(true);
                            processOrder("layaway");
                          }}
                        >
                          <button
                            style={{
                              backgroundColor: "#046307C2",
                              border: "1px solid #fff",
                              borderRadius: "7px",
                              height: "8.6vh",
                              width: "100%",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                              }}
                            >
                              {/* <CheckOutlined style={{ fontSize: "3vh", color: "#fff" }} /> */}
                            </div>
                          </button>
                        </Col>
                      ) : null}

                      {filteredPaymentMethods.length > 6 && (
                        <Col span={8}>
                          <button
                            onClick={isShowingAll ? showPreviousItems : showMoreItems}
                            style={{
                              backgroundColor: "#2F3856",
                              border: "1px solid #fff",
                              borderRadius: "7px",
                              height: "16vh",
                              width: "7.5vw",
                              cursor: "pointer",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                              }}
                            >
                              <img style={{ height: "2.5vh", left: "0.5em" }} color={"#fff"} />
                              <span className="payment" style={{ color: "#fff", marginLeft: "0.25em" }}>
                                ...
                              </span>
                            </div>
                          </button>
                        </Col>
                      )}
                    </Row>

                    <Row style={{ margin: "0 0 0 0 ", paddingTop: "10%" }}>
                      <Input
                        className="productSearch sm-payment-search totalInput"
                        style={{
                          borderRadius: "0.5vw",
                          height: "7.3vh",
                          margin: "0 1% 2.7% 3.5%",
                          borderRadius: "5px",
                          textAlign: "right",
                          fontWeight: "500",
                          fontSize: "2.5em",
                          borderColor: "#fff",
                          color: "#2F3856",
                        }}
                        // prefix={<span style={{ color: "#0F0718", fontWeight: "600" }}>Amount Tendered</span>}
                        value={amount}
                        // type="number"
                        id="sm-total-amount-input"
                        onChange={onChangeAmount}
                        placeholder="Enter Amount"
                        ref={paymentModalInputRef}
                        onFocus={() => {
                          setIsInputFocused(true);
                        }}
                        onBlur={() => {
                          setIsInputFocused(false);
                        }}
                        readOnly={cart.items.length > 0 ? false : true}
                        disabled={tillData.tillAccess.cwrTill.layAway === "Y" && tillData.tillAccess.cwrTill.payNow === "N" ? true : false}
                        onPressEnter={() => {
                          if (parseFloat(cart.total) > parseFloat(cart.paid)) {
                            if (parseFloat(amount) % 0.25 !== 0 && selectedPaymentMethod?.name?.toLowerCase() === "cash") {
                              {
                                // message.warning(`${t("cash_payment_error")}`);
                              }
                            } else {
                              if (parseFloat(Math.abs(cart.total)) <= parseFloat(cart.paid) + Math.abs(amount)) {
                                setShowOrderCompleteFlag(true);
                              }
                              requestPayment(selectedPaymentMethod, amount);
                            }
                          } else {
                            setShowOrderCompleteFlag(true);
                            processOrder();
                          }
                        }}
                      />
                    </Row>
                    <Row style={{ padding: "5% 1% 0 3.5%", fontSize: "1vw" }} gutter={[16, 16]}>
                      <Col span={6}>
                        <button id="sm-amount-button1" className="amt-dial-btn-restaurant" onClick={() => handleAmount("1")}>
                          1
                        </button>
                      </Col>
                      <Col span={6}>
                        <button id="sm-amount-button2" className="amt-dial-btn-restaurant" onClick={() => handleAmount("2")}>
                          2
                        </button>
                      </Col>
                      <Col span={6}>
                        <button id="sm-amount-button3" className="amt-dial-btn-restaurant" onClick={() => handleAmount("3")}>
                          3
                        </button>
                      </Col>
                      <Col span={6}>
                        <button className="amt-dial-btn-restaurant" id="sm-amount-buttonRemove" onClick={() => handleAmount("x")}>
                          <img src={CancelArrow} alt="back space" width={25} height={15} />
                        </button>
                      </Col>
                      <Col span={6}>
                        <button id="sm-amount-button4" className="amt-dial-btn-restaurant" onClick={() => handleAmount("4")}>
                          4
                        </button>
                      </Col>
                      <Col span={6}>
                        <button id="sm-amount-button4" className="amt-dial-btn-restaurant" onClick={() => handleAmount("5")}>
                          5
                        </button>
                      </Col>
                      <Col span={6}>
                        <button id="sm-amount-button6" className="amt-dial-btn-restaurant" onClick={() => handleAmount("6")}>
                          6
                        </button>
                      </Col>
                      <Col span={6}>
                        <button id="sm-amount-button." className="amt-dial-btn-restaurant" onClick={() => handleAmount(".")}>
                          .
                        </button>
                      </Col>
                      {/* <Col span={6}>
                          <button id="sm-amount-buttonClear" className="amt-dial-btn-restaurant" onClick={() => setDenaminationsKeyboard(!denaminationsKeyboard)}>
                            1,2,3...
                          </button>
                        </Col> */}
                      <Col span={6}>
                        <button id="sm-amount-button7" className="amt-dial-btn-restaurant" onClick={() => handleAmount("7")}>
                          7
                        </button>
                      </Col>
                      <Col span={6}>
                        <button id="sm-amount-button8" className="amt-dial-btn-restaurant" onClick={() => handleAmount("8")}>
                          8
                        </button>
                      </Col>
                      <Col span={6}>
                        <button id="sm-amount-button0" className="amt-dial-btn-restaurant" onClick={() => handleAmount("9")}>
                          9
                        </button>
                      </Col>
                      <Col span={6}>
                        <button id="sm-amount-button0" className="amt-dial-btn-restaurant" onClick={() => handleAmount("0")}>
                          0
                        </button>
                      </Col>
                      {/* <Col span={8}>
                              <button id="sm-amount-button00" className="amt-dial-btn-restaurant" onClick={() => handleAmount("00")}>
                                00
                              </button>
                            </Col> */}
                      {/* <Col span={6}>
                          <button
                            id="sm-amount-buttonEnter"
                            className="amt-dial-btn-market2"
                            onClick={() => {
                              const isGiftCardOrLoyalty =
                                selectedPaymentMethod?.name?.toLowerCase() === "card" ||
                                selectedPaymentMethod?.name?.toLowerCase() === "cash" ||
                                selectedPaymentMethod?.name?.toLowerCase() === "credit";
                              if (isGiftCardOrLoyalty) {
                                if (parseFloat(Math.abs(cart.total)) > parseFloat(cart.paid)) {
                                  if (parseFloat(amount) % 0.25 !== 0 && selectedPaymentMethod?.name.toLowerCase() === "cash") {
                                    {
                                      // message.warning(`${t("cash_payment_error")}`);
                                    }
                                  } else {
                                    setNumb(0);
                                    // Handle successful entry, e.g., submit the amount
                                    requestPayment(selectedPaymentMethod, amount);
                                  }
                                } else {
                                  processOrder();
                                }
                              }
                            }}
                          >
                            Enter
                          </button>
                        </Col> */}
                    </Row>
                  </Col>
                  <Col span={5}></Col>
                </Row>
              </div>
            ) : (
              <div>
                <Row>
                  <Col span={10} className="floor-buttons">
                    <Button
                      onClick={() => {
                        setOrderTypeData(0);
                      }}
                      className={`floor-button ${orderTypeData === 0 ? "selected" : ""}`}
                    >
                      In process
                    </Button>
                    <Button
                      onClick={() => {
                        setOrderTypeData(1);
                      }}
                      className={`floor-button ${orderTypeData === 1 ? "selected" : ""}`}
                    >
                      Ready
                    </Button>
                    <Button
                      onClick={() => {
                        setOrderTypeData(2);
                      }}
                      className={`floor-button ${orderTypeData === 2 ? "selected" : ""}`}
                    >
                      Completed
                    </Button>
                  </Col>
                </Row>
                <Scrollbars autoHide style={{ height: "90vh" }}>
                  <InfiniteScroll
                    dataLength={tableOrderData.length}
                    hasMore={true}
                    endMessage={
                      <p className="center">
                        <b>Yay! you have seen it all</b>
                      </p>
                    }
                    style={{ overflowX: "hidden" }}
                  >
                    <Row
                      style={{
                        marginTop: "2vh",
                        paddingLeft: "1vw",
                        paddingRight: "1vw",
                      }}
                    >
                      {tableOrderData.map((orderData) => {
                        return (
                          <Col span={cart.items.length > 0 ? 12 : 8} style={{ padding: "1vh 0.6vw" }}>
                            <Card
                              onClick={() => {
                                orderTypeData === 2 ? "" : setSelectedTable(orderData);
                              }}
                              style={{
                                backgroundColor: "#fff",
                                padding: "1vh 0.7vw",
                                borderRadius: "10px",
                                height: "14vh",
                                fontSize: "1vw",
                                cursor: "pointer",
                              }}
                            >
                              <Space
                                style={{
                                  width: "100%",
                                  justifyContent: "space-between",
                                  paddingLeft: "7px",
                                  paddingRight: "7px",
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: "1.25em",
                                    fontWeight: 600,
                                    color: "#0F0718",
                                    fontFamily: "Inter",
                                  }}
                                >
                                  Order {orderData?.documentno || null}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: "1.25em",
                                    fontWeight: 500,
                                    color: "#929098",
                                    fontFamily: "Inter",
                                  }}
                                >
                                  {moment(orderData.created).format("HH:mm:ss")}
                                </Text>
                              </Space>
                              <Space
                                style={{
                                  width: "100%",
                                  justifyContent: "space-between",
                                  paddingLeft: "7px",
                                  paddingRight: "7px",
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: "1em",
                                    fontWeight: 500,
                                    color: "#0F0718",
                                    fontFamily: "Inter",
                                  }}
                                >
                                  {orderData.name}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: "1em",
                                    fontWeight: 500,
                                    color: "#929098",
                                    fontFamily: "Inter",
                                  }}
                                ></Text>
                              </Space>
                              <Space
                                style={{
                                  width: "100%",
                                  justifyContent: "space-between",
                                  paddingLeft: "7px",
                                  paddingRight: "7px",
                                }}
                              >
                                <Text
                                  style={{
                                    fontSize: "1em",
                                    fontWeight: 500,
                                    color: "#0F0718",
                                    fontFamily: "Inter",
                                  }}
                                >
                                  Ordered Items: {orderData?.items?.length || 0}
                                </Text>
                                <Text
                                  style={{
                                    fontSize: "1.25em",
                                    fontWeight: 600,
                                    color: "#0F0718",
                                    fontFamily: "Inter",
                                  }}
                                >
                                  Amount: {orderData?.totalAmount || orderData?.total || 0}
                                </Text>
                              </Space>
                            </Card>
                          </Col>
                        );
                      })}
                    </Row>
                  </InfiniteScroll>
                </Scrollbars>
              </div>
            )}
          </div>
          {cart.items.length > 0 ? (
            <div
              style={{
                background: "#fff",
                height: "100vh",
                width: "31%",
                position: "relative",
                fontSize: "1vw",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "2vh 1vw 0 1vw",
                }}
              >
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: "1.5em",
                    color: "#0F0718",
                    fontFamily: "Inter",
                  }}
                >
                  Order Details
                </span>
                <span style={{ fontWeight: 500, fontSize: "1em", color: "#929098" }}>Tue, 1 Aug 2023</span>
              </div>
              <p
                style={{
                  padding: "0 1vw",
                  fontSize: "1em",
                  color: "#0F0718",
                  fontWeight: 500,
                }}
              >
                {selectedTable?.name}
              </p>
              <hr style={{ color: "#929098", width: "93%" }} />
              <Scrollbars autoHide style={{ height: "58vh" }}>
                <InfiniteScroll
                  dataLength={cart.items.length}
                  hasMore={true}
                  endMessage={
                    <p className="center">
                      <b>Yay! you have seen it all</b>
                    </p>
                  }
                  style={{ overflowX: "hidden" }}
                >
                  {cart.items.map((item) => {
                    return (
                      <div style={{ padding: "12px" }}>
                        <Row>
                          <Col span={5}>
                            <img
                              src={item.imageurl}
                              alt={item.name}
                              style={{
                                height: "8vh",
                                width: "5.5vw",
                                objectFit: "cover",
                                borderRadius: "7px",
                              }}
                            />
                          </Col>
                          <Col
                            span={16}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              paddingLeft: "0.5vw",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: "1em",
                                color: "#0F0718",
                                fontWeight: 500,
                              }}
                            >
                              {item.name}
                            </Text>
                            <Text
                              style={{
                                fontSize: "0.8em",
                                color: "#929098",
                                fontWeight: 400,
                              }}
                            >
                              {item.description}
                            </Text>
                            <Text
                              style={{
                                fontWeight: 600,
                                fontSize: "1.25em",
                                color: "#0F0718",
                              }}
                            >
                              {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                              {item.salePrice}
                              {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
                            </Text>
                          </Col>
                          <Col
                            span={2}
                            style={{
                              textAlign: "center",
                              color: "#0F0718",
                              fontWeight: 500,
                              fontSize: "1.1em",
                            }}
                          >
                            X {item.weight}
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </InfiniteScroll>
              </Scrollbars>
              <div
                style={{
                  padding: "1vh 0.6vw",
                  position: "absolute",
                  bottom: "1vh",
                  width: "100%",
                }}
              >
                <Space
                  direction="vertical"
                  style={{
                    width: "100%",
                    background: "#F3F4F9",
                    padding: "1vh 0.5vw",
                    borderRadius: "10px",
                    rowGap: "4px",
                  }}
                >
                  <Space style={{ width: "100%", justifyContent: "space-between" }}>
                    <Text
                      style={{
                        fontSize: "1em",
                        fontWeight: 500,
                        color: "#929098",
                        fontFamily: "Inter",
                      }}
                    >
                      Subtotal
                    </Text>
                    <Text
                      style={{
                        fontSize: "1em",
                        fontWeight: 500,
                        color: "#929098",
                        fontFamily: "Inter",
                      }}
                    >
                      {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                      {(
                        parseFloat(localStorage.getItem("cartObj") ? JSON.parse(localStorage.getItem("cartObj"))?.total : cart.total).toFixed(2) -
                        parseFloat(localStorage.getItem("cartObj") ? JSON.parse(localStorage.getItem("cartObj"))?.tax : cart.tax).toFixed(2)
                      ).toFixed(2)}
                      {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
                    </Text>
                  </Space>
                  <Space style={{ width: "100%", justifyContent: "space-between" }}>
                    <Text
                      style={{
                        fontSize: "1em",
                        fontWeight: 500,
                        color: "#929098",
                        fontFamily: "Inter",
                      }}
                    >
                      Discount Sales
                    </Text>
                    <Text
                      style={{
                        fontSize: "1em",
                        fontWeight: 500,
                        color: "#929098",
                        fontFamily: "Inter",
                      }}
                    >
                      {console.log(cart)}
                      {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                      {parseFloat(cart?.discount || 0).toFixed(2)}
                      {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
                    </Text>
                  </Space>
                  <Space style={{ width: "100%", justifyContent: "space-between" }}>
                    <Text
                      style={{
                        fontSize: "1em",
                        fontWeight: 500,
                        color: "#929098",
                        fontFamily: "Inter",
                      }}
                    >
                      Total Sales Tax
                    </Text>
                    <Text
                      style={{
                        fontSize: "1em",
                        fontWeight: 500,
                        color: "#929098",
                        fontFamily: "Inter",
                      }}
                    >
                      {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                      {parseFloat(cart.tax).toFixed(2)}
                      {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
                    </Text>
                  </Space>
                  <Space
                    style={{
                      width: "100%",
                      justifyContent: "space-between",
                      marginTop: "2px",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: "1.1em",
                        fontWeight: 600,
                        color: "#0F0718",
                        fontFamily: "Inter",
                      }}
                    >
                      Total Amount
                    </Text>
                    <Text
                      style={{
                        fontSize: "1.1em",
                        fontWeight: 600,
                        color: "#0F0718",
                        fontFamily: "Inter",
                      }}
                    >
                      {currenciesList[0].symbolRightSide === "Y" ? `${currenciesList[0].currSymbol} ` : ""}
                      {parseFloat(cart.total).toFixed(2)}
                      {currenciesList[0].symbolRightSide === "N" ? ` ${currenciesList[0].currSymbol} ` : ""}
                    </Text>
                  </Space>
                </Space>
                <div>
                  <Space direction="horizontal" style={{ paddingTop: "2vh" }}>
                    <Button
                      onClick={() => {
                        const isGiftCardOrLoyalty =
                          selectedPaymentMethod?.name?.toLowerCase() === "card" ||
                          selectedPaymentMethod?.name?.toLowerCase() === "cash" ||
                          selectedPaymentMethod?.name?.toLowerCase() === "credit";
                        if (isGiftCardOrLoyalty && paymentVisible) {
                          if (parseFloat(Math.abs(cart.total)) > parseFloat(cart.paid)) {
                            if (parseFloat(amount) % 0.25 !== 0 && selectedPaymentMethod?.name.toLowerCase() === "cash") {
                              {
                                // message.warning(`${t("cash_payment_error")}`);
                              }
                            } else {
                              setNumb(0);
                              if (parseFloat(Math.abs(cart.total)) <= parseFloat(cart.paid) + Math.abs(amount)) {
                                setShowOrderCompleteFlag(true);
                              }
                              // Handle successful entry, e.g., submit the amount
                              requestPayment(selectedPaymentMethod, amount);
                            }
                          } else {
                            setShowOrderCompleteFlag(true);
                            processOrder();
                          }
                        } else if (paymentVisible === false) {
                          setPaymentVisible(true);
                        }
                      }}
                      style={{
                        width: "27.8vw",
                        borderRadius: "10px",
                        backgroundColor: "#2F3856",
                        color: "#fff",
                        height: "6vh",
                        fontSize: "1em",
                        fontWeight: 500,
                      }}
                    >
                      {paymentVisible ? "Add Payment" : "Pay Bill"}
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default OrderListComponent;
