const CashManagementIcon = ({color}) => {
    return (
<svg width="1.8vw" height="1.5vw" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.7675 13.0175C22.5 12.285 22.5 11.1075 22.5 8.75C22.5 6.3925 22.5 5.215 21.7675 4.4825M21.7675 13.0175C21.035 13.75 19.8575 13.75 17.5 13.75H12.5C10.1425 13.75 8.965 13.75 8.2325 13.0175M21.7675 4.4825C21.035 3.75 19.8575 3.75 17.5 3.75H12.5C10.1425 3.75 8.965 3.75 8.2325 4.4825M8.2325 4.4825C7.5 5.215 7.5 6.3925 7.5 8.75C7.5 11.1075 7.5 12.285 8.2325 13.0175M16.25 8.75C16.25 9.08152 16.1183 9.39946 15.8839 9.63388C15.6495 9.8683 15.3315 10 15 10C14.6685 10 14.3505 9.8683 14.1161 9.63388C13.8817 9.39946 13.75 9.08152 13.75 8.75C13.75 8.41848 13.8817 8.10054 14.1161 7.86612C14.3505 7.6317 14.6685 7.5 15 7.5C15.3315 7.5 15.6495 7.6317 15.8839 7.86612C16.1183 8.10054 16.25 8.41848 16.25 8.75Z" stroke={color} stroke-width="1.5"/>
<path d="M22.5 7.5C21.5054 7.5 20.5516 7.10491 19.8483 6.40165C19.1451 5.69839 18.75 4.74456 18.75 3.75M22.5 10C21.5054 10 20.5516 10.3951 19.8483 11.0983C19.1451 11.8016 18.75 12.7554 18.75 13.75M7.5 7.5C8.49456 7.5 9.44839 7.10491 10.1517 6.40165C10.8549 5.69839 11.25 4.74456 11.25 3.75M7.5 10C8.49456 10 9.44839 10.3951 10.1517 11.0983C10.8549 11.8016 11.25 12.7554 11.25 13.75M6.25 25.485H9.075C10.3375 25.485 11.6163 25.6175 12.845 25.87C15.0388 26.3207 17.296 26.3707 19.5075 26.0175C20.5925 25.8425 21.6575 25.5737 22.6225 25.1087C23.4925 24.6875 24.5587 24.0963 25.275 23.4325C25.99 22.77 26.735 21.6862 27.2625 20.8387C27.7175 20.1112 27.4975 19.22 26.78 18.6787C26.3768 18.3855 25.8911 18.2275 25.3925 18.2275C24.8939 18.2275 24.4082 18.3855 24.005 18.6787L21.7462 20.385C20.8712 21.0475 19.915 21.6562 18.7762 21.8375C18.6387 21.8587 18.495 21.8788 18.345 21.8963M18.345 21.8963C18.2992 21.9016 18.2534 21.9066 18.2075 21.9112M18.345 21.8963C18.5451 21.8421 18.7285 21.739 18.8787 21.5963C19.0671 21.4333 19.2209 21.2343 19.3311 21.0109C19.4413 20.7875 19.5056 20.5444 19.5203 20.2957C19.535 20.0471 19.4997 19.798 19.4166 19.5633C19.3334 19.3285 19.2041 19.1127 19.0363 18.9287C18.8734 18.7479 18.6836 18.5932 18.4738 18.47C14.9775 16.3837 9.53625 17.9725 6.25 20.3038M18.345 21.8963C18.2998 21.9061 18.2537 21.9111 18.2075 21.9112M18.2075 21.9112C17.4536 21.987 16.6942 21.9887 15.94 21.9163" stroke={color} stroke-width="1.5" stroke-linecap="round"/>
<path d="M6.25 19.375C6.25 18.3395 5.41053 17.5 4.375 17.5C3.33947 17.5 2.5 18.3395 2.5 19.375V25.625C2.5 26.6605 3.33947 27.5 4.375 27.5C5.41053 27.5 6.25 26.6605 6.25 25.625V19.375Z" stroke={color} stroke-width="1.5"/>
</svg>

        

    )
}
export default CashManagementIcon;