import { useEffect } from "react";

let dataString = "";
let timeoutId;
let timeStamp;

const BarCodeComponent = (props) => {
  const { onScan, setProductSearchInput, productSearchInput, barcodeProps } = props;

  const handleKeyPress = (e) => {
    const { charCode, key } = e;

    if (timeoutId === undefined && dataString !== "") {
      timeoutId = setTimeout(() => {
        dataString = "";
        timeoutId = undefined;
      }, 1800);
    }

    if ((charCode >= 48 && charCode <= 57) || (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode === 45) {
      dataString += key;
    }

    let productName = localStorage.getItem("productName");
    if (dataString !== "" && charCode === 13 && productName === null) {
      setProductSearchInput("")
      localStorage.removeItem("productName","")
      onScan(dataString,false);
      dataString = "";
      clearTimeout(timeoutId);
      timeoutId = undefined;
    } else if (productName !== null && charCode === 13) {
      let prodCode = localStorage.getItem("productName")
      setProductSearchInput("");
      dataString = "";
      localStorage.removeItem("productName","")
      onScan(prodCode,false);
    }
  };

  useEffect(() => {
    window.addEventListener("keypress", handleKeyPress);
    return () => {
      dataString = "";
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, []); // eslint-disable-line

  return null;
};

export default BarCodeComponent;
