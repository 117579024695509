import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Card, Typography, Row, Col, Divider, Collapse, Select, Input, Button, Popover, Table, Spin } from "antd";
import { CalendarOutlined, LoadingOutlined } from "@ant-design/icons";
import menuIcon from "../../assets/images/menu.svg";
import searchIcon from "../../assets/images/mingcute_search-line.svg";
import filterIcon from "../../assets/images/mdi_filter.svg";
import dropdownIcon from "../../assets/images/mingcute_down-line.svg";
import refreshIcon from "../../assets/images/Vector.svg";
import PieChart from "../../assets/images/carbon_in-progress.svg";
import TickCircle from "../../assets/images/mdi_tick-circle.svg";
import CustomerSearch from "../../assets/images/c-serach.svg";
import Error from "../../assets/images/bxs_error.svg";
import Arrow from "../../assets/images/arrow.svg";
import Back from "../../assets/images/backIcon.svg";
import db from "../../database";
import Axios from "axios";
import { getOAuthHeaders } from "../../constants/oAuthValidation";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { Scrollbars } from "react-custom-scrollbars";

function Dashboard2() {
  const { Panel } = Collapse;
  const [productsList, setProductsList] = useState([]);
  // const [categoriesData, setCategoriesData] = useState([]);
  // const [brandsData, setBrandsData] = useState([]);
  const [ordersCopy, setOrdersCopy] = useState([]);
  const [selectGiftCardItem, setSelectGiftCardItem] = useState([]);
  const [tillEvents, setTillEvents] = useState([]);
  const [dataSyncSummery, setDataSyncSummery] = useState([]);
  const [logActivity, setLogActivity] = useState([]);
  const [isViewOrder, setIsViewOrder] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [syncItem, isSyncItem] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  // const [searchHistoryInput, setSearchHistoryInput] = useState(""); // For search input
  // const [orderHistoryDetails, setOrderHistoryDetails] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  let tillData = JSON.parse(localStorage.getItem("tillData"));
  const [pageRefreshFlag, setPageRefreshFlag] = useState(false);
  const serverUrl = process.env.REACT_APP_serverUrl;
  const colorVariable = process.env.REACT_APP_POSBACKGROUND_COLOR;
  // const tableBodyRef = useRef(null);

  const [productsData, setProductsData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const [pricingRulesData, setPricingRulesData] = useState([]);
  const [posSaleTypesData, setPosSaleTypesData] = useState([]);
  const [giftCardData, setGiftCardData] = useState([]);
  const [uomData, setUOMData] = useState([]);
  const [approversData, setApproversData] = useState([]);
  const [loyaltyData, setLoyaltyData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [displayedData, setDisplayedData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(50);
  const [loading, setLoading] = useState(false);

  const tableRef = useRef(null);
  let scrollLeft;
  let maxScroll;
  let currentScroll;

  const fetchMoreData = (event) => {
    // setLoading(true);
    const lengthOff = localStorage.getItem("dataLength");
    maxScroll = event.target.scrollHeight - event.target.clientHeight;
    currentScroll = event.target.scrollTop;
    if (scrollLeft !== event.target.scrollLeft) {
      scrollLeft = event.target.scrollLeft;
      return null;
    }
    if (Math.round(currentScroll) >= Math.round(maxScroll) - 1) {
      let data = [];
      if (selectedItem) {
        switch (selectedItem.dataKey) {
          case "products":
            data = productsData;
            break;
          case "categories":
            data = categoriesData;
            break;
          case "brands":
            data = brandsData;
            break;
          case "pricingRules":
            data = pricingRulesData;
            break;
          case "posSaletypes":
            data = posSaleTypesData;
            break;
          case "giftCardData":
            data = giftCardData;
            break;
          case "UOM":
            data = uomData;
            break;
          case "approvers":
            data = approversData;
            break;
          case "loyalityData":
            data = loyaltyData;
            break;
          default:
            data = [];
        }

        // Apply search filter
        if (searchQuery) {
          data = data.filter((item) => {
            const codeMatches = item.value && item.value.toLowerCase().includes(searchQuery.toLowerCase());
            const nameMatches = item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase());
            return codeMatches || nameMatches;
          });
        }
      }
      if (displayedData.length < data.length) {
        console.log(Math.round(currentScroll), Math.round(maxScroll));
        const newItemsToShow = itemsToShow + 50;
        setItemsToShow(newItemsToShow);
        setDisplayedData(allData.slice(0, newItemsToShow));
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const antTableBody = tableRef.current;
    if (antTableBody) {
      antTableBody.addEventListener("scroll", fetchMoreData);
      return () => {
        antTableBody.removeEventListener("scroll", fetchMoreData);
      };
    }
  }, [selectedItem, itemsToShow]);

  useEffect(async () => {
    db.products
      .limit(3)
      .toArray()
      .then((productsFetched) => {
        setProductsList([...productsFetched]);
      });

    db.dataSyncSummary
      .where("syncType")
      .equals("Full Sync")
      .reverse()
      .sortBy("syncEndTime")
      .then((latestFullSync) => {
        if (latestFullSync.length > 0) {
          setDataSyncSummery([latestFullSync[0]]);
        } else {
          // Fetch the last incremental sync data if no full sync data is found
          db.dataSyncSummary
            .where("syncType")
            .equals("Incremental Sync")
            .reverse()
            .sortBy("syncEndTime")
            .then((lastIncrementalSync) => {
              if (lastIncrementalSync.length > 0) {
                setDataSyncSummery([lastIncrementalSync[0]]);
              }
            });
        }
      });

    db.logActivity
      .reverse()
      .sortBy("syncEndTime")
      .then((response) => {
        let logData = response.slice(0, 7);

        const typeToHeaderText = {
          GCI: "Gift Card Issue",
          ACT: "Add Customer",
          RCT: "Remove Customer",
          CSU: "Customer Updates",
          LGI: "Login",
          LGO: "Logout",
          CAI: "Cash In",
          CAO: "Cash Out",
          RRC: "Reprint Receipt",
          DLN: "Deleted Lines",
          DON: "Day Opening",
          DCL: "Day Closing",
          DOR: "Delete Order",
          SRD: "Sales Rep Delete",
          LSD: "Line Sales Rep Delete",
          SLR: "Sales Return",
          DPO: "Delete Parked order",
          RQT: "Reduce Qty",
          LAY: "Layaway",
          PAY: "Payment",
          DAP: "Discounts Applied",
          OPK: "Order Parked/Retrieved",
          GCR: "Gift Card Redeem",
          CUP: "Coupons",
          LOR: "Loyalty Redemption",
        };

        logData = logData.map((item) => ({
          ...item,
          title: typeToHeaderText[item.type] || "",
        }));

        setLogActivity(logData);
        console.log(logData, "logData");
      });

    db.productBrands
      .limit(2)
      .toArray()
      .then((brandsFetched) => {
        setBrandsData([...brandsFetched]);
      });

    db.productCategories.toArray().then((categoriesFetched) => {
      setCategoriesData([...categoriesFetched]);
    });

    db.orders
      .orderBy("orderTime")
      .toArray()
      .then((response) => {
        // Sort by isSynced value first (desc), then by orderTime (desc)
        let sortedData = response.sort((a, b) => {
          if (a.isSynced === b.isSynced) {
            return new Date(b.orderTime) - new Date(a.orderTime); // descending order for orderTime
          }
          return b.isSynced - a.isSynced; // descending order for isSynced
        });

        // Get the top 10 most recently synced orders
        let data = sortedData.slice(0, 10);
        console.log("Final orders data (top 10):", data);
        setOrdersCopy([...data]);
      })
      .catch((error) => {
        console.error("Failed to get orders:", error);
      });

    db.giftCardData
      .limit(2)
      .toArray()
      .then((giftCardData) => {
        setSelectGiftCardItem(giftCardData);
      });

    db.tillEvents
      .reverse()
      .sortBy("openingTime")
      .then((sortedData) => {
        let events = sortedData.slice(0, 10);
        setTillEvents(events);
        console.log("Till Events:", events);
      })
      .catch((error) => {
        console.error("Error fetching unsynced events:", error);
      });
  }, [pageRefreshFlag]);

  useEffect(() => {
    // Set the initial data when the selected item changes
    if (selectedItem) {
      let data = [];
      switch (selectedItem.dataKey) {
        case "products":
          data = productsData;
          break;
        case "categories":
          data = categoriesData;
          break;
        case "brands":
          data = brandsData;
          break;
        case "pricingRules":
          data = pricingRulesData;
          break;
        case "posSaletypes":
          data = posSaleTypesData;
          break;
        case "giftCardData":
          data = giftCardData;
          break;
        case "UOM":
          data = uomData;
          break;
        case "approvers":
          data = approversData;
          break;
        case "loyalityData":
          data = loyaltyData;
          break;
        default:
          data = [];
      }

      // Apply search filter
      if (searchQuery) {
        data = data.filter((item) => {
          const codeMatches = item.value && item.value.toLowerCase().includes(searchQuery.toLowerCase());
          const nameMatches = item.name && item.name.toLowerCase().includes(searchQuery.toLowerCase());
          return codeMatches || nameMatches;
        });
      }

      setAllData(data);
      // setItemsToShow(0);
      setDisplayedData(data.slice(0, itemsToShow)); // Show the first 50 items
    }
  }, [selectedItem, searchQuery, itemsToShow]);

  const formatOrderTime = (orderTime) => {
    const date = new Date(orderTime);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes}${period}`;
  };

  const syncOrders = async (order) => {
    const nWStatus = navigator.onLine ? "online" : "offline";
    let setAuthTokens;
    const authHeaders = await getOAuthHeaders();
    if (authHeaders && authHeaders.access_token) {
      setAuthTokens = authHeaders.access_token;
    }
    let shouldCallAxios = false;
    const posConfig = JSON.parse(localStorage.getItem("posConfig"));
    let saleTypeId = null;
    if (localStorage.getItem("saleTypeData") !== null) {
      const saleTypeData = JSON.parse(localStorage.getItem("saleTypeData"));
      saleTypeId = saleTypeData?.cwrSaletype?.cwrSaletypeId;
    };
    if (nWStatus === "online") {
      db.logInformation.toArray().then((fetched) => {
        if (fetched.length > 0) {
          const posLogArray = [];
          const trxId = uuidv4().replace(/-/g, "").toUpperCase();
          fetched.forEach((item) => {
            let value = { ...item };
            db.logInformation.where("id").equals(value.id).delete();
            delete value.id;
            posLogArray.push(`{
          type: "${value.type}", 
          action: "LOG", 
          description: "${value.description}", 
          date: "${value.date}",
          time: "${value.time}",    
          orderNo: "${value.orderNo}",
          remarks: "${value.remarks}",
          transactionId: "${trxId}"
          status: "SCS"
          duration: ${value.duration}
      }`);
            if (posConfig?.[value.type] === "Y") {
              shouldCallAxios = true;
            }
          });
          if (shouldCallAxios) {
            Axios({
              url: serverUrl,
              method: "POST",
              data: {
                query: `mutation {
                upsertPOSLog(order: {
                  tillId: "${tillData.tillAccess.cwrTill.cwrTillID}"
                  userId: "${tillData.tillAccess.csUserId}" 
                  bUnitId: "${tillData.tillAccess.csBunit.csBunitId}", 
                  lines: [${posLogArray}]
                }) {
                status   
                message
              }
            }`,
              },
              headers: {
                "Content-Type": "Application/json",
                Authorization: `${setAuthTokens}`,
              },
            });
          }
        }
      });

      const csCurrencyId = localStorage.getItem("csCurrencyId");
      db.orders
        .where("documentno")
        .equals(order.documentno)
        .toArray()
        .then((orders) => {
          const pendingOrdersCount = orders.length;
          if (pendingOrdersCount > 0) {
            for (let i = 0; i < pendingOrdersCount; i += 1) {
              const regularOrders = orders.filter((order) => {
                const isRegularOrderWithPaid = order.layAway === "N" && order.paid !== 0;
                // const parkedOrders = order.
                const isRegularOrderWithoutPaid = (order.layAway === "N" || order.layAway === "Y") && order.paid === 0;
                const cancelledOrders = order.total === 0 && order.totalQty === 0;
                const parkedOrders = order.parked === "Y" && order.paid === 0;
                return isRegularOrderWithPaid || isRegularOrderWithoutPaid || cancelledOrders || parkedOrders;
              });

              if (regularOrders.length > 0) {
                const orderLines = [];
                for (let j = 0; j < regularOrders[i]?.items.length; j += 1) {
                  let unitTax = regularOrders[i].items[j].taxAmount / regularOrders[i].items[j].weight;
                  orderLines.push(`{
                  sOrderlineID: "${uuidv4().replace(/-/g, "").toUpperCase()}",
                  sOrderlineReturnId: ${regularOrders[i].items[j].sOrderlineReturnId ? `"${regularOrders[i].items[j].sOrderlineReturnId}"` : null}
                  created: "${regularOrders[i].orderTime}",
                  createdby: "${regularOrders[i].createdBy}",
                  updated: "${regularOrders[i].orderTime}",
                  updatedby: "${regularOrders[i].createdBy}",
                  sOrderId: "${regularOrders[i].sOrderID}",
                  line: ${(j + 1) * 10},
                  description: "${regularOrders[i].items[j].notes !== undefined && regularOrders[i].items[j].notes !== "" ? regularOrders[i].items[j].notes : ""}",
                  mProductId: "${regularOrders[i].items[j].productId ? regularOrders[i].items[j].productId : regularOrders[i].items[j].mProductId}",
                  csUomId: "${regularOrders[i].items[j].uom ? regularOrders[i].items[j].uom : regularOrders[i].items[j].uomData[0].csUomId}",
                  csTaxId: "${regularOrders[i].items[j].tax ? regularOrders[i].items[j].tax : regularOrders[i].items[j].cTaxId}",
                  qty: ${regularOrders[i].items[j].weight},
                  unitprice: ${parseFloat(regularOrders[i].items[j].realPrice) * (regularOrders[i].items[j].isReturn ? -1 : 1)},
                  netlist: ${parseFloat(regularOrders[i].items[j].netList)}
                  discount: ${regularOrders[i].items[j].discount},
                  returnline: ${regularOrders[i].items[j].isReturn ? regularOrders[i].items[j].isReturn : false},
                  returnQty: ${regularOrders[i].items[j].isReturn === true ? Math.abs(regularOrders[i].items[j].weight) : 0},
                  mBatchId: ${
                    regularOrders[i].items[j].hasOwnProperty("mBatchId") && regularOrders[i].items[j].mBatchId !== null
                      ? `"${regularOrders[i].items[j].mBatchId}"`
                      : regularOrders[i].items[j].hasOwnProperty("mBatch") && regularOrders[i].items[j].mBatch !== null
                      ? `"${regularOrders[i].items[j].mBatch}"`
                      : null
                  },
                  mPricingruleId: ${
                    regularOrders[i].items[j].mPricingruleId !== null && regularOrders[i].items[j].mPricingruleId !== undefined
                      ? `"${regularOrders[i].items[j].mPricingruleId}"`
                      : null
                  },
                  batchedForSale:"${regularOrders[i].items[j].batchedForSale}",
                  batchedForStock:"${regularOrders[i].items[j].batchedForStock}",
                  batchedProduct:"${regularOrders[i].items[j].batchedProduct}",
                  salesRepId: ${
                    regularOrders[i].items[j].salesRepId !== null && regularOrders[i].items[j].salesRepId !== undefined ? `"${regularOrders[i].items[j].salesRepId}"` : null
                  },
                  multiPrice: "${regularOrders[i].items[j].multiPrice}",
                  discountTypeId: null,
                  discountAmount: null,
                  unittax: ${unitTax > 0 ? parseFloat(unitTax.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)) * (regularOrders[i].items[j].isReturn ? -1 : 1) : 0}
                  linetax: ${parseFloat(regularOrders[i].items[j].linetax ? regularOrders[i].items[j].linetax : 0)}
                  linenet: ${regularOrders[i].items[j].linenet ? regularOrders[i].items[j].linenet : 0}
                  linegross: ${regularOrders[i].items[j].linegross ? regularOrders[i].items[j].linegross : 0}
                  netunit: ${
                    Math.abs(regularOrders[i].items[j].grossunit) > 0
                      ? parseFloat((regularOrders[i].items[j].grossunit - unitTax).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)) *
                        (regularOrders[i].items[j].isReturn ? -1 : 1)
                      : 0
                  }
                  netstd: ${Math.abs(regularOrders[i].items[j].netStd) ? parseFloat(regularOrders[i].items[j].netStd) : 0}
                  listprice: ${regularOrders[i].items[j].listPrice ? regularOrders[i].items[j].listPrice : 0}
                  grossunit: ${regularOrders[i].items[j].grossunit ? regularOrders[i].items[j].grossunit : 0}
                  grossstd: ${Math.abs(regularOrders[i].items[j].sunitprice) > 0 ? regularOrders[i].items[j].sunitprice * (regularOrders[i].items[j].isReturn ? -1 : 1) : 0}
                  grosslist: ${regularOrders[i].items[j].listPrice ? regularOrders[i].items[j].listPrice : 0}
                        tax:[{
                          csTaxID: "${regularOrders[i].items[j].tax ? regularOrders[i].items[j].tax : regularOrders[i].items[j].cTaxId}"
                          taxableAmt: ${parseFloat(regularOrders[i]?.items[j]?.linenet?.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision))}
                          taxAmt: ${parseFloat(regularOrders[i].items[j].taxAmount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision))}
                      }]
                    }`);
                }
                const paymentsList = [];
                let amtMax = -100000000000000000000;
                let maxFinPaymentMethod;
                for (let k = 0; k < regularOrders[i]?.payments.length; k += 1) {
                  if (amtMax < parseFloat(regularOrders[i].payments[k].amount)) {
                    amtMax = parseFloat(regularOrders[i].payments[k].amount);
                    maxFinPaymentMethod = regularOrders[i].payments[k].finPaymentmethodId;
                  }
                  if (regularOrders[i].payments[k].name.toLowerCase() === "card") {
                    if (regularOrders[i].payments[k].integratedPayment === true) {
                      paymentsList.push(`{
                      finPaymentmethodID: "${regularOrders[i].payments[k].finPaymentmethodId}",
                      amount: "${parseFloat(parseFloat(regularOrders[i].payments[k].amount).toFixed(2))}"
                      transactionResponse: ${regularOrders[i].cardPaymentData?.response?.length > 0 ? `"${regularOrders[i].cardPaymentData?.response[0]?.resultMessage}"` : ""},
                      transactionId: null,
                      cardType: "",
                      cardNo: "",
                      transactionTime: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                      authorizationCode: null,
                      metaData: [{
                        key: "payload",
                        value: ${regularOrders[i].cardPaymentData?.payload?.length > 0 ? JSON.stringify(JSON.stringify(regularOrders[i].cardPaymentData?.payload[0])) : ""}
                      },
                      {
                        key: "response",
                        value: ${regularOrders[i].cardPaymentData?.response?.length > 0 ? JSON.stringify(JSON.stringify(regularOrders[i].cardPaymentData?.response[0])) : ""}
                      },
                    ]
                    }`);
                    } else {
                      paymentsList.push(`{
                      finPaymentmethodID: "${regularOrders[i].payments[k].finPaymentmethodId}",
                      amount: "${parseFloat(parseFloat(regularOrders[i].payments[k].amount).toFixed(2))}"
                      transactionResponse: null,
                      transactionId: ${regularOrders[i]?.cardPaymentData?.manual?.transactionId ? `"${regularOrders[i]?.cardPaymentData?.manual?.transactionId}"` : null},
                      cardType: ${regularOrders[i].cardPaymentData?.manual?.cardType ? `"${regularOrders[i].cardPaymentData?.manual?.cardType}"` : null},
                      cardNo: ${regularOrders[i].cardPaymentData?.manual?.cardNumber ? `"${regularOrders[i].cardPaymentData?.manual?.cardNumber}"` : null},
                      transactionTime: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                      authorizationCode: ${regularOrders[i]?.cardPaymentData?.manual?.authorization ? `"${regularOrders[i]?.cardPaymentData?.manual?.authorization}"` : null},
                      metaData: []
                    }`);
                    }
                  } else {
                    paymentsList.push(`{
                    finPaymentmethodID: "${regularOrders[i].payments[k].finPaymentmethodId}",
                    amount: "${parseFloat(parseFloat(regularOrders[i].payments[k].amount).toFixed(2))}"
                  }`);
                  }
                }
                let tableData = JSON.parse(localStorage.getItem("tableName"));
                let metaData = [];
                if (localStorage.getItem("dineIn") === "Y") {
                  const keysToCheck = ["guestName", "guestType", "cwrFbTableId", "cwrFbsectionId", "noOfPersons", "referredBy", "orderId"];
                  localStorage.removeItem("tableName");
                  for (const key of keysToCheck) {
                    if (tableData?.hasOwnProperty(key)) {
                      metaData.push(`{
                    key: "${key}",
                    value: "${tableData[key]}"
                  }`);
                    }
                  }
                }
                if (regularOrders[i]?.orderTimeDetails) {
                  Object.keys(regularOrders[i].orderTimeDetails).map((obj) => {
                    metaData.push(`{
                    key: "${obj}",
                    value: "${regularOrders[i].orderTimeDetails[obj]}"
                  }`);
                  });
                }
                let giftCards = [];
                regularOrders[i]?.giftCardData?.map((res) => {
                  giftCards.push(`{
                  redeemRefNo: ${res.redemptionId ? `"${res.redemptionId}"` : null},
                  cardNo:${res.number ? `"${res.number}"` : null},
                  referenceNo: ${res.refId ? `"${res.refId}"` : null}
                }`);
                });

                const paramsInput = {
                  query: `mutation{
            posOrderProcessor(posOrder:{
            sOrderID: "${regularOrders[i].sOrderID}",
            cSClientID: "${tillData.tillAccess.csClientId}",
            cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}",
            created: "${regularOrders[i].orderTime}",
            createdby: "${regularOrders[i].createdBy}",
            updated: "${regularOrders[i].orderTime}",
            updatedby: "${regularOrders[i].createdBy}",
            csDoctypeId: "${tillData.tillAccess.csBunit.cwrCsDoctypeId}",
            sCustomerId: "${tillData.tillAccess.csBunit.b2cCustomer.sCustomer.sCustomerID}",
            sCustomerBillingId: "${tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID}",
            sCustomerShippingId: "${tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID}",
            sPricelistId: "${tillData.tillAccess.csBunit.cwrSpricelistId}",
            documentno: "${regularOrders[i].documentno}",
            dateordered: "${regularOrders[i].orderTime}",
            datepromised: "${regularOrders[i].orderTime}",
            csPaymenttermID: null,
            finPaymentmethodId: ${regularOrders[i].layAway === "N" ? (maxFinPaymentMethod ? `"${maxFinPaymentMethod}"` : null) : null},
            csCurrencyId: "${csCurrencyId}",
            mWarehouseId: "${tillData.tillAccess.csBunit.mWarehouse.mWarehouseID}",
            cwrLongitude: "",
            cwrLatitude: "",
            csUserId: "${tillData.tillAccess.csUserId}",
            cwrB2cCustomerId: "${regularOrders[i].customer.cwrCustomerId}",
            orderreference: "",
            cwrPayref: "",
            cwrPayremarks: "",
            description: "${regularOrders[i].description !== undefined && regularOrders[i].description !== "" ? regularOrders[i].description : ""}",
            storeDailyOpsTillid: "${localStorage.getItem("storeDailyOpsTillid")}",
            cwrTillId: "${tillData.tillAccess.cwrTill.cwrTillID}",
            redemption: ${regularOrders[i].redemptionPoints},
            accumulation: ${regularOrders[i].accumulationPoints},
            redeemRefId: "${regularOrders[i].referenceId}",
            roundoff: ${regularOrders[i].roundOff.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)},
            cwrProductQty: ${regularOrders[i].items.length},
            cwrProductCount: ${regularOrders[i].totalQty},
            ofdStatus: "Delivered",
            ofdIspaid: "Y",
            mPricingruleId: ${regularOrders[i].mPricingruleId !== null && regularOrders[i].mPricingruleId !== undefined ? `"${regularOrders[i].mPricingruleId}"` : null},
            cwrSaletypeId: "${saleTypeId}",
            salesRepId:${regularOrders[i].salesRepId !== null && regularOrders[i].salesRepId !== undefined ? `"${regularOrders[i].salesRepId}"` : null},
            discAmount: ${parseFloat(regularOrders[i].discount.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision))},
            creditAmount: ${regularOrders[i].creditAmount},
            metaData: [${metaData}], 
            giftCard:[${giftCards}],
            pricingCoupon:[{
              mPricingCouponId: ${regularOrders[i]?.couponInput?.length > 0 ? `"${regularOrders[i].couponInput[0].mPricingCouponId}"` : null},
              redemptionCount:${regularOrders[i].couponRedemptionCount ? parseInt(regularOrders[i].couponRedemptionCount) : null},
              referenceId:${regularOrders[i]?.couponInput?.length > 0 ? `"${regularOrders[i].couponInput[0].referenceId}"` : null}
            }]
            orderTotal: ${regularOrders[i].total}
            nettotal: ${parseFloat((regularOrders[i].total - regularOrders[i].tax).toFixed(2))}
            taxamt: ${parseFloat(regularOrders[i].tax.toFixed(2))}
            isReturn: ${regularOrders[i].items.filter((f) => f.isReturn === true).length > 0 ? `"Y"` : `"N"`},
            sOrderReturnId: ${regularOrders[i].items?.[0]?.sOrderReturnId ? `"${regularOrders[i].items?.[0]?.sOrderReturnId}"` : null},
            layAway: ${regularOrders[i].layAway ? `"${regularOrders[i].layAway}"` : null},
            payments: [${paymentsList}],
            tax: ${regularOrders[i].combinedTaxRates ? `[${regularOrders[i].combinedTaxRates}]` : null}
            line: [${orderLines}],
            })
          {
            documentno 
            status
            message
           }
          }
          `,
                };
                Axios({
                  url: serverUrl,
                  method: "POST",
                  data: paramsInput,
                  headers: {
                    "Content-Type": "Application/json",
                    Authorization: `${setAuthTokens}`,
                  },
                })
                  .then(async (response) => {
                    const result = response.data.data.posOrderProcessor;
                    const { status } = result;
                    if (status === "200") {
                      console.info(`Order ${regularOrders[i].documentno} synced to Server`);
                      db.orders.where("sOrderID").equals(regularOrders[i].sOrderID).modify({ isSynced: 1 });
                      let rfidData = [];
                      await db.rfidData.toArray((products) => {
                        products.map((ele) => {
                          if (ele.tag_status === "SO") {
                            rfidData.push(` {
                           tagValue: "${ele.tag_value}"
                           taggingDate: null
                           batchNumber: null
                           batchId: null
                           warehouseId: null
                           tagStatus: "SO"
                           lastScannedDate: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                           scannedBy: null
                           expirydate: null
                           customAttribute: null
                           tagType: null
                           productCode: "${ele.product_code}"
                           }`);
                            //  db.rfidData.update(item[0].tag_value, { tag_status: "SO" });
                          }
                        });
                      });
                      await Axios({
                        url: serverUrl,
                        method: "POST",
                        data: {
                          query: `mutation{
                        RFIDTag(rfidTag:[${rfidData}]){
                        status
                        message
                        }
                        }`,
                        },
                        headers: {
                          "Content-Type": "Application/json",
                          Authorization: `${setAuthTokens}`,
                        },
                      });
                    } else {
                      console.error("Failed Order Sync ====> ", response);
                      const syncFailedCount = parseInt(regularOrders[i].syncAttempts) + 1;
                      if (parseInt(regularOrders[i].syncAttempts) < 100) {
                        db.regularOrders.where("sOrderID").equals(regularOrders[i].sOrderID).modify({ syncAttempts: syncFailedCount });
                      } else {
                        db.regularOrders.where("sOrderID").equals(regularOrders[i].sOrderID).modify({ isSynced: 2 });
                      }
                    }
                  })
                  .catch((error) => {
                    // const err = JSON.parse(JSON.stringify(error));
                    // const { message } = err;
                    // if (message === "Network error: Unexpected token < in JSON at position 0" || message === "Request failed with status code 401") {
                    //   const refToken = tokens.refresh_token;
                    //   const requestBody = {
                    //     grant_type: "refresh_token",
                    //     refresh_token: refToken,
                    //   };
                    //   const config = {
                    //     headers: {
                    //       "Content-Type": "application/x-www-form-urlencoded",
                    //       Authorization: "Basic dGFsazJhbWFyZXN3YXJhbjpteS1zZWNyZXQ=",
                    //     },
                    //   };
                    //   Axios.post(serverTokenUrl, qs.stringify(requestBody), config).then((newToken) => {
                    //     setAuthTokens=newToken.data;
                    //   });
                    // } else {
                    //   const syncFailedCount = parseInt(orders[i].syncAttempts) + 1;
                    //   if (parseInt(orders[i].syncAttempts) < 100) {
                    //     db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ syncAttempts: syncFailedCount });
                    //   } else {
                    //     db.orders.where("sOrderID").equals(orders[i].sOrderID).modify({ isSynced: 2 });
                    //   }
                    // }
                  });
              } else {
                const layAwayOrders = orders.filter((order) => {
                  return order.layAway === "Y" && order.paid !== 0;
                });

                // const regularOrders = orders.filter(order => order.layAway !== "Y");
                //  console.log(layAwayOrders,"=======>lay")
                if (layAwayOrders.length > 0) {
                  // console.log(layAwayOrders,"->layawayirders")
                  const orderLines = [];
                  for (let j = 0; j < layAwayOrders[i].items.length; j += 1) {
                    let unitPrice = layAwayOrders[i].items[j].nettotal / layAwayOrders[i].items[j].weight;
                    unitPrice = parseFloat(unitPrice).toFixed(2);
                    orderLines.push(`{
               sOrderlineID: "${uuidv4().replace(/-/g, "").toUpperCase()}",
               sOrderlineReturnId: ${layAwayOrders[i].items[j].sOrderlineReturnId ? `"${layAwayOrders[i].items[j].sOrderlineReturnId}"` : null}
               created: "${layAwayOrders[i].orderTime}",
               createdby: "${layAwayOrders[i].createdBy}",
               updated: "${layAwayOrders[i].orderTime}",
               updatedby: "${layAwayOrders[i].createdBy}",
               sOrderId: "${layAwayOrders[i].sOrderID}",
               line: ${(j + 1) * 10},
               description: "",
               mProductId: "${layAwayOrders[i].items[j].productId ? layAwayOrders[i].items[j].productId : layAwayOrders[i].items[j].product.mProductId}",
               csUomId: ${
                 layAwayOrders[i].items[j].productId
                   ? `"${layAwayOrders[i].items[j].uom}"`
                   : layAwayOrders[i].items[j].uom && layAwayOrders[i].items[j].uom.csUomId
                   ? `"${layAwayOrders[i].items[j].uom.csUomId}"`
                   : null
               },                     
               csTaxId: ${
                 layAwayOrders[i].items[j].productId
                   ? `"${layAwayOrders[i].items[j].tax}"`
                   : layAwayOrders[i].items[j].tax?.csTaxID
                   ? `"${layAwayOrders[i].items[j].tax.csTaxID}"`
                   : null
               }
               qty: ${layAwayOrders[i].items[j].weight},
               unitprice: ${unitPrice},
               netlist: ${layAwayOrders[i].items[j].salePrice}
               discount: ${layAwayOrders[i].items[j].discount},
               returnline: ${
                 layAwayOrders[i].items[j].isReturn !== undefined && layAwayOrders[i].items[j].isReturn !== null
                   ? layAwayOrders[i].items[j].isReturn
                   : layAwayOrders[i].items[j].returnline
               },
               returnQty: ${layAwayOrders[i].items[j].isReturn === true ? Math.abs(layAwayOrders[i].items[j].weight) : 0},
               mBatchId: ${layAwayOrders[i].items[j].mBatchId ? `"${layAwayOrders[i].items[j].mBatchId}"` : null},
               mPricingruleId: ${
                 layAwayOrders[i].items[j].mPricingruleId !== null && layAwayOrders[i].items[j].mPricingruleId !== undefined
                   ? `"${layAwayOrders[i].items[j].mPricingruleId}"`
                   : null
               },
               batchedForSale:${layAwayOrders[i].items[j].batchedForSale ? `"${layAwayOrders[i].items[j].batchedForSale}"` : null},
               batchedForStock:${layAwayOrders[i].items[j].batchedForStock ? `"${layAwayOrders[i].items[j].batchedForStock}"` : null},
               batchedProduct:${layAwayOrders[i].items[j].batchedProduct ? `"${layAwayOrders[i].items[j].batchedProduct}"` : null},
               salesRepId: ${
                 layAwayOrders[i].items[j].salesRepId !== null && layAwayOrders[i].items[j].salesRepId !== undefined ? `"${layAwayOrders[i].items[j].salesRepId}"` : null
               },
               multiPrice: ${layAwayOrders[i].items[j].multiPrice ? `"${layAwayOrders[i].items[j].multiPrice}"` : null},
               discountTypeId: null,
               discountAmount: null,
                 }`);
                  }
                  const paymentsList = [];
                  let amtMax = -100000000000000000000;
                  let maxFinPaymentMethod;
                  for (let k = 0; k < layAwayOrders[i].payments.length; k += 1) {
                    if (amtMax < parseFloat(layAwayOrders[i].payments[k].amount)) {
                      amtMax = parseFloat(layAwayOrders[i].payments[k].amount);
                      maxFinPaymentMethod = layAwayOrders[i].payments[k].finPaymentmethodId;
                    }
                    paymentsList.push(`{
                   finPaymentmethodID: "${layAwayOrders[i].payments[k].finPaymentmethodId}",
                   amount: "${layAwayOrders[i].payments[k].amount}"
                 }`);
                  }
                  let tableData = JSON.parse(localStorage.getItem("tableName"));
                  let metaData = [];
                  if (localStorage.getItem("dineIn") === "Y") {
                    const keysToCheck = ["guestName", "guestType", "cwrFbTableId", "cwrFbsectionId", "noOfPersons", "referredBy", "orderId"];
                    localStorage.removeItem("tableName");
                    for (const key of keysToCheck) {
                      if (tableData.hasOwnProperty(key)) {
                        metaData.push(`{
                       key: "${key}",
                       value: "${tableData[key]}"
                     }`);
                      }
                    }
                  }
                  const paramsInput = {
                    query: `mutation{
                  confirmPOSLayawayOrder(posOrder:{
               sOrderID: "${layAwayOrders[i].sOrderID}",
               cSClientID: "${tillData.tillAccess.csClientId}",
               cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}",
               created: "${layAwayOrders[i].orderTime}",
               createdby: "${layAwayOrders[i].createdBy}",
               updated: "${layAwayOrders[i].orderTime}",
               updatedby: "${layAwayOrders[i].createdBy}",
               csDoctypeId: "${tillData.tillAccess.csBunit.cwrCsDoctypeId}",
               sCustomerId: "${tillData.tillAccess.csBunit.b2cCustomer.sCustomer.sCustomerID}",
               sCustomerBillingId: "${tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID}",
               sCustomerShippingId: "${tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID}",
               sPricelistId: "${tillData.tillAccess.csBunit.cwrSpricelistId}",
               documentno: "${layAwayOrders[i].documentno}",
               dateordered: "${layAwayOrders[i].orderTime}",
               datepromised: "${layAwayOrders[i].orderTime}",
               csPaymenttermID: null,
               finPaymentmethodId: ${layAwayOrders[i].layAway === "N" ? `"${maxFinPaymentMethod}"` : null},
               csCurrencyId: "${csCurrencyId}",
               mWarehouseId: "${tillData.tillAccess.csBunit.mWarehouse.mWarehouseID}",
               cwrLongitude: "",
               cwrLatitude: "",
               csUserId: "${tillData.tillAccess.csUserId}",
               cwrB2cCustomerId: "${layAwayOrders[i].customer.cwrCustomerId}",
               orderreference: "",
               cwrPayref: "",
               cwrPayremarks: "",
               description: "",
               cwrTillId: "${tillData.tillAccess.cwrTill.cwrTillID}",
               redemption: ${layAwayOrders[i].redemptionPoints ? layAwayOrders[i].redemptionPoints : null},
               accumulation: ${layAwayOrders[i].accumulationPoints ? layAwayOrders[i].accumulationPoints : null},
               roundoff: ${Math.abs(layAwayOrders[i].roundOff.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision))},
               cwrProductQty: ${layAwayOrders[i].items.length},
               cwrProductCount: ${layAwayOrders[i].totalQty},
               ofdStatus: "Delivered",
               ofdIspaid: "Y",
               mPricingruleId: ${layAwayOrders[i].mPricingruleId !== null && layAwayOrders[i].mPricingruleId !== undefined ? `"${layAwayOrders[i].mPricingruleId}"` : null},
               cwrSaletypeId: "${saleTypeId}",
               salesRepId:${layAwayOrders[i].salesRepId !== null && layAwayOrders[i].salesRepId !== undefined ? `"${layAwayOrders[i].salesRepId}"` : null},
               discAmount: ${layAwayOrders[i].discount},
               creditAmount: ${layAwayOrders[i].creditAmount},
               metaData: [${metaData}], 
               pricingCoupon:{
                 mPricingCouponId:${layAwayOrders[i].mPricingCouponId ? `"${layAwayOrders[i].mPricingCouponId}"` : null},
                 redemptionCount:${layAwayOrders[i].couponRedemptionCount ? parseInt(layAwayOrders[i].couponRedemptionCount) : null},
                 referenceId:${layAwayOrders[i]?.couponInput?.length > 0 ? `"${layAwayOrders[i].couponInput[0].referenceId}"` : null}
               }
               orderTotal: ${layAwayOrders[i].total}
               isReturn: ${layAwayOrders[i].items.filter((f) => f.isReturn === true).length > 0 ? `"Y"` : `"N"`},
               sOrderReturnId: ${layAwayOrders[i].items?.[0]?.sOrderReturnId ? `"${layAwayOrders[i].items?.[0]?.sOrderReturnId}"` : null},
               layAway: "${layAwayOrders[i].layAway}",
               payments: [${paymentsList}],
               line: [${orderLines}],
               })
             {
               documentno 
               status
               message
              }
             }
             `,
                  };
                  // console.log(paramsInput,"-->confirm")
                  Axios({
                    url: serverUrl,
                    method: "POST",
                    data: paramsInput,
                    headers: {
                      "Content-Type": "Application/json",
                      Authorization: `${setAuthTokens}`,
                    },
                  })
                    .then(async (response) => {
                      if (response.statusText === "200") {
                        console.log(response, "----------->response");
                        console.info(`Order ${layAwayOrders[i].documentno} synced to Server`);
                        db.orders.where("sOrderID").equals(layAwayOrders[i].sOrderID).modify({ isSynced: 1 });
                        let rfidData = [];
                        await db.rfidData.toArray((products) => {
                          products.map((ele) => {
                            if (ele.tag_status === "SO") {
                              rfidData.push(` {
                              tagValue: "${ele.tag_value}"
                              taggingDate: null
                              batchNumber: null
                              batchId: null
                              warehouseId: null
                              tagStatus: "SO"
                              lastScannedDate: "${moment(new Date()).format("YYYY-MM-DD HH:mm:ss")}"
                              scannedBy: null
                              expirydate: null
                              customAttribute: null
                              tagType: null
                              productCode: "${ele.product_code}"
                              }`);
                              //  db.rfidData.update(item[0].tag_value, { tag_status: "SO" });
                            }
                          });
                        });
                        await Axios({
                          url: serverUrl,
                          method: "POST",
                          data: {
                            query: `mutation{
                           RFIDTag(rfidTag:[${rfidData}]){
                           status
                           message
                           }
                           }`,
                          },
                          headers: {
                            "Content-Type": "Application/json",
                            Authorization: `${setAuthTokens}`,
                          },
                        });
                      } else {
                        console.error("Failed Order Sync ====> ", response);
                        const syncFailedCount = parseInt(layAwayOrders[i].syncAttempts) + 1;
                        if (parseInt(layAwayOrders[i].syncAttempts) < 100) {
                          db.layAwayOrders.where("sOrderID").equals(layAwayOrders[i].sOrderID).modify({ syncAttempts: syncFailedCount });
                        } else {
                          db.layAwayOrders.where("sOrderID").equals(layAwayOrders[i].sOrderID).modify({ isSynced: 2 });
                        }
                      }
                    })
                    .catch((error) => {});
                }
              }
            }
          }
        });
    }
  };

  const handleButtonClick = () => {
    setPopoverVisible(!isPopoverVisible);
  };

  const getDisplayDate = () => {
    if (dateRange && dateRange.length === 2) {
      const [start, end] = dateRange;
      return `${start.format("DD-MM-YYYY")} to ${end.format("DD-MM-YYYY")}`;
    }
    return "Choose date range";
  };

  const handleBackClick = () => {
    setIsViewOrder(false);
  };

  const handleRecordClick1 = (item) => {
    setSelectedRecord(item);
  };
  const handleExpand = (record) => {
    console.log("Record:", record);
    const nextExpandedKeys = [...expandedRowKeys];
    const index = nextExpandedKeys.indexOf(record.key);
    if (index > -1) {
      nextExpandedKeys.splice(index, 1);
    } else {
      nextExpandedKeys.push(record.key);
    }
    setExpandedRowKeys(nextExpandedKeys);
  };

  const handleRecordClick = (item) => {
    setSelectedRecord(item);
    setIsViewOrder(true);
  };

  // const handleLinkClick = (item) => {
  //   console.log("Item Clicked:", item);
  //   setSelectedItem(item);

  //   console.log("Selected Item State:", item);
  //   console.log("Selected Item DataKey:", item?.dataKey);

  //   let fetchData;
  //   switch (item.dataKey) {
  //     case "products":
  //       fetchData = db.products.toArray();
  //       break;
  //     case "categories":
  //       fetchData = db.productCategories.toArray();
  //       break;
  //     case "brands":
  //       fetchData = db.productBrands.toArray();
  //       break;
  //     case "pricingRules":
  //       fetchData = db.pricingRules.toArray();
  //       break;
  //     case "posSaletypes":
  //       fetchData = db.posSaletypes.toArray();
  //       break;
  //     case "giftCardData":
  //       fetchData = db.giftCardData.toArray();
  //       break;
  //     case "UOM":
  //       fetchData = db.productUom.toArray();
  //       break;
  //     case "approvers":
  //       fetchData = db.approvers.toArray();
  //       break;
  //     case "loyalityData":
  //       fetchData = db.loyalityData.toArray();
  //       break;
  //     default:
  //       console.error("Invalid dataKey:", item.dataKey);
  //       return;
  //   }
  //   fetchData
  //     .then((dataFetched) => {
  //       console.log("Fetched Data:", dataFetched);
  //       switch (item.dataKey) {
  //         case "products":
  //           setProductsData([...dataFetched]);
  //           break;
  //         case "categories":
  //           setCategoriesData([...dataFetched]);
  //           break;
  //         case "brands":
  //           setBrandsData([...dataFetched]);
  //           break;
  //         case "pricingRules":
  //           setPricingRulesData([...dataFetched]);
  //           break;
  //         case "posSaletypes":
  //           setPosSaleTypesData([...dataFetched]);
  //           break;
  //         case "giftCardData":
  //           setGiftCardData([...dataFetched]);
  //           break;
  //         case "UOM":
  //           setUOMData([...dataFetched]);
  //           break;
  //         case "approvers":
  //           setApproversData([...dataFetched]);
  //           break;
  //         case "loyalityData":
  //           setLoyaltyData([...dataFetched]);
  //           break;
  //         default:
  //           console.error("Invalid dataKey:", item.dataKey);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // };

  const handleLinkClick = async (item) => {
    setLoading(true);

    let fetchData;
    let syncData = {};

    try {
      const syncSummary = await db.dataSyncSummary.toArray();

      syncSummary.forEach((syncItem) => {
        syncData[syncItem.syncId] = syncItem.syncEndTime;
      });

      switch (item.dataKey) {
        case "products":
          fetchData = db.products.toArray();
          break;
        case "categories":
          fetchData = db.productCategories.toArray();
          break;
        case "brands":
          fetchData = db.productBrands.toArray();
          break;
        case "pricingRules":
          fetchData = db.pricingRules.toArray();
          break;
        case "posSaletypes":
          fetchData = db.posSaletypes.toArray();
          break;
        case "giftCardData":
          fetchData = db.giftCardData.toArray();
          break;
        case "UOM":
          fetchData = db.productUom.toArray();
          break;
        case "approvers":
          fetchData = db.approvers.toArray();
          break;
        case "loyalityData":
          fetchData = db.loyalityData.toArray();
          break;
        default:
          console.error("Invalid dataKey:", item.dataKey);
          return;
      }

      const dataFetched = await fetchData;

      const updatedData = dataFetched.map((record) => ({
        ...record,
        syncEndTime: syncData[record.syncId] || " ",
      }));

      switch (item.dataKey) {
        case "products":
          setProductsData([...updatedData]);
          break;
        case "categories":
          setCategoriesData([...updatedData]);
          break;
        case "brands":
          setBrandsData([...updatedData]);
          break;
        case "pricingRules":
          setPricingRulesData([...updatedData]);
          break;
        case "posSaletypes":
          setPosSaleTypesData([...updatedData]);
          break;
        case "giftCardData":
          setGiftCardData([...updatedData]);
          break;
        case "UOM":
          setUOMData([...updatedData]);
          break;
        case "approvers":
          setApproversData([...updatedData]);
          break;
        case "loyalityData":
          setLoyaltyData([...updatedData]);
          break;
        default:
          console.error("Invalid dataKey:", item.dataKey);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setSelectedItem(item);
      setSearchQuery("");
      setLoading(false);
    }
    // setItemsToShow(newItemsToShow);
  };

  const dataKeyToNameMap = {
    products: "Products",
    categories: "Categories",
    brands: "Brands",
    pricingRules: "Pricing Rules",
    posSaletypes: "POS Sale Types",
    giftCardData: "Gift Card Data",
    UOM: "Units of Measure",
    approvers: "Approvers",
    loyalityData: "Loyalty Data",
  };

  useEffect(() => {
    console.log("Selected Item Changed:", selectedItem);
  }, [selectedItem]);

  const handleSyncItemClick = (syncItem) => {
    setSelectedItem(syncItem);
  };

  const filteredData = selectedItem ? dataSyncSummery[selectedItem.dataKey] : [];

  // const handleBackClick = () => {
  //   setIsViewOrder(false);
  //   setSelectedRecord(null);
  // };

  // const content = (
  //   <RangePicker
  //     open={isPopoverVisible}
  //     allowEmpty
  //     id="sm-salesHistory-date"
  //     // onPressEnter={searchOrderHistory}
  //     allowClear={true}
  //     format={"DD-MM-YYYY"}
  //     suffixIcon={null}
  //     separator={null}
  //     value={dateRange}
  //     className="salesHistory-datePicker-sm"
  //   />
  // );

  const syncItems = [
    { title: { label: "Products" }, dataKey: "products" },
    { title: { label: "Categories" }, dataKey: "categories" },
    { title: { label: "Brands" }, dataKey: "brands" },
    { title: { label: "Pricing Rules" }, dataKey: "pricingRules" },
    { title: { label: "POS Sale Types" }, dataKey: "posSaletypes" },
    { title: { label: "Gift Card" }, dataKey: "giftCardData" },
    { title: { label: "UOM" }, dataKey: "UOM" },
    { title: { label: "Approvers" }, dataKey: "approvers" },
    { title: { label: "Loyalty" }, dataKey: "loyalityData" },
  ];

  const columns = [
    {
      title: "Order Number",
      dataIndex: "documentno",
      width: "25%",
    },
    {
      title: "Customer",
      dataIndex: "customerSearchKey",
      width: "25%",
    },
    {
      title: "Order Time",
      dataIndex: "orderTime",
      width: "25%",
    },
    {
      title: "Sync Status",
      dataIndex: "isSynced",
      width: "25%",
      render: (isSynced) => <span>{isSynced === 1 ? "Synced" : "Pending"}</span>,
    },
  ];

  // const columns1 = [
  //   {
  //     title: "Code",
  //     dataIndex: "value",
  //     width: "10%",
  //   },
  //   {
  //     title: "Name",
  //     dataIndex: "name",
  //     width: "40%",
  //   },
  //   {
  //     title: "Sync ID",
  //     dataIndex: "syncId",
  //     // dataIndex: "orderTime",
  //     width: "35%",
  //   },
  //   {
  //     title: "Sync Status",
  //     // dataIndex: "syncId",
  //     width: "15%",
  //     // render: (isSynced) => <span>{isSynced === 1 ? "Synced" : "Pending"}</span>,
  //     render: () => <span>Synced</span>,
  //   },
  // ];

  const columnsConfig = {
    default: [
      {
        title: "Name",
        dataIndex: "name",
        width: "40%",
      },
      {
        title: "Sync Time",
        dataIndex: "syncEndTime",
        width: "40%",
      },
      {
        title: "Sync Status",
        width: "20%",
        render: () => <span>Synced</span>,
      },
    ],
    products: [
      {
        title: "Code",
        dataIndex: "value",
        width: "10%",
      },
      {
        title: "Name",
        dataIndex: "name",
        width: "40%",
      },
      {
        title: "Sync Time",
        dataIndex: "syncEndTime",
        width: "35%",
      },
      {
        title: "Sync Status",
        width: "15%",
        render: () => <span>Synced</span>,
      },
    ],
    categories: [
      {
        title: "Code",
        dataIndex: "value",
        width: "10%",
      },
      {
        title: "Name",
        dataIndex: "name",
        width: "40%",
      },
      {
        title: "Sync Time",
        dataIndex: "syncEndTime",
        width: "35%",
      },
      {
        title: "Sync Status",
        width: "15%",
        render: () => <span>Synced</span>,
      },
    ],
    posSaletypes: [
      {
        title: "Name",
        dataIndex: "cwrSaletype", // This will be the parent object
        width: "40%",
        render: (cwrSaletype) => cwrSaletype?.name || "N/A", // Accessing the nested name property
      },
      {
        title: "Sync Time",
        dataIndex: "syncEndTime",
        width: "40%",
      },
      {
        title: "Sync Status",
        width: "20%",
        render: () => <span>Synced</span>,
      },
    ],
    // Add more configurations if needed
  };

  const SyncItem = ({ title, dataKey, data = [], handleLinkClick }) => (
    <>
      <div style={{ fontSize: "1vw" }}>
        <Typography.Text
          style={{
            textAlign: "left",
            fontWeight: 500,
            fontSize: "1em",
            color: "rgba(15, 7, 24, 1)",
            position: "absolute",
            left: "1.5vw",
          }}
        >
          {title.label}
        </Typography.Text>
        {data.length > 0
          ? data.map((item, index) => (
              <Col key={index} style={{ marginTop: "5vh", fontSize: "1vw" }}>
                <div
                  style={{
                    width: "25vw",
                    height: "0.6vh",
                    backgroundColor: "rgba(217, 217, 217, 1)",
                    borderRadius: "5px",
                    top: "5vh",
                  }}
                >
                  <div
                    style={{
                      width: "25vw",
                      height: "100%",
                      backgroundColor: item.syncType === "Full Sync" ? "rgba(0, 128, 0, 1)" : "rgba(255, 0, 0, 1)",
                      borderRadius: "5px",
                    }}
                  />
                </div>

                <div
                  style={{
                    width: "2vw",
                    height: "2.2vh",
                    left: "26vw",
                    position: "absolute",
                    top: "-2vh",
                  }}
                >
                  <img src={item.syncType === "Full Sync" ? TickCircle : PieChart} style={{ width: "1.4vw", height: "3vh", left: "0.3vw" }} />
                </div>

                <Typography.Text
                  style={{
                    fontFamily: "Inter",
                    fontSize: "0.9em",
                    fontWeight: 400,
                    color: "rgba(15, 7, 24, 1)",
                    position: "absolute",
                    paddingTop: "1vh",
                  }}
                >
                  {item.type?.[dataKey]?.incremntalSyncEnd ? item.type?.[dataKey]?.incremntalSyncEnd : item.type?.[dataKey]?.syncEndTime}
                </Typography.Text>

                <Typography.Text
                  style={{
                    fontFamily: "Inter",
                    fontSize: "0.9em",
                    fontWeight: 400,
                    color: "rgba(15, 7, 24, 1)",
                    position: "absolute",
                    marginLeft: "15vw",
                    paddingTop: "1vh",
                  }}
                >
                  <a style={{ color: "#0F0718", textDecoration: "underline" }} onClick={() => handleLinkClick({ ...item, dataKey })}>
                    Records: {item.type?.[dataKey]?.incremntalSyncRecods ? item.type?.[dataKey]?.incremntalSyncRecods : item.type?.[dataKey]?.records}
                  </a>
                </Typography.Text>

                <Typography.Text
                  style={{
                    fontFamily: "Inter",
                    fontSize: "0.9em",
                    fontWeight: 400,
                    color: "rgba(15, 7, 24, 1)",
                    position: "absolute",
                    marginLeft: "25vw",
                    paddingTop: "1vh",
                  }}
                >
                  {item.syncType === "Full Sync" ? "Synced" : "Pending"}
                </Typography.Text>
              </Col>
            ))
          : null}
      </div>
    </>
  );

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 40, marginTop: "18vh", color: "#2F3856" }} spin />} spinning={loading}>
      {isViewOrder ? (
        <div style={{ background: colorVariable ? colorVariable : "#f3f4f9", height: "100vh" }}>
          <Row style={{ padding: "1.5em 1.5em 0 1.5em", fontSize: "1vw" }}>
            <Col span={6}>
              <img src={Back} onClick={handleBackClick} id="sm-parked-bill-back" style={{ height: "3vw", paddingBottom: "0.7em", cursor: "pointer" }} />
              <span style={{ paddingRight: "1vw", fontSize: "1.8em", fontWeight: 500, margin: "0.5em", paddingTop: "0.5em", color: "#0F0718" }}>View Order</span>
            </Col>
          </Row>

          <Row style={{ padding: "1.5em 1.5em 0 1.5em", fontSize: "1vw" }}>
            <Table
              // ref={tableBodyRef}
              style={{
                width: "100%",
                overflowY: "auto",
                border: "none",
                fontSize: "1vw",
                borderRadius: "10px",
                padding: "0 1em",
                height: "83vh",
                background: "#fff",
              }}
              onRow={(record) => ({
                onClick: () => handleExpand(record),
              })}
              pagination={false}
              columns={columns}
              dataSource={[selectedOrder]}
              className="sm-view-order-table"
              rowClassName={(record) => (selectedKeys[0] === record.key ? "tableSelectedBlue" : "tableNonSelectedWhite")}
              // onExpand={handleExpand}
              expandable={{
                expandIcon: () => null,
                expandedRowKeys,
                // onExpand: handleExpand,
                expandedRowRender: (record) => (
                  <div
                    style={{
                      padding: "1% 4% 1% 4%",
                      backgroundColor: "#fff",
                    }}
                  >
                    <Row>
                      <Col span={24}>
                        <Row>
                          <Col span={11} className="td-nested-title-sm">
                            Description
                          </Col>
                          <Col span={3} className="td-nested-title-sm" style={{ textAlign: "center" }}>
                            Qty
                          </Col>
                          <Col span={3} offset={1} className="td-nested-title-sm" style={{ textAlign: "right" }}>
                            Unit Price
                          </Col>
                          <Col span={4} offset={1} className="td-nested-title-sm" style={{ textAlign: "right", paddingRight: "15px" }}>
                            Net Total
                          </Col>
                        </Row>
                        <Row style={{ paddingBottom: "0.5vw" }}>
                          {record.items.map((item) => (
                            <React.Fragment key={item.key}>
                              <Col span={11} className="td-nested-description">
                                {item.name}
                              </Col>
                              <Col span={4} style={{ textAlign: "center", fontWeight: "400", paddingRight: "45px" }}>
                                {item.weight}
                              </Col>
                              <Col span={1} style={{ textAlign: "center", fontWeight: "400" }} />
                              <Col span={3} style={{ textAlign: "right", fontWeight: "400" }}>
                                {item.salePrice.toFixed(2)}
                              </Col>
                              <Col span={2} style={{ textAlign: "center" }} />
                              <Col span={3} style={{ textAlign: "right", fontWeight: "400", paddingRight: "15px" }}>
                                {item.nettotal.toFixed(2)}
                              </Col>
                            </React.Fragment>
                          ))}
                        </Row>
                        <p style={{ width: "96%", marginLeft: "0%", borderTop: "1.3px solid rgba(52, 40, 44, 0.55)", marginBottom: "0px" }} />
                        <Row style={{ paddingTop: "5px" }}>
                          <Col span={3} offset={17} className="td-subtotal">
                            <span className="td-subTotalText">Subtotal</span>
                            <span className="td-subTotalText">Total tax</span>
                          </Col>
                          <Col span={3} className="td-subtotal" style={{ textAlign: "right" }}>
                            <span className="td-subTotalText" style={{ paddingRight: "15px" }}>
                              {(record.total - record.tax).toFixed(2)}
                            </span>
                            <span className="td-subTotalText" style={{ paddingRight: "15px" }}>
                              {record.tax.toFixed(2)}
                            </span>
                          </Col>
                        </Row>
                        <Row style={{ marginBottom: "1%" }}>
                          <Col span={3} offset={17} className="td-subtotal" style={{ padding: "4px 0" }}>
                            <span className="td-total">SALE TOTAL</span>
                          </Col>
                          <Col span={3} style={{ fontSize: "1em", borderBottom: "1px solid rgba(52, 40, 44, 0.55)", textAlign: "right", paddingTop: "4px" }}>
                            <span className="td-total" style={{ paddingRight: "12px" }}>
                              {parseFloat(record.total).toFixed(2)}
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                ),
              }}
            />
          </Row>
        </div>
      ) : // selectedItem ? (
      //   <div style={{ background: colorVariable ? colorVariable : "#f3f4f9", height: "100vh" }}>
      //     <Row style={{ padding: "1.5em 1.5em 0 1.5em", fontSize: "1vw" }}>
      //       <Col span={8}>
      //         <img src={Back} onClick={() => setSelectedItem(false)} id="sm-parked-bill-back" style={{ height: "3vw", paddingBottom: "0.5em", cursor: "pointer" }} />

      //         <span style={{ marginRight: "20px", fontSize: "1.8em", fontWeight: 500, paddingTop: "0.5em", color: "#0F0718", marginLeft: "10px" }}>
      //           {selectedItem ? (
      //             <>
      //               {/* {selectedItem.dataKey} */}
      //               {dataKeyToNameMap[selectedItem.dataKey]} Records (
      //               {(() => {
      //                 switch (selectedItem.dataKey) {
      //                   case "products":
      //                     return productsData.length;
      //                   case "categories":
      //                     return categoriesData.length;
      //                   case "brands":
      //                     return brandsData.length;
      //                   case "pricingRules":
      //                     return pricingRulesData.length;
      //                   case "posSaletypes":
      //                     return posSaleTypesData.length;
      //                   case "giftCardData":
      //                     return giftCardData.length;
      //                   case "UOM":
      //                     return uomData.length;
      //                   case "approvers":
      //                     return approversData.length;
      //                   case "loyalityData":
      //                     return loyaltyData.length;
      //                   default:
      //                     return "0";
      //                 }
      //               })()}
      //               )
      //             </>
      //           ) : (
      //             "0"
      //           )}
      //         </span>
      //       </Col>
      //       <Col span={5} />
      //       <Col span={11} style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
      //         <Input
      //           placeholder={`Search for customers`}
      //           prefix={<img src={CustomerSearch} style={{ width: "1.5em" }} alt="" />}
      //           // size="middle"
      //           allowClear={false}
      //           style={{ width: "70%", borderRadius: "5px", border: "none" }}
      //           id="sm-parked-bill-customer"
      //           className="parkedCustomer-sm"
      //           width={700}
      //         />
      //       </Col>
      //     </Row>
      //     <Row style={{ padding: "1.5em 1.5em 0 1.5em", fontSize: "1vw" }}>
      //       <Table
      //         style={{
      //           width: "100%",
      //           overflowY: "auto",
      //           border: "none",
      //           fontSize: "1vw",
      //           borderRadius: "10px",
      //           padding: "0 1em",
      //           height: "83vh",
      //           background: "#fff",
      //         }}
      //         pagination={false}
      //         columns={columns1}
      //         dataSource={(() => {
      //           if (!selectedItem) return [];

      //           console.log("Selected Item DataKey:", selectedItem.dataKey);

      //           switch (selectedItem.dataKey) {
      //             case "products":
      //               return productsData;
      //             case "categories":
      //               return categoriesData;
      //             case "brands":
      //               return brandsData;
      //             case "pricingRules":
      //               return pricingRulesData;
      //             case "posSaletypes":
      //               return posSaleTypesData;
      //             case "giftCardData":
      //               return giftCardData;
      //             case "UOM":
      //               return uomData;
      //             case "approvers":
      //               return approversData;
      //             case "loyalityData":
      //               return loyaltyData;
      //             default:
      //               return [];
      //           }
      //         })()}
      //         className="sm-dashboard-table"
      //       />
      //     </Row>
      //   </div>
      // )
      selectedItem ? (
        <div style={{ background: colorVariable ? colorVariable : "#f3f4f9", height: "100vh" }}>
          <Row style={{ padding: "1.5em 1.5em 0 1.5em", fontSize: "1vw" }}>
            <Col span={8}>
              <img
                src={Back}
                onClick={() => {
                  setSelectedItem(false);
                  setItemsToShow(50);
                }}
                id="sm-parked-bill-back"
                style={{ height: "3vw", paddingBottom: "0.5em", cursor: "pointer" }}
              />

              <span style={{ marginRight: "20px", fontSize: "1.8em", fontWeight: 500, paddingTop: "0.5em", color: "#0F0718", marginLeft: "10px" }}>
                {selectedItem ? (
                  <>
                    {dataKeyToNameMap[selectedItem.dataKey]} Records (
                    {(() => {
                      switch (selectedItem.dataKey) {
                        case "products":
                          return productsData.length;
                        case "categories":
                          return categoriesData.length;
                        case "brands":
                          return brandsData.length;
                        case "pricingRules":
                          return pricingRulesData.length;
                        case "posSaletypes":
                          return posSaleTypesData.length;
                        case "giftCardData":
                          return giftCardData.length;
                        case "UOM":
                          return uomData.length;
                        case "approvers":
                          return approversData.length;
                        case "loyalityData":
                          return loyaltyData.length;
                        default:
                          return "0";
                      }
                    })()}
                    )
                  </>
                ) : (
                  "0"
                )}
              </span>
            </Col>
            <Col span={5} />
            <Col span={11} style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
              <Input
                placeholder={`Search here by name/code`}
                prefix={<img src={CustomerSearch} style={{ width: "1.5em" }} alt="" />}
                // size="middle"
                allowClear={false}
                style={{ width: "70%", borderRadius: "5px", border: "none" }}
                id="sm-parked-bill-customer"
                className="parkedCustomer-sm"
                width={700}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Col>
          </Row>
          <Row style={{ padding: "1.5em 1.5em 0 1.5em", fontSize: "1vw" }}>
            {/* <Table
              style={{
                width: "100%",
                overflowY: "auto",
                border: "none",
                fontSize: "1vw",
                borderRadius: "10px",
                padding: "0 1em",
                height: "83vh",
                background: "#fff",
              }}
              pagination={false}
              columns={columns1}
              dataSource={(() => {
                if (!selectedItem) return [];

                console.log("Selected Item DataKey:", selectedItem.dataKey);

                let data = [];

                switch (selectedItem.dataKey) {
                  case "products":
                    return productsData;
                  case "categories":
                    return categoriesData;
                  case "brands":
                    return brandsData;
                  case "pricingRules":
                    return pricingRulesData;
                  case "posSaletypes":
                    return posSaleTypesData;
                  case "giftCardData":
                    return giftCardData;
                  case "UOM":
                    return uomData;
                  case "approvers":
                    return approversData;
                  case "loyalityData":
                    return loyaltyData;
                  default:
                    return [];
                }
                if (searchQuery) {
                  return data.filter(item => {
                    const itemString = Object.values(item).join(" ").toLowerCase();
                    return itemString.includes(searchQuery.toLowerCase());
                  });
                }
            
                return data;
              })()}
              className="sm-dashboard-table"
            /> */}

            <Table
              style={{
                width: "100%",
                overflowY: "auto",
                border: "none",
                fontSize: "1vw",
                borderRadius: "10px",
                padding: "0 1em",
                height: "83vh",
                background: "#fff",
              }}
              ref={tableRef}
              pagination={false}
              columns={columnsConfig[selectedItem?.dataKey] || columnsConfig.default}
              dataSource={displayedData}
              className="sm-dashboard-table"
              // loading={loading}
            />
          </Row>
        </div>
      ) : (
        <>
          <div style={{ backgroundColor: "rgba(243, 244, 249, 1)", width: "100vw", minHeight: "100vh", overflow: "hidden", fontSize: "1vw" }}>
            <Row gutter={24} style={{ height: "8.5vh", paddingLeft: "2.5vw", paddingRight: "2.5vw", paddingTop: "1vw", display: "flex", alignItems: "center" }}>
              {/* <Col span={1} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <img src={menuIcon} style={{ width: "2vw", height: "2.2vh", paddingRight: 0, cursor: "pointer" }} />
          </Col> */}

              <Col span={2} style={{ position: "relative", paddingLeft: "10px" }}>
                <Link to="/pos">
                  {/* <img src={Arrow} style={{width:"2.2vw",height: "3.5vh",cursor:"pointer"}} /> */}
                  <img src={Back} style={{ height: "4.5vh", cursor: "pointer", paddingTop: "0.5vh" }} />
                </Link>
              </Col>

              <Col span={5} style={{ right: "5em", paddingRight: "1vw", paddingTop: "0.2em", paddingLeft: "7px" }}>
                <Typography.Text style={{ fontWeight: 500, fontSize: "1.5em", color: "rgba(15, 7, 24, 1)" }}>Data Sync Dashboard</Typography.Text>
              </Col>

              <Col span={7} style={{ right: "9em", paddingRight: 0, paddingTop: "0.5em" }}>
                <Typography.Text style={{ fontSize: "1em", color: "rgba(15, 7, 24, 1)" }}>Last Sync: {dataSyncSummery[0]?.syncEndTime}</Typography.Text>
              </Col>

              <Col span={4} style={{ display: "flex", alignItems: "center", paddingLeft: "3.5vw", paddingRight: 0 }}>
                {/* <div style={{position: 'relative', display: 'flex', alignItems: 'center' }}>
            <input type="text" placeholder="Search" style={{ width: "16.6vw",height: "4.3vh",border: "none",borderRadius: "0.3em",outline: "none",fontSize: "1.7vh",verticalAlign: "middle",paddingLeft: "2.5em",}}/>
            <img src={searchIcon} alt="Search" style={{ width: "1.5vw",height: "2.1vh",position: "absolute",left: "0.4em",right:"0.8em",top: "50%",transform: "translateY(-50%)",cursor: "pointer",}}/>
            </div> */}
              </Col>

              <Col span={3} style={{ paddingRight: 0 }}>
                {/* <div style={{ width: "9vw", height: "4.3vh", borderRadius: "0.3125em", background: "rgba(255, 255, 255, 1)" }}>
            <div style={{ position: "relative", width: "100%", height: "100%", left: "0.7vw" }}>
            <img src={filterIcon} alt="Filter" style={{ width: "1.2vw", height: "2.2vh", objectFit: "contain", position: "absolute", top: "50%", transform: "translateY(-50%)" }} />
            <select style={{ paddingTop: "0.3vw", paddingLeft: "2vw", border: "none", outline: "none", borderRadius: "0.3125em", background: "transparent", fontSize: "1vw", appearance: "none", width: "7vw" }}>
            <option value="" selected hidden>Filter</option>
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
            </select>
            <img src={dropdownIcon} alt="Dropdown" style={{ width: "1.5vw", height: "2.5vh", objectFit: "contain", position: "absolute", top: "50%", right: "0.8vw", transform: "translateY(-50%)" }} />
            </div>
            </div> */}
              </Col>

              <Col span={2} style={{ paddingLeft: "4vw", paddingRight: 0 }}>
                <button
                  style={{
                    width: "8vw",
                    height: "5vh",
                    borderRadius: "0.3em",
                    backgroundColor: "rgba(47, 56, 86, 1)",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "1em",
                    border: "none",
                    paddingLeft: "12px",
                  }}
                  onClick={() => setPageRefreshFlag(!pageRefreshFlag)}
                >
                  <img src={refreshIcon} alt="Refresh" style={{ width: "1em", height: "1em", objectFit: "contain", marginRight: "10px", marginLeft: "2px" }} />
                  Refresh
                </button>
              </Col>
            </Row>

            <Row gutter={24} style={{ paddingLeft: "2.3vw", paddingRight: "2.3vw", paddingTop: "1vw", fontSize: "1vw" }}>
              <Col span={8} style={{ paddingLeft: 0 }}>
                <Card style={{ borderRadius: "7px", height: "87vh", marginRight: "-0.4em" }}>
                  <div
                    style={{
                      backgroundColor: "rgba(146, 144, 152, 0.3)",
                      borderRadius: "7px 7px 0 0",
                      height: "5.5vh",
                      width: "31.80vw",
                      paddingTop: "1.4vh",
                      paddingLeft: "1.3vw",
                      fontSize: "1vw",
                    }}
                  >
                    <Typography.Title level={4} style={{ color: "#0F0718", fontSize: "1em", fontWeight: 400 }}>
                      Incoming Data
                    </Typography.Title>
                  </div>
                  <Scrollbars style={{ height: "80vh" }} renderTrackHorizontal={(props) => <div {...props} style={{ display: "none" }} className="track-horizontal" />}>
                    <Row style={{ paddingLeft: "1.5vw", paddingRight: "1.4vw", height: "13vh", paddingTop: "2vh", fontSize: "1vw" }}>
                      <>
                        <Typography.Text style={{ textAlign: "left", fontWeight: 500, fontSize: "1em", color: "#0F0718", position: "absolute", left: "1.5vw" }}>
                          Till Data
                        </Typography.Text>
                        <Col key={"0"} style={{ marginTop: "5vh" }}>
                          <div style={{ width: "25vw", height: "0.6vh", backgroundColor: "rgba(217, 217, 217, 1)", borderRadius: "5px", top: "5vh" }}>
                            <div style={{ width: "25vw", height: "100%", backgroundColor: "rgba(0, 128, 0, 1)", borderRadius: "5px" }} />
                          </div>

                          <div style={{ width: "2vw", height: "2.2vh", left: "26vw", position: "absolute", top: "-2vh" }}>
                            <img src={TickCircle} style={{ width: "1.4vw", height: "3vh", left: "0.3vw" }} />
                          </div>

                          <Typography.Text
                            style={{ fontFamily: "Inter", fontSize: "0.9em", fontWeight: 400, color: "rgba(15, 7, 24, 1)", position: "absolute", paddingTop: "1vh" }}
                          >
                            {tillData?.time}
                          </Typography.Text>

                          <Typography.Text
                            style={{
                              fontFamily: "Inter",
                              fontSize: "0.9em",
                              fontWeight: 400,
                              color: "rgba(15, 7, 24, 1)",
                              position: "absolute",
                              marginLeft: "10vw",
                              paddingTop: "1vh",
                            }}
                          ></Typography.Text>

                          <Typography.Text
                            style={{
                              fontFamily: "Inter",
                              fontSize: "0.9em",
                              fontWeight: 400,
                              color: "rgba(15, 7, 24, 1)",
                              position: "absolute",
                              marginLeft: "25.1vw",
                              paddingTop: "1.1vh",
                            }}
                          >
                            {"Synced"}
                          </Typography.Text>
                        </Col>
                      </>
                    </Row>
                    {syncItems.map((syncItem, index) => (
                      <Row key={index} style={{ paddingLeft: "1.5vw", paddingRight: "1.4vw", height: "13vh", paddingTop: "2vh" }}>
                        <SyncItem
                          title={syncItem.title}
                          dataKey={syncItem.dataKey}
                          data={dataSyncSummery}
                          // data={dataSyncSummery[syncItem.dataKey] }
                          syncEndTimeTop={syncItem.syncEndTimeTop}
                          handleLinkClick={handleLinkClick}
                        />
                      </Row>
                    ))}
                  </Scrollbars>
                </Card>
              </Col>

              <Col span={8}>
                <Card style={{ borderRadius: "7px", height: "87vh", marginLeft: "-0.63em", marginRight: "-0.63em", fontSize: "1vw" }}>
                  <div
                    style={{
                      backgroundColor: "rgba(146, 144, 152, 0.3)",
                      borderRadius: "7px 7px 0 0",
                      height: "5.5vh",
                      width: "31.75vw",
                      paddingTop: "1.4vh",
                      paddingLeft: "1.3vw",
                    }}
                  >
                    <Typography.Title level={4} style={{ color: "rgba(15, 7, 24, 1)", fontSize: "1em", fontWeight: 400 }}>
                      Outgoing Data
                    </Typography.Title>
                  </div>

                  <Typography.Text style={{ textAlign: "left", fontWeight: 500, fontSize: "1em", color: "rgba(15, 7, 24, 1)", position: "absolute", left: "1.5vw", top: "7.75vh" }}>
                    Orders
                  </Typography.Text>

                  <div>
                    <Scrollbars style={{ width: "100%", height: "35vh", paddingLeft: "25px", marginTop: "10px" }} className="ordersDataScroll">
                      <Row style={{ paddingLeft: "1.5vw", paddingRight: "1.4vw", height: "25vh", fontSize: "1vw" }}>
                        {ordersCopy.map((order, index) => (
                          <Col span={24} key={index} style={{ paddingTop: "5.5vh" }}>
                            <Typography.Text style={{ color: "rgba(15, 7, 24, 1)", fontSize: "0.9em" }}>
                              {order.isReturn === true || order.isReturn === "Y" ? (
                                <span style={{ color: "#f0ad4e", fontWeight: 400 }}>Return</span>
                              ) : order.layAway === "Y" ? (
                                <span style={{ color: "#007BFF", fontWeight: 400 }}>Layaway</span>
                              ) : (order.total === 0 || order.total === "0.00") && order.totalQty === 0 ? (
                                <span style={{ color: "red" }}>Cancelled</span>
                              ) : (
                                <span style={{ color: "#28a745", fontWeight: 400 }}>Receipt</span>
                              )}{" "}
                              : {order.documentno}
                            </Typography.Text>

                            <div style={{ paddingTop: "0.5vh" }}>
                              <div style={{ width: "25vw", height: "0.6vh", backgroundColor: "rgba(217, 217, 217, 1)", borderRadius: "5px" }}>
                                <div
                                  style={{ width: "25vw", height: "100%", backgroundColor: order.isSynced ? "rgba(0, 128, 0, 1)" : "rgba(248, 197, 25, 1)", borderRadius: "5px" }}
                                />
                              </div>
                            </div>

                            <div style={{ position: "absolute", width: "2vw", height: "2vh", top: "7vh", left: "26.2vw" }}>
                              <img src={order.isSynced ? TickCircle : PieChart} style={{ width: "1.4vw", height: "3vh", top: "1vh", left: "0.5vw", position: "absolute" }} />
                            </div>

                            <Typography.Text style={{ paddingTop: "1vh", position: "absolute", fontWeight: 400, fontSize: "0.9em", color: "#0F0718" }}>
                              {order.orderTime}
                            </Typography.Text>

                            <Typography.Text
                              style={{
                                fontFamily: "Inter",
                                fontSize: "0.9em",
                                fontWeight: 400,
                                color: "rgba(15, 7, 24, 1)",
                                marginLeft: "25.72vw",
                                position: "absolute",
                                paddingTop: "1vh",
                              }}
                            >
                              {order.isSynced ? "Synced" : "Pending"}
                            </Typography.Text>
                          </Col>
                        ))}
                      </Row>
                    </Scrollbars>
                  </div>

                  <div style={{ position: "absolute", top: "46vh", left: "1.5vw", width: "28vw", height: "1px", backgroundColor: "rgba(146, 144, 152, 0.5)", fontSize: "1vw" }} />
                  <Typography.Text style={{ textAlign: "left", fontWeight: 500, fontSize: "1em", color: "rgba(15, 7, 24, 1)", position: "absolute", left: "1.5vw", top: "48vh" }}>
                    Till Events
                  </Typography.Text>

                  <Row style={{ paddingLeft: "1.5vw", paddingTop: "14vh", paddingRight: "1.4vw", height: "20vh" }}>
                    <Scrollbars style={{ width: "100%", height: "35vh" }} className="tillEventsScroll">
                      {tillEvents.map((item, index) => (
                        <Col span={24} style={{ marginTop: "2.5vh" }}>
                          <Typography.Text style={{ color: "rgba(15, 7, 24, 1)", fontSize: "1em", fontWeight: 500 }}>{item.tillStatus} till:</Typography.Text>

                          <div style={{ paddingTop: "0.5vh" }}>
                            <div style={{ width: "25vw", height: "0.6vh", backgroundColor: "rgba(217, 217, 217, 1)", borderRadius: "5px" }}>
                              <div
                                style={{ width: "25vw", height: "100%", backgroundColor: item.isSynced ? "rgba(0, 128, 0, 1)" : "rgba(248, 197, 25, 1)", borderRadius: "5px" }}
                              />
                            </div>
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              width: "14vw",
                              height: "2vh",
                              marginLeft: "14.8vw",
                              fontSize: "0.9em",
                              fontWeight: 400,
                              color: "#0F0718",
                              paddingTop: "1vh",
                            }}
                          >
                            User : {item?.user}
                          </div>

                          <div style={{ position: "absolute", width: "2vw", height: "2vh", top: "1.7vh", left: "26.1vw", paddingBottom: "1vh" }}>
                            <img src={item.isSynced ? TickCircle : PieChart} style={{ width: "1.4vw", height: "3vh", top: "0.8vh", left: "0.5vw", position: "absolute" }} />
                          </div>

                          <Typography.Text style={{ paddingTop: "1vh", position: "absolute", fontWeight: 400, fontSize: "0.9em", color: "#0F0718" }}>
                            {item.tillStatus === "open" ? item.openingTime : item.closingTime}
                          </Typography.Text>

                          <Typography.Text
                            style={{
                              fontFamily: "Inter",
                              fontSize: "0.9em",
                              fontWeight: 400,
                              color: "rgba(15, 7, 24, 1)",
                              marginLeft: "25.4vw",
                              paddingTop: "1.1vh",
                              position: "absolute",
                            }}
                          >
                            {item.isSynced ? "Synced" : "Pending"}
                          </Typography.Text>
                        </Col>
                      ))}
                    </Scrollbars>
                  </Row>
                </Card>
              </Col>

              <Col span={8} style={{ paddingRight: 0 }}>
                <Card style={{ borderRadius: "7px", height: "42.5vh", marginLeft: "-0.4em", fontSize: "1vw" }}>
                  <div
                    style={{
                      backgroundColor: "rgba(146, 144, 152, 0.3)",
                      borderRadius: "7px 7px 0 0",
                      height: "5.5vh",
                      width: "31.80vw",
                      paddingTop: "1.4vh",
                      paddingLeft: "1.3vw",
                    }}
                  >
                    <Typography.Title level={4} style={{ color: "rgba(15, 7, 24, 1)", fontSize: "1em", fontWeight: 300 }}>
                      Activity Log
                    </Typography.Title>
                  </div>
                  <Scrollbars
                    style={{ height: "35vh" }}
                    renderTrackHorizontal={(props) => <div {...props} style={{ display: "none" }} className="track-horizontal" />}
                    className="orderListScroll"
                  >
                    <Collapse bordered={false} style={{ backgroundColor: "transparent", fontSize: "1vw" }}>
                      {logActivity.map((logData, index) => {
                        return (
                          <Panel
                            header={
                              <div style={{ fontWeight: "500", fontSize: "1em", textAlign: "center", color: "rgba(15, 7, 24, 1)" }}>
                                {logData.title?.toString().length > 35 ? logData.title?.toString().substring(0, 35)?.concat("....") : logData.title}
                              </div>
                            }
                            key={index}
                          >
                            {Object.entries(logData).map(([key, value]) => {
                              return (
                                <p style={{ width: "40vw", height: "1vh", fontSize: "1em", fontWeight: 400, color: "#0F0718" }}>
                                  {key} - {value?.toString().length > 40 ? value?.toString().substring(0, 40)?.concat("....") : value}
                                </p>
                              );
                            })}
                          </Panel>
                        );
                      })}
                    </Collapse>
                  </Scrollbars>
                </Card>

                <Row gutter={24}>
                  <Col span={24}>
                    <Card style={{ borderRadius: "7px", height: "42.5vh", marginTop: "15px", paddingRight: 0, marginLeft: "-0.4em", fontSize: "1vw" }}>
                      <div
                        style={{
                          backgroundColor: "rgba(146, 144, 152, 0.3)",
                          borderRadius: "7px 7px 0 0",
                          height: "5.5vh",
                          width: "31.80vw",
                          paddingTop: "1.4vh",
                          paddingLeft: "1.3vw",
                        }}
                      >
                        <Typography.Title level={4} style={{ color: "rgba(15, 7, 24, 1)", fontSize: "1em", fontWeight: 300 }}>
                          Order List
                        </Typography.Title>
                      </div>

                      <Typography.Text style={{ top: "7vh", position: "absolute", left: "1.5vw", fontWeight: 400, fontSize: "1em", color: "#0F0718" }}>Order No</Typography.Text>

                      <Typography.Text style={{ top: "7vh", position: "absolute", left: "8vw", fontWeight: 400, fontSize: "1em", color: "#0F0718" }}>Sync Status</Typography.Text>

                      <Typography.Text style={{ top: "7vh", position: "absolute", left: "14.5vw", fontWeight: 400, fontSize: "1em", color: "#0F0718" }}>
                        Last Sync Time
                      </Typography.Text>

                      <div style={{ position: "absolute", top: "11.5vh", left: "1.5vw", width: "28vw", height: "1px", backgroundColor: "rgba(146, 144, 152, 0.5)" }} />

                      <Row style={{ paddingLeft: "1.3vw", paddingTop: "7.5vh", paddingRight: "1.4vw", height: "20vh" }}>
                        <Scrollbars style={{ width: "100%", height: "35vh" }} className="orderListScroll">
                          {ordersCopy.map((order, index) => (
                            <Col span={24} key={index} style={{ paddingTop: "0.8vh" }}>
                              <Typography.Text style={{ color: "rgba(15, 7, 24, 1)", fontSize: "0.9em", color: "#0F0718", marginTop: "5px" }}>{order.documentno}</Typography.Text>

                              <div style={{ position: "absolute", width: "2vw", height: "1.5vh", top: "0.2vh", left: "8.2vw", paddingBottom: "2px" }}>
                                <img src={order.isSynced ? TickCircle : PieChart} style={{ width: "1.4vw", height: "3vh", top: "0.8vh", left: "0.5vw", position: "absolute" }} />
                              </div>

                              <Typography.Text style={{ position: "absolute", left: "13.5vw", fontWeight: 400, fontSize: "0.9em", color: "#0F0718" }}>
                                {formatOrderTime(order.orderTime)}
                                {/* {order.orderTime} */}
                              </Typography.Text>

                              <button
                                onClick={() => {
                                  syncOrders(order);
                                }}
                                style={{
                                  width: "3.5vw",
                                  height: "3.2vh",
                                  top: "1vh",
                                  left: "21.5vw",
                                  padding: "8px 14px",
                                  borderRadius: "2px",
                                  border: "1px solid rgba(47, 56, 86, 1)",
                                  backgroundColor: "rgba(47, 56, 86, 1)",
                                  color: "white",
                                  cursor: "pointer",
                                  fontSize: "0.8em",
                                  position: "absolute",
                                  outline: "none",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                Sync
                              </button>

                              <button
                                onClick={() => {
                                  // setSelectedOrder(order);
                                  console.log(order, "--------->order");
                                  setSelectedOrder(order);
                                  setIsViewOrder(true);
                                }}
                                style={{
                                  width: "3.5vw",
                                  height: "3.2vh",
                                  top: "1vh",
                                  left: "25.5vw",
                                  padding: "8px 14px",
                                  borderRadius: "2px",
                                  border: "1px solid rgba(47, 56, 86, 1)",
                                  backgroundColor: "transparent",
                                  color: "rgba(47, 56, 86, 1)",
                                  cursor: "pointer",
                                  fontSize: "0.8em",
                                  position: "absolute",
                                  outline: "none",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                View
                              </button>
                            </Col>
                          ))}
                        </Scrollbars>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </>
      )}
    </Spin>
  );
}

export default Dashboard2;