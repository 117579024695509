import React, { useEffect, useRef,useState } from "react";
import { Row, Col, Button, Form } from "antd";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "./style.css";
import "./mobile.css";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import Logo from "../../../../assets/images/kioskLogo.svg";
import mobileImg from "../../../../assets/images/mobile.svg";
import { LeftCircleOutlined } from "@ant-design/icons";
import kisokLogo from "../../../../assets/images/kioskLogo.svg";
import volumeImg from "../../../../assets/images/volume.svg";
import wheelchairImg from "../../../../assets/images/wheelchair.svg";
import helpImg from "../../../../assets/images/help.svg";
import backImg from "../../../../assets/images/backImg.svg";
import kioskBGI from "../../../../assets/images/kioskBGI.png"


import { useTranslation } from "react-i18next";

const MobileComponent = (props) => {
  const { t } = useTranslation();
  const multilingual = localStorage.getItem("multilingual");
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const themeJSON = posConfig?.themeJSON && posConfig?.themeJSON !== "N" && Object.keys(posConfig.themeJSON).length > 0 ? JSON.parse(posConfig.themeJSON)?.layout3 : {};
  const colorVariable = process.env.REACT_APP_POSBACKGROUND_COLOR;
  const isThemeLogo = themeJSON.logoURL;
  const logoURL = isThemeLogo ? themeJSON.logoURL : kisokLogo;
  const {
    setKioskUI,
    layoutName,
    setLayoutName,
    onChange,
    // handleKeyboardInputs,
    keyboardRef,
    inputName,
    // setInputFields,
    kioskLogin,
    inputRef,
    setInputName,
    handleCustomerSearch,
    setCustomerSearchInput,
  } = props;
  // useEffect(()=>{
  //   kioskLogin.resetFields()
  //   setInputFields({})
  // },[])

  useEffect(() => {
    kioskLogin.resetFields();
    setInputFields({ mobile: '' });
  }, [kioskLogin]);

  const [inputFields, setInputFields] = useState({ mobile: '' });
  const [isLocked, setIsLocked] = useState(false);
  const keyBoardColor = themeJSON.keyBoardColor || "#fff"; 

  const logoStyles = isThemeLogo
  ? { height: "8vh", position: "relative", top: "2.3vh", left: "1.2vw", cursor: "pointer",width:"5vw" } // Styles for theme logo
  : { height: "2.8vw", position: "relative", top: "2.3vh", left: "1.2vw", cursor: "pointer" }; // Styles for kisok logo

  const mainDivStyles = themeJSON.appBgImageURL
  ? {
      backgroundImage: `url(${themeJSON.appBgImageURL})`,
      backgroundPosition: "100% 100%",
      backgroundSize: "cover",
      height: "100vh",
      width: "100%",
      display: isLocked ? "none" : "block"
      
    }
  : {
      backgroundImage: `url(${kioskBGI})`,
      backgroundSize:"cover",
      backgroundPosition:"10% 10%",
      height:"100vh",
      width:"100%",
      position:"absolute",
    };

    useEffect(() => {
      const applyKeyStyles = (color) => {
        document.querySelectorAll(".hg-button").forEach((key) => {
          key.style.backgroundColor = color;
          key.style.color = "#000"; 
        });
      };
      setTimeout(() => applyKeyStyles(keyBoardColor), 100);
      return () => {
        document.querySelectorAll(".hg-button").forEach((key) => {
          key.style.backgroundColor = "";
          key.style.color = ""; 
        });
      };
    }, [keyBoardColor]);

   
  useEffect(() => {
    if (inputRef.current) {
      const handleInputChange = () => {
        const value = inputRef.current.value;
        if (/^\d{0,10}$/.test(value)) {
          setInputFields({ mobile: value });
          kioskLogin.setFieldsValue({ mobile: value });
          if (keyboardRef.current) {
            keyboardRef.current.setInput(value);
          }
        }
      };
      inputRef.current.addEventListener('input', handleInputChange);
      return () => {
        inputRef.current.removeEventListener('input', handleInputChange);
      };
    }
  }, [inputRef, kioskLogin, keyboardRef]);

  const handleKeyboardInputs = (button) => {
    setInputFields(prevFields => {
      let updatedFields = { ...prevFields };
      if (button === '{bksp}') {
        updatedFields.mobile = updatedFields.mobile.slice(0, -1);
      } else if (/^\d$/.test(button)) {
        if (updatedFields.mobile.length < 10) {
          updatedFields.mobile += button;
        }
      } else if (button === '{enter}') {
        handleCustomerSearch();
      }
      kioskLogin.setFieldsValue({ mobile: updatedFields.mobile });
      // Update the virtual keyboard input
      if (keyboardRef.current) {
        keyboardRef.current.setInput(updatedFields.mobile);
      }
      return updatedFields;
    });
  };

  return (
    // <div style={{ height: "100vh" }}>
    <div style={mainDivStyles}>
      <div style={{ display: "flex" }}>
      <img
      src={logoURL} 
      style={logoStyles} 
      alt="Kiosk Logo" />
        <div style={{ width: "9vw" }}></div>

        <div className="mc-main-div">
          <div style={{ position: "relative" }}>
            <div className="mc-left-div">
              <img src={mobileImg} alt="mobile" className="mc-cl-img" />
            </div>
            <Form layout="vertical" form={kioskLogin} name="kioskLogin">
              <Form.Item
                onFocus={() => {
                  setInputName("mobile");
                  setLayoutName("default");
                }}
                ref={inputRef}
                name={"mobile"}
                label={<p style={{ margin: "0", color: "#fff", fontSize: "1.6vw" }}>Mobile Number</p>}
              >
                <input
                  inputMode="none"
                  className="mc-input-field"
                  autoFocus={true}
                  maxLength={10}
                  value={inputFields.mobile || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value) && value.length <= 10) {
                      setInputFields({ mobile: value });
                      kioskLogin.setFieldsValue({ mobile: value });
                      // setCustomerSearchInput(e.target.value);
                      if (keyboardRef.current) {
                        keyboardRef.current.setInput(value);
                      }
                    }
                  }}
                />
              </Form.Item>
            </Form>

            <div className="mc-input-lable"></div>
            <div style={{ height: "1vh", fontSize: "2.5vh", color: "red" }}></div>
          </div>
          <Button
           //  className="mc-continue-btn"
           style={{
              position:"relative",
              backgroundColor: themeJSON.btnsBgColor || "#EEC302",
              width:"26.2rem",
              height:"3.7rem",
              borderRadius:"7px",
              color:"#fff",
              fontSize:"1.4rem",
              fontWeight:"500"
           }} 
           onClick={handleCustomerSearch}>
            Continue
          </Button>
        </div>
        <div style={{ width: "11.5vw" }}></div>
        
        <div className="mc-kb-div">
          <Keyboard
            keyboardRef={(r) => (keyboardRef.current = r)}
            inputName={inputName}
            // onChangeAll={(inputs) => {
            //   setInputFields({ ...inputs });
            //   // setCustomerSearchInput(inputs.mobile);
            //   kioskLogin.setFieldsValue(inputs);
            // }}
            onChangeAll={(inputs) => {
              const mobileNumber = inputs.mobile || "";
              if (/^\d{0,10}$/.test(mobileNumber)) {
                setInputFields({ mobile: mobileNumber });
                kioskLogin.setFieldsValue({ mobile: mobileNumber });
                if (keyboardRef.current) {
                  keyboardRef.current.setInput(mobileNumber);
                }
              }
            }}
            onKeyPress={handleKeyboardInputs}
            layoutName={layoutName}
            theme={"hg-theme-default mobile"}
            buttonTheme={[
              {
                class: "hg-bksp",
                buttons: "{bksp} {backspace}",
              },
            ]}
            layout={{
              default: ["1 2 3", "4 5 6", "7 8 9", ". 0 {bksp}"],
            }}
          />
        </div>
        <div></div>
      </div>
      <div className="left-bottom-images">
        <div
          className="bottom-img"
          onClick={() => {
            localStorage.setItem("kioskUI",0);
            setKioskUI(0);
            const customEvent = new CustomEvent("customStorageChange", {
              detail: { key: "kioskUI", newValue: 0 },
            });
            window.dispatchEvent(customEvent);
          }}
        >
          <img src={backImg} style={{ height: "2.7vw" }} />
          <p style={{ color: "#fff", margin: "0" }}>Back</p>
        </div>
        <div className="bottom-img">
          <img src={volumeImg} style={{ height: "2.7vw" }} />
          <p style={{ color: "#fff", margin: "0" }}>Volume</p>
        </div>
        <div className="bottom-img">
          <img src={wheelchairImg} style={{ height: "2.7vw" }} />
          <p style={{ color: "#fff", margin: "0" }}>Accessibility</p>
        </div>
        <div className="bottom-img">
          <img src={helpImg} style={{ height: "2.7vw" }} />
          <p style={{ color: "#fff", margin: "0" }}>Help</p>
        </div>
      </div>
    </div>
  );
};

export default MobileComponent;
