import React, { createContext, useContext, useState } from 'react';
import ManagerApprovalModal from "./ManagerApprovalModal";

// Event Bus Context
const EventBusContext = createContext();

// Event Bus Provider
const EventBusProvider = ({ children }) => {
  const [events, setEvents] = useState([]);
  const [isManagerApprovalModalVisible, setManagerApprovalModalVisible] = useState(false);
  const [approvalGrantedCallback, setApprovalGrantedCallback] = useState(null);

  const dispatchEvent = (eventName, ruleName, ruleCondition,approvers, callback) => {
    const eventData = WorkflowEngine.processEvent({ name: eventName, ruleName, ruleCondition });

    setEvents([{ eventName, ruleName, ruleCondition,approvers }]);
    
    openManagerApprovalModalIfNeeded(eventData, callback);
  };
  
  const openManagerApprovalModalIfNeeded = (eventData, callback) => {
    if (eventData && eventData.managerApprovalRequired) {
      localStorage.setItem("approvalFlag","0");
      setManagerApprovalModalVisible(true); // Open manager approval modal
      setApprovalGrantedCallback(() => callback);
    }
  };

  // Function to close the manager approval modal
  const closeManagerApprovalModal = () => {
    localStorage.setItem("approvalFlag","1");
    setManagerApprovalModalVisible(false);
  };

  const handleApprovalGranted = (data) => {
    closeManagerApprovalModal();
    // Simulate approval
    const approvalGranted = data;
    const eventData = { approvalGranted };
    if (approvalGranted && approvalGrantedCallback) {
      approvalGrantedCallback(eventData); // Pass the eventData back
    }
  };
    

  return (
    <EventBusContext.Provider value={{ dispatchEvent, events }}>
      {children}
      {isManagerApprovalModalVisible && (
        <ManagerApprovalModal
          event={events}
          onClose={closeManagerApprovalModal}
          visible={isManagerApprovalModalVisible}
          setManagerApprovalModalVisible={setManagerApprovalModalVisible}
          onApprovalGranted={handleApprovalGranted}
        />
      )}
    </EventBusContext.Provider>
  );
};

// Use this hook to access the event bus
const useEventBus = () => useContext(EventBusContext);

// Workflow Engine
const WorkflowEngine = {
  processEvent: (eventWithRules) => {
    const rule = eventWithRules.ruleName;
    const actions = JSON.parse(eventWithRules.ruleCondition).conditions[0].actions;
    switch (rule) {
      case 'Draft Print Receipt Approval':
        console.log(JSON.parse(eventWithRules.ruleCondition));
        if (actions[0].actionType === "requestApprovalForDraftPrint") {
          localStorage.setItem("approvalFlag","0");
          return { managerApprovalRequired: true, actions };
        }
        case 'Sales Return Approval' :
          // const actions = JSON.parse(eventWithRules.ruleCondition).conditions[0].actions;
          console.log(JSON.parse(eventWithRules.ruleCondition));
          if (actions[0].actionType === "requestApprovalForReturn") {
            return { managerApprovalRequired: true, actions };
          }  
       case "Receipt Reprint Approval" :
        // const actions = JSON.parse(eventWithRules.ruleCondition).conditions[0].actions;
        console.log(JSON.parse(eventWithRules.ruleCondition));
        if (actions[0].actionType === "requestApprovalForReprint") {
          return { managerApprovalRequired: true, actions };
        }
        break;
      default:
        return { approvalGranted: true };
    }
  },
};


export { EventBusProvider, useEventBus };
