import Dexie from "dexie";

const db = new Dexie("cwpos");
db.version(24340)
  .stores({
    tillRegistrations: "++id",
    productCategories: "mProductCategoryId",
    productBrands: "brandId",
    newBrands: "brandId",
    dataSyncSummary: "++id, syncId, syncType",
    products: "++id, mProductId, mProductCategoryId, value, name, upc, brandId, *batchIndex, *upcIndex",
    orders: "sOrderID, orderTime, documentno, isSynced, tillSessionId, customerSearchKey",
    pricingRules: "++id, billAmount, mPricingrulesId",
    posSaletypes: "++id, cwrSaletypeId",
    productUom: "++id, csUomId",
    tillEvents: "++id, tillSessionId, isSynced, tillStatus",
    logConfiguration: "++id",
    logInformation: "++id",
    restaurantTables: "++id",
    cashInCashOut: "++id",
    rfidData: "tag_value",
    restaurantTables: "++id",
    tableData: "cwrFbTableId, name, tableSync",
    fbOrderData: "++id,fbOrderId,cwrFbTableId,fbOrderStatus,fbOrderSync",
    sectionTables: "++id",
    loyalityData: "loyaltylevelId",
    AllProductCategories: "mProductCategoryId",
    POSWorkFlowRules: "cwrRulesId",
    approvers: "++id",
    giftCardData: "cwrGiftcardTypeId",
    paymentsData: "++id, tillSessionId, orderTime, tillStatus",
    ordersData: "++id, tillSessionId, orderTime, tillStatus",
    logActivity: "++id",
    salesRep: "s_salesrep_id, name,code",
    docTypesData: "++cs_doctype_id"
  })
  .upgrade((tx) => {
    // Database Migrations
    console.log("DB Migration -> ", tx);
  });

// Catch any errors related to missing object stores
db.on("blocked", () => {
  console.error("Upgrade needed, but another connection is blocking it.");
});

// Open the database
db.open().catch((err) => {
  console.error(`Failed to open db: ${err.stack}`);
});

export default db;
