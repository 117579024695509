import Axios from "axios";

const openDrawer = () => {
    const tillData = JSON.parse(localStorage.getItem("tillData"));
    const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;
  
    let openDrawerPayload = `<?xml version="1.0" encoding="UTF-8"?>
    <output>
    <opendrawer/>
    </output>`;
    Axios.post(`${printerURL}printer`, openDrawerPayload, {
      headers: {
        "Content-Type": "application/xml; charset=UTF-8",
        Accept: "application/xml",
      },
    })
      .then(() => {
        console.log("openDrawer success!");
      })
      .catch((response) => {
        console.log("openDrawer failed!", response);
      });
  };

  export default openDrawer;