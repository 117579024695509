import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { I18nextProvider } from 'react-i18next';
import { Modal } from 'antd';
import i18n from './i18n';

const tabId = sessionStorage.getItem('tabId') || Date.now().toString();
sessionStorage.setItem('tabId', tabId);

const isAnotherTabOpen = () => {
    const activeTabId = localStorage.getItem('activeTabId');
    return activeTabId && activeTabId !== tabId;
};

const setActiveTab = () => {
    localStorage.setItem('activeTabId', tabId);
};

const clearActiveTab = () => {
    const activeTabId = localStorage.getItem('activeTabId');
    if (activeTabId === tabId) {
        localStorage.removeItem('activeTabId');
    }
};

const closeTab = () => {
    window.close();
};

const showWarningModal = () => {
    Modal.warning({
        title: 'Application Already Open',
        content: 'The POS system is already open in another tab. Please switch to that tab.',
        onOk: closeTab
    });
};

window.addEventListener('storage', (event) => {
    if (event.key === 'activeTabId' && event.newValue !== tabId) {
        showWarningModal();
    }
});

window.addEventListener('unload', clearActiveTab);

if (window.opener || isAnotherTabOpen()) {
    showWarningModal();
} else {
    setActiveTab();
    ReactDOM.render(
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>,
        document.getElementById('root')
    );
};