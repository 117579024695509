import React, { useState, useEffect,useReducer } from "react";
import { HashRouter as Router, Route,Switch } from "react-router-dom";
import { AuthContext } from "../lib/auth";
import { GlobalContext,storeReducer } from "../lib/auth/globalStore";
import PrivateRoute from "./PrivateRoute";
import Login from "./Login";
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import TillReg from "./TillRegistration";
import PointOfSale from "./PointOfSale";
import Dashboard from "./Dashboard";
import Sync from "./Sync";
import OpenTill from "./OpenTill";
import OpenTillComponent from "./OpenTill/OpenTillComponent";
import CloseTill from "./CloseTill";
import CloseTillAll from "./CloseTill/CloseTIllAll";
import CashToKeep from "./CloseTill/cashToKeep";
import TableManagement from "./PointOfSale/Restaurant/Tables-Management/tables";
import BillManagement from "./PointOfSale/Restaurant/billManagement";
import DayClose from "./DayClose";
import PurchaseGRN from "./PurchaseGrn";
import STReceipt from "./STReceipt";
import WastageQty from "./wastageQty";
import B2BSales from "./B2BSales";
import PriceUpdate from "./PriceUpdate";
import Till from "./Till/Till";
import Dashboard2 from "./Dashboard2"
import "./style.css";
import serviceWorker from "../serviceWorker";
import ErrorBoundary from './ErrorBoundary';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { EventBusProvider } from "../lib/hooks/EventBusProvider";

const App = (props) => {
  const [authTokens, setAuthTokens] = useState(localStorage.getItem("tokens"));
  const SentryDSN = process.env.REACT_APP_SENTRY_DSN;
  const SentryEnabled = process.env.REACT_APP_ENABLE_SENTRY;
  const environment = process.env.REACT_APP_Sentry_Environment;
  // const setTokens = (data) => {
  //   localStorage.setItem("tokens", JSON.stringify(data));
  //   setAuthTokens(data);
  // };
  const setTokens = () => {
    const getQueryParams = (location) => {
      const searchParams = new URLSearchParams(location.search);
      const params = {};
      for (const [key, value] of searchParams) {
        params[key] = value;
      }
      return params;
    };
    const location = window.location;
    const params = getQueryParams(location);
    if (params?.searchKey) {
      localStorage.setItem("tiilId",params?.searchKey)
      localStorage.setItem("tillName",params?.nameTill)
      // setAuthTokens(tokens)
    };
  };

  useEffect(() => {
    setTokens();
  }, []);

  useEffect(() => {
    if(SentryEnabled){
      Sentry.init({
        environment:environment,
        dsn: SentryDSN,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
          new Integrations.BrowserTracing(),
          Sentry.replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: true,
            blockAllMedia: true,
          }),
        ],
        tracesSampleRate: 1.0,
      });
    }
    // Log a message to Sentry
    
  }, []);

//  serviceWorker();

let percent = "0%";
let productCategoryPercentage="0";
let productPercentage="0";
let promotionPercentage="0"
  const [globalStore, setGlobalStore] = useReducer(storeReducer, {
   percent:percent,
   productCategoryPercentage:productCategoryPercentage,
   productPercentage:productPercentage,
   promotionPercentage:promotionPercentage
  });

  return (
    <div className="aspectRatio">
            <GlobalContext.Provider value={{ globalStore, setGlobalStore }}>
      {/* <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}> */}
      <I18nextProvider i18n={i18n}>
        <EventBusProvider>
        <Router>
        <ErrorBoundary>  
        <Switch>
        <Route exact path="/" component={Till} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/registration" component={TillReg} />
          <PrivateRoute exact path="/pos" component={PointOfSale} />
          <PrivateRoute exact path="/sync" component={Sync} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/dashboard2" component={Dashboard2} />
          {/* <PrivateRoute exact path="/productionOrder" component={ProductionOrder} /> */}
          <PrivateRoute exact path="/open-till" component={OpenTill} />
          <PrivateRoute exact path="/cash-to-keep" component={CashToKeep} />
          <PrivateRoute exact path="/open-till-component" component={OpenTillComponent} />
          <PrivateRoute exact path="/close-till" component={CloseTill} />
          <PrivateRoute exact path="/close-till-all" component={CloseTillAll} />
          {/* <PrivateRoute exact path="/cash-event" component={CashEvent} /> */}
          <PrivateRoute exact path="/table-management" component={TableManagement} />
          {/* <PrivateRoute exact path="/bill-management" component={BillManagement} /> */}
          <PrivateRoute exact path="/day-close" component={DayClose} />
          <PrivateRoute exact path="/purchase-grn" component={PurchaseGRN} />
          <PrivateRoute exact path="/stock-receipt" component={STReceipt} />
          <PrivateRoute exact path="/wastage-qty" component={WastageQty} />
          <PrivateRoute exact path="/b2b-sales" component={B2BSales} />
          <PrivateRoute exact path="/price-update" component={PriceUpdate} />
          {/* <PrivateRoute exact path="*" component={ErrorBoundary} /> */}
          </Switch>
          </ErrorBoundary>
        </Router>
        </EventBusProvider>
        </I18nextProvider>
        </GlobalContext.Provider>
      {/* </AuthContext.Provider> */}
    </div>
  );
};

export default App;
