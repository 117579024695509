import React,{useEffect} from "react";
import { Route, Redirect,useLocation } from "react-router-dom";
// import { useAuth } from "../../lib/auth";
import 'intro.js/introjs.css';
import IntroJS from 'intro.js';
import IntroCloseicon from '../../assets/images/introCloseIcon.png'


const PrivateRoute = ({ component: Component, ...rest }) => {

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
      navigator.serviceWorker
        .register('/worker.js')
        .then(() => {
        })
        .catch(() => {
        });
    });
  };
  
  const location = useLocation();

  const productTourSteps = localStorage.getItem("productTourSteps")
  const existingUser = localStorage.getItem('existingUser')

  useEffect(() => {
    // const tillData = JSON.parse(localStorage.getItem("tillData"));
    // let tillLayout 
    // if(tillData){
    //   const tillaccess = JSON.parse(tillData.tillAccess.userAccessController);
    //    tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);
    // }

    const pageKey = `intro-tour-${window.location.hash}`;
    const hasCompletedTour = localStorage.getItem(pageKey);
    if (!hasCompletedTour &&   (existingUser === null || existingUser === 'undefined')) {

      const jsonArray = JSON.parse(JSON.parse(productTourSteps));
      let currentPageSteps = [];
      if (jsonArray && Array.isArray(jsonArray)) {
        jsonArray.forEach((data) => {
          data.data.forEach((stepsData)=>{
            if (stepsData.page === window.location.hash || stepsData.page + '/' === window.location.hash) {
              currentPageSteps = stepsData.steps;
            }
          })
        
        });
      }
  
      let phoneImg = document.createElement('img');
      phoneImg.src = IntroCloseicon;
      phoneImg.alt = 'Skip tour';
  
      let intro = IntroJS().setOptions({
        steps: currentPageSteps,
        nextButton: 'my-next-button',
        prevButton: 'my-prev-button',
        skipLabel: phoneImg.outerHTML
      });
  
      intro.oncomplete(() => {
        localStorage.setItem(pageKey, 'completed');
      });
  
      setTimeout(() => {
        intro.start();
      }, 2000);
    }
  }, [location]);
  // const { authTokens } = useAuth();
  return <Route {...rest} render={(props) => (<Component {...props} />)} />;
};

export default PrivateRoute;
