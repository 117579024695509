import React from "react";
import { withRouter } from "react-router-dom";
import { Modal, Button } from "antd";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, unhandledRejection: false };
  }

  componentDidMount() {
    window.addEventListener("unhandledrejection", this.handlePromiseRejection);
  }

  componentWillUnmount() {
    window.removeEventListener("unhandledrejection", this.handlePromiseRejection);
  }
  componentDidUpdate(prevProps, prevState) {
    if ((prevState.hasError || prevState.unhandledRejection) && !this.state.hasError && !this.state.unhandledRejection) {
      setTimeout(() => {
        this.setState({ unhandledRejection: true });
      }, 5000); // 5000 milliseconds = 5 seconds
    }
  }
  handlePromiseRejection = (event) => {
    console.error("Unhandled promise rejection:", event.reason);
    if (!this.state.hasError) {
      this.setState({ unhandledRejection: true });
    }
  };

  componentDidCatch(error, errorInfo) {
    console.error("Error:", error);
    console.error("Error Info:", errorInfo);
    this.setState({ hasError: true });
  }

  handleOk = () => {
    this.setState({ hasError: false, unhandledRejection: false });
    this.props.history.push("/");
  };

  render() {
    const { hasError, unhandledRejection } = this.state;
    if (hasError || unhandledRejection) {
      return (
        <Modal
          title="Error"
          visible={hasError || unhandledRejection}
          onCancel={this.handleOk}
          footer={[
            <Button key="ok" type="primary" onClick={this.handleOk}>
              Ok
            </Button>,
          ]}
        >
          <p>{navigator.onLine ? "There was an error while loading this page. Please try again later." : "Network Error"}</p>
        </Modal>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
