import React, { useEffect } from "react";
import { Row, Col, Button, Input, Form } from "antd";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "./style.css";
import "./email.css";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import Logo from "../../../../assets/images/kioskLogo.svg";
import Email from "../../../../assets/images/email.svg";
import { LeftCircleOutlined } from "@ant-design/icons";
import kisokLogo from "../../../../assets/images/kioskLogo.svg";
import volumeImg from "../../../../assets/images/volume.svg";
import wheelchairImg from "../../../../assets/images/wheelchair.svg";
import helpImg from "../../../../assets/images/help.svg";
import backImg from "../../../../assets/images/backImg.svg";

const Login = (props) => {
  const {
    setKioskUI,
    layoutName,
    setLayoutName,
    onChange,
    handleKeyboardInputs,
    keyboardRef,
    inputName,
    setInputFields,
    emailLogin,
    inputRef,
    setInputName,
    handleCustomerSearch,
    setCustomerSearchInput,
    kioskLogin,
    form,
    addNewCustomer,
    deleteCart,
  } = props;
  //   const { t } = useTranslation();
  //   kioskLogin.setFieldsValue({
  //     email1 : "check"
  //   })

  return (
    <div style={{ height: "100vh" }}>
      <div className="customer-login">
        {/* <img src={kisokLogo} style={{ height: "2.6vw", position: "relative", top: "2.3vh", left: "1.2vw" }} /> */}
      </div>
      <div style={{height:"9vh",display:"flex",justifyContent:"space-between"}}>
        <img
          src={kisokLogo}
          style={{ height: "2.6vw", position: "relative", top: "2.3vh", left: "1.2vw" ,cursor:"pointer"}}
        />
        <img src={backImg} style={{paddingRight:"2vw",cursor:"pointer"}} onClick={() => {
            form.resetFields();
            localStorage.setItem("kioskUI", 0);
            setKioskUI(1);
            const customEvent = new CustomEvent("customStorageChange", {
              detail: { key: "kioskUI", newValue: 0 },
            });
            window.dispatchEvent(customEvent);
          }}/>
      </div>

      <div style={{ display: "flex", justifyContent: "end", alignItems: "center", flexDirection: "column", height: "50vh", paddingBottom: "3vh" }}>
        <Form layout="vertical" key={"addCustomer"} form={form} name="addCustomer" onFinish={addNewCustomer}>
          <Row>
            <Col span={8} offset={4}>
              <Form.Item
                name="name"
                onFocus={() => {
                  setInputName("name");
                  setLayoutName("default");
                }}
                label={<p style={{ margin: "0", color: "#fff", fontSize: "1.6vw" }}>First Name</p>}
              >
                <input inputMode="none" className="login-input-field" autoFocus={true} />
              </Form.Item>
            </Col>
            <Col span={8} offset={1}>
              <Form.Item
                name="lastName"
                onFocus={() => {
                  setInputName("lastName");
                  setLayoutName("default");
                }}
                label={<p style={{ margin: "0", color: "#fff", fontSize: "1.6vw" }}>Last Name</p>}
              >
                <input inputMode="none" className="login-input-field" autoFocus={true} />
              </Form.Item>
            </Col>
            <Col span={3} />
            <Col span={8} offset={4}>
              <Form.Item
                name="mobile"
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(/^[6-9][0-9]{9}$/),
                    message: "Please Input Valid Contact Number",
                  },
                ]}
                onFocus={() => {
                  setInputName("mobile");
                  setLayoutName("number");
                }}
                label={<p style={{ margin: "0", color: "#fff", fontSize: "1.6vw" }}>Phone No</p>}
              >
                <input inputMode="none" className="login-input-field" autoFocus={true} />
              </Form.Item>
            </Col>
            <Col span={8} offset={1}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: false,
                    pattern: new RegExp(/\S+@\S+\.\S+/),
                    message: "Please Input Valid Email",
                  },
                ]}
                onFocus={() => {
                  setInputName("email");
                  setLayoutName("default");
                }}
                label={<p style={{ margin: "0", color: "#fff", fontSize: "1.6vw" }}>Email Address</p>}
              >
                <input inputMode="none" className="login-input-field" autoFocus={true} />
              </Form.Item>
            </Col>
            <Col span={3} />
          </Row>
          <div className="mc-input-lable"></div>
          <div style={{ height: "1vh", fontSize: "2.5vh", color: "red" }}></div>
          <Col span={8} offset={8}>
            <Button className="email-continue-btn" htmlType="submit">
              Continue
            </Button>
          </Col>
        </Form>
      </div>

      <div className="email-kb-div">
        <Keyboard
          buttonTheme={[
            {
              class: "hg-yellow",
              buttons: "{space}",
            },
            {
              class: "hg-space",
              buttons: "{spaces}",
            },
            {
              class: "hg-mail",
              buttons: "@yahoo.com @gmail.com",
            },
          ]}
          keyboardRef={(r) => (keyboardRef.current = r)}
          inputName={inputName}
          onChangeAll={(inputs) => {
            const loginType = localStorage.getItem("loginType");
            const formData = form.getFieldsValue();
            const updatedInputs = {
              name: inputs.name,
              lastName: inputs.lastName,
              email: inputs.email,
              mobile: loginType === "mobile" ? formData.mobile : inputs.mobile ,
            };

            if (loginType === "mobile") {
              updatedInputs.mobile = formData.mobile;
              updatedInputs.email = inputs.email || formData.email;
            } else {
              updatedInputs.email = inputs.email || formData.email;
              updatedInputs.mobile = inputs.mobile || formData.mobile;
            }
            setInputFields(updatedInputs);

            form.setFieldsValue({
              email: updatedInputs.email,
              mobile: updatedInputs.mobile,
              name: updatedInputs.name,
              lastName: updatedInputs.lastName,
            });
          }}
          onKeyPress={handleKeyboardInputs}
          layoutName={layoutName}
          theme={"hg-theme-default email"}
          display={{
            "{abc}": "ABC",
            "{smileys}": "\uD83D\uDE03",
            "{numbers}": "123",
            "{number}": "123",
            "{bksp}": "backspace",
            "{backspace}": "⌫",
            "{shift}": "⇧",
            "{tab}": "tab",
            "{lock}": "lock",
            "{enter}": "enter",
            "{space}": "Space",
            "{spaces}": "Space",
            "{caps}": "ABC",
            "{small}": "abc",
            "{back}": "Enter",
          }}
          layout={{
            shift: ["q w e r t y u i o p", "a s d f g h j k l", "{shift} z x c v b n m {bksp}", "{number} {space}"],
            numbers: ["1 2 3 4 5 6 7 8 9 0", '- / : ; ( ) $ & @ "', "# . , ? ! ` {bksp}", "{caps} {space}"],
            number: ["1 2 3 4 5 6 7 8 9 0", '- / : ; ( ) $ & @ "', "# . , ? ! ` {bksp}", "{small} {space}"],
            default: ["Q W E R T Y U I O P", "A S D F G H J K L", "{shift} Z X C V B N M {bksp}", "{numbers} {space}"],
          }}
        />
      </div>
    </div>
  );
};

export default Login;
