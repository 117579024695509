export const QuantityDiscount = async (
  product,
  pricingRule,
  setCart,
  cart,
  orderType,
  cartObj,
  iscoupon,
  couponInput,
  uniqReferenceId,
  mPricingCouponId,
  mPricingRulesId,
  removeDiscounts
) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const taxIncludeFlag = tillData.tillAccess.csBunit.isTaxIncluded ? tillData.tillAccess.csBunit.isTaxIncluded : "Y";
  const saleTypeValidation = pricingRule?.cwrSaletypeId === null || orderType === undefined ? true : pricingRule?.cwrSaletypeId === orderType?.cwrSaletype?.cwrSaletypeId;
  let modificationsMade = false; // Flag to indicate modifications

  // Iterate through each item in the cart
  cartObj.items.map((item) => {
    let excludingFlag = true;

    const matchingCategory = pricingRule.mPricingPcategories.find((pc) => pc.mProductCategoryId === item.mProductCategoryId);
    if (
      (pricingRule.excludeProductCategories === "Y" && matchingCategory) ||
      (pricingRule.excludeProductCategories !== "Y" && !matchingCategory) ||
      (pricingRule.excludeProductCategories === "N" && pricingRule.mPricingPcategories.length === 0)
    ) {
      excludingFlag = false;
    }

    const matchingBrand = pricingRule.mPricingBrands.find((pc) => pc.mBrandId === item.productBrandId);
    if (
      (pricingRule.excludeBrands === "Y" && matchingBrand) ||
      (pricingRule.excludeBrands !== "Y" && !matchingBrand) ||
      (pricingRule.excludeBrands === "N" && pricingRule.mPricingBrands.length === 0)
    ) {
      excludingFlag = false;
    }

    const matchingProductIndex = pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === item.productId);
    if (
      (pricingRule.excludeProducts === "Y" && matchingProductIndex !== -1) ||
      (pricingRule.excludeProducts !== "Y" && matchingProductIndex === -1) ||
      (pricingRule.excludeProducts === "N" && pricingRule.mPricingXProducts.length === 0)
    ) {
      excludingFlag = false;
    }

    let pricingRuleFlag = true;

    // Apply additional logic for pricingRuleFlag based on your requirements

    if (item.discount <= 0) {
      // console.log("-----quantity")
      delete item.priority;
    }

    if (
      saleTypeValidation &&
      excludingFlag &&
      pricingRuleFlag &&
      (product.productId === item.productId || iscoupon || removeDiscounts) &&
      (item?.priority ? item?.priority < pricingRule.priority : true)
    ) {
      const offerIndex =
        pricingRule.mPricingXProducts.length > 0 && pricingRule.excludeProducts === "N"
          ? pricingRule.mPricingXProducts.findIndex((op) => op.mProductId === item.productId)
          : pricingRule.excludeProducts === "Y"
          ? pricingRule.mPricingXProducts.length > 0 && pricingRule.mPricingXProducts.findIndex((op) => op.mProductId !== item.productId)
          : 0;
      if (offerIndex >= 0) {
        let discountAmt = 0;
        if (pricingRule.mPricingQuantities.length > 0) {
          pricingRule.mPricingQuantities.forEach((qd) => {
            if (parseFloat(qd.quantity) === parseFloat(Math.abs(item.weight))) {
              if (pricingRule.discountType === "V") {
                discountAmt = parseFloat(qd.discountValue);
              } else {
                discountAmt = (qd.discountValue / 100) * parseFloat(item.realPrice);
              }
            }
          });
        }

        if (discountAmt > 0) {
          const sp = (parseFloat(item.realPrice) * item.weight - discountAmt) / item.weight;
          const mrp = parseFloat(sp) * item.weight;
          const tax = taxIncludeFlag === "Y" ? mrp - mrp / (1 + item.taxRate / 100) : (mrp * item.taxRate) / 100;
          item.salePrice = sp;
          item.taxAmount = parseFloat(tax.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision));
          item.nettotal =
            taxIncludeFlag === "Y"
              ? parseFloat(mrp.toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision))
              : parseFloat((mrp + tax).toFixed(tillData.tillAccess.csBunit.currencies[0].prcPrecision)) * item.weight;
          item.discount = discountAmt;
          item.discountName = pricingRule.printedName;
          item.mPricingruleId = pricingRule.mPricingrulesId;
          item.nextRule = pricingRule.nextRule;
          item.iscoupon = iscoupon;
          item.priority = pricingRule.priority;
          item.couponInput = couponInput;
          item.referenceId = uniqReferenceId;

          // Modifications made for the current item
          modificationsMade = true;
        } else {
          const mrp = parseFloat(item.realPrice) * item.weight;
          const tax = taxIncludeFlag === "Y" ? mrp - mrp / (1 + item.taxRate / 100) : (mrp * item.taxRate) / 100;
          item.taxAmount = tax;
          item.nettotal = taxIncludeFlag === "Y" ? mrp : mrp + tax;
          item.discount = 0;
          item.discountName = "";
          item.mPricingruleId = null;
          delete item.nextRule;
        }
      }
    }
  });

  if (modificationsMade) {
    // Update the cart object with the adjusted items
    let couponsData = cart.couponInput?.length > 0 ? [...cart.couponInput] : [];
    if (!couponsData.some((coupon) => coupon.couponCode === couponInput) && couponInput !== undefined && couponInput !== null) {
      couponsData.push({ couponCode: couponInput, referenceId: uniqReferenceId, mPricingCouponId: mPricingCouponId, mPricingruleId: mPricingRulesId });
    }
    cartObj.iscoupon = iscoupon;
    cartObj.couponInput = couponsData;
    setCart(cartObj);
    localStorage.setItem("cartObj", JSON.stringify(cartObj));
    return cartObj;
  }
};
