const TableOrder = ({ color }) => {
  return (
    <svg height="3vh" viewBox="0 0 20 20" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.4167 3.3335H4.58333C4.1231 3.3335 3.75 3.70659 3.75 4.16683V17.5002C3.75 17.9604 4.1231 18.3335 4.58333 18.3335H15.4167C15.8769 18.3335 16.25 17.9604 16.25 17.5002V4.16683C16.25 3.70659 15.8769 3.3335 15.4167 3.3335Z"
        stroke="black"
        stroke-linejoin="round"
      />
      <path
        d="M7.5 1.66675V4.16675M12.5 1.66675V4.16675M6.66667 7.91675H13.3333M6.66667 11.2501H11.6667M6.66667 14.5834H10"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default TableOrder;
