import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Input, Button, Modal, Empty, Spin, notification } from "antd";
import { LoadingOutlined, SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
// import { serverUrl } from "../../constants/config";
import { v4 as uuidv4 } from "uuid";
import Axios from "axios";
import _ from "lodash";
import { clock } from "../../utility/clock";
import { timeStamp } from "../../utility/timestamp";
// import logoIcon from "../../assets/images/cwpos-blue.png";
import MenuIcon from "../../assets/images/menu.png";
import POSLogo from "../../assets/images/cwPosLogo.png";
import DefaultProductImage from "../../assets/images/default-product.webp";
import CircleCloseIcon from "../../assets/images/circle-close-icon.svg";
import Backspace from "../../assets/images/backspac.png";
import Tick from "../../assets/images/tick-small.svg";
import "../style.css";
import db from "../../database";
import data from "../../constants/purchaseGrn.json";
import posUiData from "../../constants/retailPos.json";
import AspectRatio from "react-aspect-ratio";
import { getOAuthHeaders } from "../../constants/oAuthValidation";


const { TextArea } = Input;

const B2BSales = () => {
  const serverUrl = process.env.REACT_APP_serverUrl;
  const dynamicHeight = 0.5625;
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tokens = JSON.parse(localStorage.getItem("tokens"));
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  let cleanToken;

  // CLOCK BLOCK START
  const [displayClock, setDisplayClock] = useState(clock());
  useEffect(() => {
    const authHeaders = getOAuthHeaders();
    if (authHeaders) {
      cleanToken = authHeaders.access_token
    };
    const timerId = setInterval(() => setDisplayClock(clock()), 1000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);
  // CLOCK BLOCK END

  const [customerList, setCustomerList] = useState([]);
  const [filteredData, setFilteredData] = useState({
    customer: {},
    remarks: "",
  });

  const handlePosLogo = () => {
    history.push("/dashboard");
  };

  const [confirmModalClose, setConfirmModalClose] = useState(false);

  const handleHome = (e) => {
    if (e.keyCode === 27 && e.keyCode !== 13) {
      if (document.activeElement.className === "ant-modal-wrap") {
        setConfirmModalClose(false);
      } else {
        Modal.confirm({
          content: <div>Go to Home Screen?</div>,
          okText: "Ok",
          onOk: () => {
            history.push("/dashboard");
            setConfirmModalClose(false);
          },
          onCancel: () => {
            setConfirmModalClose(false);
          },
        });
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleHome);
    return () => {
      window.removeEventListener("keydown", handleHome);
    };
  }, []);

  const setDefaultImage = (e) => {
    e.target.src = DefaultProductImage;
  };

  const [customerProductList, setCustomerProductList] = useState([]);
  const [customerSearchInput, setCustomerSearchInput] = useState("");

  const onSelect = (value) => {
    setCustomerSearchInput(value);
  };

  // Cutomer Selection Modal Starts //

  const [displayCutomerModal, setDisplayCustomerModal] = useState(false);
  const handleCustomerSearch = (value) => {
    const customerInput = {
      query: `query{
          customerSearch(name:"${customerSearchInput}"){
            cwrCustomerId 
            code   
            name
            email        
            mobileNo
            name
            pincode
            retlLoyaltyBalance
            b2cRegisteredstoreId
             iscredit
            loyaltyLevel{
                cwrLoyaltyLevelId
                name
                accumulationRate
                redemptionRate
            }
            sCustomer{
              sCustomerID
              customerCategory{
                sCustomerCateforyId
                value
                name
                description
              }
            }
            csBunit{
              csBunitId
              name
            }
            b2cCustomerSegment{
              name
              cwrB2CCustomerSegmentId
            }
          }
        }`,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: customerInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${cleanToken}`,
      },
    }).then((response) => {
      const { customerSearch } = response.data.data;
      setCustomerList([...customerSearch]);
      setDisplayCustomerModal(true);
      setCustomerSearchInput("");
    });
  };

  const handleSelectedCustomer = (value) => {
    setFilteredData({ ...filteredData, customer: value });
    setDisplayCustomerModal(false);
  };

  // Cutomer Selection Modal Ends //

  const [addProdToStockDisplayModal, setAddProdToStockDisplayModal] = useState(false);
  const [addProdsToStockList, setAddProdsToStockList] = useState([]);
  const [addProdToStock, setAddProdToStock] = useState("");
  const [searchAddProdToStock, setSearchAddProdToStock] = useState(addProdsToStockList);
  const [displayManualQtyWeightInput, setDisplayManualQtyWeightInput] = useState(false);
  const [productWeightModalInput, setProductWeightModalInput] = useState("");
  const [currentWeightSelectedProduct, setCurrentWeightSelectedProduct] = useState({});

  const handleAddProdToStock = () => {
    setAddProdToStock("");
    db.products
      .where("mProductId")
      .startsWithAnyOfIgnoreCase("")
      .toArray()
      .then((productsFetched) => {
        let newProductsFetched = productsFetched.map((item, index) => {
          return {
            ...item,
            qty: 0,
            netTotal: 0,
          };
        });
        setAddProdsToStockList([...newProductsFetched]);
        setSearchAddProdToStock([...newProductsFetched]);
        setAddProdToStockDisplayModal(true);
      });
  };

  const addProdToStockSearch = (value) => {
    if (value !== "") {
      const results = addProdsToStockList.filter((prodToStock) => {
        return prodToStock.name.toLowerCase().startsWith(value.toLowerCase());
      });
      setSearchAddProdToStock(results);
    } else {
      setSearchAddProdToStock(addProdsToStockList);
    }
    setAddProdToStock(value);
  };

  const handleWeightModal = (prod) => {
    setCurrentWeightSelectedProduct(prod);
    setAddProdToStockDisplayModal(false);
    setDisplayManualQtyWeightInput(true);
  };

  const handleWeightManual = (value) => {
    if (productWeightModalInput === "" && value === "x") {
      setProductWeightModalInput("");
    } else if (value === "x") {
      setProductWeightModalInput(`${productWeightModalInput.toString().substring(0, productWeightModalInput.toString().length - 1)}`);
    } else {
      setProductWeightModalInput(`${productWeightModalInput}${value}`);
    }
  };

  const addManualWeightToProduct = (value) => {
    let newCustomerProductList = [...customerProductList];
    const prodIndex = _.findIndex(addProdsToStockList, ["mProductId", value.mProductId]);
    addProdsToStockList[prodIndex].qty = Number(productWeightModalInput);
    addProdsToStockList[prodIndex]["remarks"] = filteredData.remarks;
    addProdsToStockList[prodIndex].netTotal = addProdsToStockList[prodIndex].qty * addProdsToStockList[prodIndex].sunitprice;
    const productIndex = _.findIndex(newCustomerProductList, ["mProductId", value.mProductId]);
    if (productIndex !== -1) {
      newCustomerProductList.splice(productIndex, 1, addProdsToStockList[prodIndex]);
    } else {
      newCustomerProductList.push(addProdsToStockList[prodIndex]);
    }
    setCustomerProductList([...newCustomerProductList]);
    setDisplayManualQtyWeightInput(false);
    setProductWeightModalInput("");
    setFilteredData({
      ...filteredData,
      remarks: "",
    });
  };

  const handlePrice = (prodIndex, value) => {
    let newCustomerProductList = [...customerProductList];
    customerProductList[prodIndex].sunitprice = value;
    customerProductList[prodIndex].netTotal = customerProductList[prodIndex].qty * customerProductList[prodIndex].sunitprice;
    setCustomerProductList([...newCustomerProductList]);
  };

  const key = `open${Date.now()}`;
  const btn = (
    <Button className="notificationCnfmBtn" onClick={() => notification.close(key)}>
      Confirm
    </Button>
  );

  const tillDocumentSequence = parseFloat(localStorage.getItem("documentSequence"));
  const [documentSequence, setDocumnetSequence] = useState(tillDocumentSequence);

  const handleReceive = () => {
    const uniqueId = uuidv4().replace(/-/g, "").toUpperCase();
    const presentTimeStamp = timeStamp();
    const newDocumentSequence = documentSequence + 1;
    localStorage.setItem("documentSequence", newDocumentSequence);
    const paymentMethodId = tillData.tillAccess.csBunit.paymentMethodList.filter((item) => item.iscredit === true);
    const csCurrencyId = localStorage.getItem("csCurrencyId");
    const orderLines = [];
    customerProductList.map((item, index) => {
      // let unitPrice = item.netTotal / item.qty;
      // unitPrice = parseFloat(unitPrice).toFixed(2);
      item.qty > 0 &&
        orderLines.push(`{
        sOrderlineID: "${uuidv4().replace(/-/g, "").toUpperCase()}",
        created: "${presentTimeStamp}",
        createdby: "${tillData.tillAccess.csUserId}",
        updated: "${presentTimeStamp}",
        updatedby: "${tillData.tillAccess.csUserId}",
        sOrderId: "${uniqueId}",
        line: ${(index + 1) * 10},
        description: "",
        mProductId: "${item.mProductId}",
        csUomId: "${item.csUomId}",
        csTaxId: "${item.cTaxId}",
        qty: ${item.qty},
        unitprice: ${item.sunitprice},
        netlist: ${item.slistprice}
        discount: 0,
        returnline: false,
        returnQty: 0,
        mBatchId: null,
        mPricingruleId: null,
        batchedForSale:"${item.batchedForSale}",
        batchedForStock:"${item.batchedForStock}",
        batchedProduct:"${item.batchedProduct}",
        salesRepId: null,
        multiPrice: "${item.multiPrice}",
        discountTypeId: null,
        discountAmount: null,
      }`);
      return null;
    });

    const paramsInput = {
      query: `mutation{
    posOrderProcessor(posOrder:{
    sOrderID: "${uniqueId}",
    cSClientID: "${tillData.tillAccess.csClientId}",
    cSBunitID: "${tillData.tillAccess.csBunit.csBunitId}",
    created: "${presentTimeStamp}",
    createdby: "${tillData.tillAccess.csUserId}",
    updated: "${presentTimeStamp}",
    updatedby: "${tillData.tillAccess.csUserId}",
    csDoctypeId: "${tillData.tillAccess.csBunit.cwrCsDoctypeId}",
    sCustomerId: "${filteredData.customer.cwrCustomerId}",
    sCustomerBillingId: "${tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID}",
    sCustomerShippingId: "${tillData.tillAccess.csBunit.customerAddress.sCustomerAddressID}",
    sPricelistId: "${tillData.tillAccess.csBunit.cwrSpricelistId}",
    documentno: "${tillData.tillAccess.cwrTill.prefix}${newDocumentSequence}",
    dateordered: "${presentTimeStamp}",
    datepromised: "${presentTimeStamp}",
    csPaymenttermID: null,
    finPaymentmethodId: "${paymentMethodId[0].finPaymentmethodId}",
    csCurrencyId: "${tillData.tillAccess.csBunit.currencies[0].csCurrencyId}",
    mWarehouseId: "${tillData.tillAccess.csBunit.mWarehouse.mWarehouseID}",
    cwrLongitude: "",
    cwrLatitude: "",
    csUserId: "${tillData.tillAccess.csUserId}",
    cwrB2cCustomerId: "${filteredData.customer.cwrCustomerId}",
    orderreference: "",
    cwrPayref: "",
    cwrPayremarks: "",
    description: "",
    cwrTillId: "${tillData.tillAccess.cwrTill.cwrTillID}",
    redemption: 0,
    accumulation: 0,
    roundoff: 0,
    cwrProductQty: ${customerProductList.length},
    cwrProductCount: ${_.sumBy(customerProductList, "qty")},
    ofdStatus: "Delivered",
    ofdIspaid: "Y",
    mPricingruleId: null,
    cwrSaletypeId: "A251223B0177420C9D92BAB4D1CD0F48",
    salesRepId: null,
    discAmount: 0,
    creditAmount: 0,
    pricingCoupon:{
      mPricingCouponId: null,
      redemptionCount: null,
    }
    isReturn:"N",
    sOrderReturnId: null,
    layAway: "N",
    payments: [],
    line: [${orderLines}]
    })
  {
    documentno 
    status
    message
   }
  }
  `,
    };
    Axios({
      url: serverUrl,
      method: "POST",
      data: paramsInput,
      headers: {
        "Content-Type": "Application/json",
        Authorization: `${cleanToken}`,
      },
    }).then((response) => {
      const result = response.data.data.posOrderProcessor;
      const { status } = result;
      if (status === "200") {
        setDocumnetSequence(newDocumentSequence);
        notification.open({
          message: `Document No - ${response.data.data.posOrderProcessor.documentno} ${response.data.data.posOrderProcessor.message}`,
          type: "success",
          btn,
          key,
          duration: 15,
          style: {
            width: 500,
          },
        });
        setFilteredData({
          customer: "",
        });
        setCustomerProductList([]);
      }
    });
  };

  // Shortcuts Code //

  const customerSearchRef = useRef();
  useEffect(() => {
    setTimeout(() => {
      customerSearchRef.current.focus();
    }, 100);
  }, []);

  const handleKey = (e) => {
    const { altKey, keyCode } = e;
    if (altKey) {
      if (keyCode === 80) {
        handleAddProdToStock();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("keydown", handleKey);
    };
  }, []);

  const antIcon = <LoadingOutlined style={{ fontSize: "24px" }} spin />;

  return (
    <div>
      <Spin indicator={antIcon} spinning={loading} tip="...Loading">
        {/* <BarCodeComponent onScan={handleSku} /> */}
        <div style={data.purchaseGrn.header}>
          <Row style={{ height: `${3.7 * dynamicHeight}vw` }}>
            <Col span={2} style={{ alignSelf: "center" }}>
              <img src={MenuIcon} width="27%" alt="" />
            </Col>
            <Col span={10} style={{ alignSelf: "center" }}>
              <label style={data.purchaseGrn.header.posHeadLabel}>{tillData.tillAccess.csBunit.name}</label>
            </Col>
            <Col span={8} style={{ alignSelf: "center" }}>
              <label style={data.purchaseGrn.header.tillName}>{tillData.tillAccess.cwrTill.till}</label>
            </Col>
            <Col span={2} style={{ alignSelf: "center", textAlign: "right" }}>
              <label style={data.purchaseGrn.header.time}>{displayClock}</label>
            </Col>
            <Col span={2} style={{ alignSelf: "center", textAlign: "right" }}>
              <img onClick={handlePosLogo} src={POSLogo} style={data.purchaseGrn.header.logoIcon} alt="" />
            </Col>
          </Row>
        </div>
        <div style={{ padding: 10 }}>
          <p style={data.purchaseGrn.purchaseText}>B2B Sales</p>
          <Row style={{ paddingBottom: 10 }}>
            <Col span={8}>
              <Row>
                <Col span={5} style={{ alignSelf: "center" }}>
                  <p style={data.purchaseGrn.inputLabel}>Customer</p>
                </Col>
                <Col span={19}>
                  <Input
                    ref={customerSearchRef}
                    placeholder="customer"
                    onChange={(e) => onSelect(e.target.value)}
                    onPressEnter={handleCustomerSearch}
                    value={Object.keys(filteredData.customer).length > 0 ? filteredData.customer.name : customerSearchInput}
                    // allowClear={true}
                    suffix={<SearchOutlined onClick={handleCustomerSearch} />}
                    className="customerSelect"
                  />
                </Col>
              </Row>
            </Col>
            <Col span={4} style={{ alignSelf: "center", paddingLeft: 20 }}>
              <p
                style={{
                  marginBottom: 0,
                  fontSize: "13px",
                  fontWeight: 600,
                  fontFamily: "Inter",
                  color: Object.keys(filteredData.customer).length > 0 ? "#0C4DA9" : "#dddddd",
                  opacity: 1,
                  letterSpacing: "0.26px",
                  cursor: "pointer",
                }}
                onClick={() => (Object.keys(filteredData.customer).length > 0 ? handleAddProdToStock() : "")}
              >
                + ADD PRODUCTS
              </p>
            </Col>
            <Col span={12} />
          </Row>
          <Card key="z" style={data.purchaseGrn.mainCardFirst} bodyStyle={{ padding: 0 }}>
            <Row style={data.purchaseGrn.headingRow}>
              <Col span={8}>
                <strong>Product</strong>
              </Col>
              <Col span={4} style={{ textAlign: "center" }}>
                <strong>Qty</strong>
              </Col>
              <Col span={6} style={{ textAlign: "center" }}>
                <strong>Price</strong>
              </Col>
              <Col span={6} style={{ textAlign: "center" }}>
                <strong>Net Total</strong>
              </Col>
            </Row>
            {customerProductList.length > 0 ? (
              <div>
                <Scrollbars autoHide className="purchaseGrnScroll">
                  {customerProductList.map((item, index) => (
                    <Row style={{ padding: "10px 10px 0px" }}>
                      <Col span={8}>
                        <Row>
                          <Col span={4}>
                            <img onError={setDefaultImage} src={item.imageurl} alt="" style={data.purchaseGrn.prodImgInTable} />
                          </Col>
                          <Col span={20} style={{ paddingTop: 5 }}>
                            <Row>
                              <p style={data.purchaseGrn.desc}>{item.value}</p>
                            </Row>
                            <Row>
                              <p style={data.purchaseGrn.prodTitle}>{item.name}</p>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={4} style={{ alignSelf: "center", textAlign: "center" }}>
                        <Input readOnly={true} style={data.purchaseGrn.priceTotalAmount} value={Number(item.qty).toFixed(2)} />
                      </Col>
                      <Col span={6} style={{ alignSelf: "center", textAlign: "center" }}>
                        <Input
                          style={{
                            width: "45%",
                            padding: "5px 10px",
                            backgroundColor: "#ffffff",
                            fontWeight: "normal",
                            textAlign: "center",
                            border: "1px solid #F7F7F7",
                            borderRadius: "5px",
                            outline: "none",
                            boxShadow: "none",
                          }}
                          value={Number(item.sunitprice)}
                          onChange={(e) => handlePrice(index, e.target.value)}
                        />
                      </Col>
                      <Col span={6} style={{ alignSelf: "center", textAlign: "center" }}>
                        <Input readOnly={true} style={data.purchaseGrn.priceTotalAmount} value={Number(item.netTotal).toFixed(2)} />
                      </Col>
                    </Row>
                  ))}
                </Scrollbars>
              </div>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: "10% 0px" }} />
            )}
            <div style={{ display: customerProductList.length > 0 ? "block" : "none" }}>
              <Row style={{ padding: 10 }}>
                <Col span={8} />
                <Col span={4} />
                <Col span={6} />
                <Col span={6}>
                  <p
                    style={{
                      marginBottom: 0,
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontFamily: "Inter",
                      letterSpacing: "0px",
                      color: "#000000",
                      opacity: 1,
                      textAlign: "center",
                    }}
                  >
                    Total: {_.sumBy(customerProductList, "netTotal")}{" "}
                  </p>
                </Col>
              </Row>
              <Row style={{ padding: 10 }}>
                <Col span={21} />
                <Col span={3} style={{ float: "right" }}>
                  <Button style={data.purchaseGrn.receiveBtn} onClick={handleReceive}>
                    <span style={data.purchaseGrn.receiveBtn.label}>CONFIRM</span>
                  </Button>
                </Col>
              </Row>
            </div>
          </Card>
        </div>

        {/* ADD PRODUCTS MODAL START */}
        <Modal
          visible={addProdToStockDisplayModal}
          onCancel={() => setAddProdToStockDisplayModal(false)}
          closable={false}
          footer={null}
          // width="40%"
          bodyStyle={{ padding: 15 }}
        >
          <Row>
            <Col span={12}>
              <p style={data.purchaseGrn.addProdToStockModal.modalTitle}>Add Product</p>
            </Col>
            <Col span={12}>
              <img src={CircleCloseIcon} alt="" style={{ float: "right" }} onClick={() => setAddProdToStockDisplayModal(false)} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Input
                size="large"
                placeholder="Search"
                value={addProdToStock}
                onChange={(e) => {
                  addProdToStockSearch(e.target.value);
                }}
                allowClear
                suffix={<SearchOutlined />}
                className="stockProdSearchIpt"
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Scrollbars autoHide className="addWastageProdScroll">
              {searchAddProdToStock && searchAddProdToStock.length > 0
                ? searchAddProdToStock.map((item, index) => (
                    <Row style={{ marginBottom: 5, cursor: "pointer" }} onClick={() => handleWeightModal(item)}>
                      <Col span={4}>
                        <img onError={setDefaultImage} src={item.imageurl} alt="" style={data.purchaseGrn.prodImgInTable} />
                      </Col>
                      <Col span={20} style={{ paddingTop: 5 }}>
                        <Row>
                          <p style={data.purchaseGrn.desc}>{item.value}</p>
                        </Row>
                        <Row>
                          <p style={data.purchaseGrn.prodTitle}>{item.name}</p>
                        </Row>
                      </Col>
                    </Row>
                  ))
                : ""}
            </Scrollbars>
          </Row>
        </Modal>
        {/* ADD PRODUCTS MODAL END */}

        {/* PRODUCT WEIGHT MODAL START */}
        <Modal
          visible={displayManualQtyWeightInput}
          closable={false}
          // onCancel={() => {
          //   setDisplayManualQtyWeightInput(false);
          //   setProductWeightModalInput("");
          //   setFilteredData({ ...filteredData, remarks: "" });
          // }}
          footer={null}
          width="45%"
          bodyStyle={{ padding: 10 }}
        >
          <Row>
            <Col span={12}>
              <p style={data.purchaseGrn.productWeightModal.modalTitle}>Enter the product quantity</p>
            </Col>
            <Col span={12}>
              <p style={data.purchaseGrn.productWeightModal.closeIconP}>
                <CloseOutlined
                  onClick={() => {
                    setDisplayManualQtyWeightInput(false);
                    setProductWeightModalInput("");
                    setFilteredData({ ...filteredData, remarks: "" });
                  }}
                  style={data.purchaseGrn.productWeightModal.closeIcon}
                />
              </p>
            </Col>
          </Row>
          <Row gutter={[8, 0]}>
            <Col span={9}>
              <Card bodyStyle={{ padding: 10 }} style={data.purchaseGrn.productWeightModal.imgCard}>
                <img onError={setDefaultImage} width="100%" height="100%" src={currentWeightSelectedProduct.imageurl} alt="" />
              </Card>
              <Row style={{ paddingTop: 10 }}>
                <h3 style={data.purchaseGrn.productWeightModal.prodHead}>{currentWeightSelectedProduct.name || ""}</h3>
              </Row>
              <Row>
                <Col span={9}>
                  <h3 style={data.purchaseGrn.productWeightModal.prodHead}>MRP</h3>
                  <h3 style={data.purchaseGrn.productWeightModal.prodHead}>Quantity</h3>
                </Col>
                <Col span={15}>
                  <p style={data.purchaseGrn.productWeightModal.prodPriceValue}>{currenciesList[0].symbolRightSide === "N" ? currenciesList[0].currSymbol : ""} {(currentWeightSelectedProduct.slistprice || 0).toFixed(2)} {currenciesList[0].symbolRightSide === "Y" ? currenciesList[0].currSymbol : ""}</p>
                  <p style={data.purchaseGrn.productWeightModal.prodPriceValue}>{productWeightModalInput || 0}</p>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <TextArea
                    style={data.purchaseGrn.productWeightModal.remarksInput}
                    rows={4}
                    placeholder="Remarks"
                    value={filteredData.remarks}
                    onChange={(e) => setFilteredData({ ...filteredData, remarks: e.target.value })}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={15}>
              <Card bodyStyle={{ padding: 15 }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Input
                      value={productWeightModalInput}
                      // type={currentWeightSelectedProduct.isDecimal === true ? "number" : "text"}
                      onChange={(e) => {
                        console.log("key", e.target.value);
                        let newNumber = currentWeightSelectedProduct.isDecimal === true ? e.target.value : e.target.value.replace(/[^\d]*/g, "");
                        setProductWeightModalInput(newNumber);
                      }}
                      style={data.purchaseGrn.productWeightModal.topayInput}
                      size="large"
                    />
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("1")}>
                        1
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("2")}>
                        2
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("3")}>
                        3
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("x")}>
                        <img src={Backspace} alt="back space" width={20} height={15} />
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("4")}>
                        4
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("5")}>
                        5
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("6")}>
                        6
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button
                        className="amt-dial-btn"
                        onClick={() => currentWeightSelectedProduct.isDecimal && handleWeightManual(".")}
                        disabled={currentWeightSelectedProduct.isDecimal === false}
                      >
                        .
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("7")}>
                        7
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("8")}>
                        8
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("9")}>
                        9
                      </button>
                    </AspectRatio>
                  </Col>
                  <Col span={6}>
                    <AspectRatio ratio="1">
                      <button className="amt-dial-btn" onClick={() => handleWeightManual("0")}>
                        0
                      </button>
                    </AspectRatio>
                  </Col>
                  <Button size="large" style={data.purchaseGrn.productWeightModal.confirmQtyBtn} onClick={() => addManualWeightToProduct(currentWeightSelectedProduct)}>
                    <img src={Tick} alt="" />
                  </Button>
                </Row>
              </Card>
            </Col>
          </Row>
        </Modal>
        {/* PRODUCT WEIGHT MODAL END */}

        {/* CUSTOMER SELECTION MODAL START*/}
        <Modal
          visible={displayCutomerModal}
          closable={false}
          onCancel={() => {
            setDisplayCustomerModal(false);
            setCustomerSearchInput("");
          }}
          footer={null}
          width="35%"
          bodyStyle={{ padding: "20px 15px 15px" }}
        >
          <Row>
            <Col span={14}>
              <p style={posUiData.posScreenStyles.orderTypeSelectionModal.modalTitle}>Customer</p>
            </Col>
            <Col span={10}>
              <p style={posUiData.posScreenStyles.offerSelectionModal.closeIconP}>
                <CloseOutlined
                  onClick={() => {
                    setDisplayCustomerModal(false);
                    setCustomerSearchInput("");
                  }}
                  style={posUiData.posScreenStyles.offerSelectionModal.closeIcon}
                />
              </p>
            </Col>
          </Row>
          <Row>
            <Card className="orderHistoryCard" style={posUiData.posScreenStyles.cardDetails} bodyStyle={{ padding: 10 }}>
              {customerList.length > 0 ? (
                <Scrollbars autoHide className="orderHistoryScroll">
                  {customerList.map((item, index) => (
                    <Card
                      key={item.cwrCustomerId}
                      style={posUiData.posScreenStyles.orderHistoryModal.oderHistoryDetailsCard}
                      bodyStyle={{ padding: 20 }}
                      onClick={() => handleSelectedCustomer(item)}
                      onKeyDown={(e) => (e.keyCode === 13 ? handleSelectedCustomer(item) : null)}
                      tabIndex={0}
                    >
                      <Row>
                        <Col span={24}>
                          <p
                            style={{
                              fontSize: "1vw",
                              marginBottom: "0px",
                              color: "#000000",
                              fontWeight: 600,
                              cursor: "pointer",
                            }}
                          >
                            {item.name}
                          </p>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                </Scrollbars>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Card>
          </Row>
        </Modal>
        {/* CUSTOMER SELECTION MODAL END*/}
      </Spin>
    </div>
  );
};

export default B2BSales;
