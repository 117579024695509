import Axios from "axios";
import db from "../../database";

const CWGiftCardHCPrint = async (printData, type) => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const printerURL = tillData?.tillAccess?.cwrTill?.hardwareController?.imageUrl;

  let GiftCardHCPrintPayload;
  let giftCardData = await db.giftCardData?.toArray();

  // Find the digital gift card data
  giftCardData?.forEach((GCData) => {
    if (GCData?.type === "DIG") {
      GiftCardHCPrintPayload = GCData?.printTemplate?.xmlcode2;
    }
  });

  // Object holding the placeholder values
  const replacements = {
    // "@@giftCardName@@": type === "Credit Note" ? "Credit Note" : printData?.name,
    "@@giftCardNumber@@": printData?.cardNo,
    // "@@giftCardAmount@@": printData?.nettotal,
    // "@@validThrough@@": printData?.expiryGiftCard,
  };

  // Replace all placeholders in one pass
  GiftCardHCPrintPayload = Object.keys(replacements).reduce(
    (payload, key) => payload.replaceAll(key, replacements[key]),
    GiftCardHCPrintPayload
  );

  // Send the print request
  Axios.post(`${printerURL}printer`, GiftCardHCPrintPayload, {
    headers: {
      "Content-Type": "application/xml; charset=UTF-8",
      Accept: "application/xml",
    },
  })
    .then(() => {
      console.log("Gift Card Print success!");
    })
    .catch((response) => {
      console.log("Gift Card Print failed!", response);
    });
};

export default CWGiftCardHCPrint;
