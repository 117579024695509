import { flatDiscount } from "./FlatDiscount";
import { PercentageDiscount } from "./PercentageDiscount";
import { QuantityDiscount } from "./QuantityDiscount";
import { PackageDiscount } from "./PackageDiscount";
import { BuyXGetYDiscount } from "./BuyXGetYDiscount";
import { priceAdjustment } from "./priceAdjustment";
import { ExpiryDiscount } from "./ExpiryDiscount";
import { BuyXPayYOfDifferent } from "./BuyXPayYOfDifferent";
import { BuyXPayYOfSame } from "./BuyXPayYOfSame";
import db from "../../../../database";
import moment from "moment";
import { Modal, Input } from "antd";
import { timeStamp, timeValidator, currentDay, dateValidator } from "../../../../utility/clock";

export const pricingRuleController = async (
  addToCart,
  cartObj,
  cart,
  setCart,
  cartRef,
  orderType,
  iscoupon,
  couponInput,
  uniqReferenceId,
  mPricingCouponId,
  mPricingRulesId,
  removeDiscounts
) => {
  let product = addToCart;
  let finalData = cartObj;
  let matchFound = false;
  const tillData = JSON.parse(localStorage.getItem("tillData"));

  const pr = await db.pricingRules.toArray();
  const pricingRuleCount = pr.length;

  if (pricingRuleCount > 0 && (!product?.isGiftCard || product?.promotionEligible === "Y") && !product?.isReturn) {
    pr.sort((a, b) => {
      if (a.priority === null && b.priority === null) {
        return 0;
      } else if (a.priority === null) {
        return 1;
      } else if (b.priority === null) {
        return -1;
      }
      return parseInt(b.priority, 10) - parseInt(a.priority, 10);
    });

    for (const pricingRule of pr) {
      const cuponFlag = iscoupon === true && pricingRule.iscoupon === "Y" ? true : iscoupon === true && pricingRule.iscoupon === "N" ? false : true;
      let pricingRuleFlag = iscoupon ? pricingRule.mPricingrulesId === mPricingRulesId : true;
      if (!matchFound && dateValidator(pricingRule.startDate, pricingRule.endDate) && cuponFlag && pricingRuleFlag) {
        const minQtyFlag = pricingRule.minimumQty ? Math.abs(product.weight) >= pricingRule.minimumQty : true;
        const maxQtyFlag = pricingRule.maximumQty ? Math.abs(product.weight) <= pricingRule.maximumQty : true;
        const minAmountFlag = pricingRule.billAmount ? Math.abs(product.nettotal) >= pricingRule.billAmount : true;
        const maxAmountFlag = pricingRule.maxBillAmount ? Math.abs(product.nettotal) <= pricingRule.maxBillAmount : true;

        let excludingFlag = true;

        const matchingCustomerSegment = pricingRule.mPricingB2CCustomerSegments.find(
          (pc) => pc.cwrB2CCustomerSegmentId === cartObj.customer?.b2cCustomerSegment?.cwrB2CCustomerSegmentId
        );

        if (
          (pricingRule.excludeB2CSegment === "Y" && matchingCustomerSegment) ||
          (pricingRule.excludeB2CSegment !== "Y" && !matchingCustomerSegment) ||
          (pricingRule.excludeB2CSegment === "N" && pricingRule.mPricingB2CCustomerSegments.length === 0)
        ) {
          excludingFlag = false;
        }

        const matchingCustomer = pricingRule.mPricingB2CCustomers.find((pc) => pc.b2cCustomerId === cartObj.customer.cwrCustomerId);
        if (
          (pricingRule.excludeB2CCustomers === "Y" && matchingCustomer) ||
          (pricingRule.excludeB2CCustomers !== "Y" && !matchingCustomer) ||
          (pricingRule.excludeB2CCustomers === "N" && pricingRule.mPricingB2CCustomers.length === 0)
        ) {
          excludingFlag = false;
        }

        const matchingBusinessUnit = pricingRule.mPricingBUnits.find((pc) => pc.mBunitPricingId === tillData.tillAccess.csBunit.csBunitId);
        if (
          (pricingRule.excludeBusinessUnits === "Y" && matchingBusinessUnit) ||
          (pricingRule.excludeBusinessUnits !== "Y" && !matchingBusinessUnit) ||
          (pricingRule.excludeBusinessUnits === "N" && pricingRule.mPricingBUnits.length === 0)
        ) {
          excludingFlag = false;
        }

        if (pricingRule.timeSpecific === "Y") {
          const weekDay = currentDay();
          const pStartTime = pricingRule?.starttime !== null ? pricingRule?.starttime?.substring(11) : moment(new Date()).format("YYYY-MM-DD HH:mm:ss").substring(11);
          const pEndTIme = pricingRule?.endtime !== null ? pricingRule?.endtime?.substring(11) : moment(new Date()).format("YYYY-MM-DD HH:mm:ss").substring(11);
          const starttime = timeStamp().substring(0, 10) + " " + pStartTime;
          const endtime = timeStamp().substring(0, 10) + " " + pEndTIme;
          if (timeValidator(starttime, endtime) && pricingRule[weekDay] === "Y" && minQtyFlag && minAmountFlag && maxQtyFlag && maxAmountFlag && excludingFlag) {
            const matchedResult = await matchedPricingRule(product,pricingRule,setCart,cart,orderType,cartObj,iscoupon,couponInput,uniqReferenceId,mPricingCouponId,mPricingRulesId,removeDiscounts);
            if (matchedResult !== undefined) {
              finalData = matchedResult;
              matchFound = true;
              break; // Break out of the loop
            }
          }
        } else {
          if (minQtyFlag && minAmountFlag && maxQtyFlag && maxAmountFlag && excludingFlag) {
            const matchedResult = await matchedPricingRule(product,pricingRule,setCart,cart,orderType,cartObj,iscoupon,couponInput,uniqReferenceId,mPricingCouponId,mPricingRulesId,removeDiscounts);

            if (matchedResult !== undefined) {
              finalData = matchedResult;
              matchFound = true;
              break; // Break out of the loop
            }
          }
        }
      }
    }
  }

  return finalData;
};

const matchedPricingRule = async (product, pricingRule, setCart, cart, orderType, cartObj,  iscoupon, couponInput, uniqReferenceId, mPricingCouponId, mPricingRulesId, removeDiscounts) => {
  const expiryDiscount = localStorage.getItem("expiryDiscount") !== null && localStorage.getItem("expiryDiscount") === "Y" ? true : false;
  const cuponFlag = iscoupon === true && pricingRule.iscoupon === "Y" ? true : !iscoupon && pricingRule.iscoupon === "N" ? true : false;
  if (pricingRule.type === "FD" && (pricingRule.manualDiscount === "N" || pricingRule.manualDiscount === null) && !expiryDiscount && cuponFlag) {
    let latestData = await flatDiscount(product, pricingRule, setCart, cart, orderType, cartObj, iscoupon, couponInput, uniqReferenceId, mPricingCouponId, mPricingRulesId, removeDiscounts);
    return latestData;
  }

  if (pricingRule.type === "PA" && (pricingRule.manualDiscount === "N" || pricingRule.manualDiscount === null) && !expiryDiscount && cuponFlag) {
    let latestData = await priceAdjustment(product, pricingRule, setCart, cart, orderType, cartObj, iscoupon, couponInput, uniqReferenceId, mPricingCouponId, mPricingRulesId, removeDiscounts);
    return latestData;
  }

  if (pricingRule.type === "PD" && (pricingRule.manualDiscount === "N" || pricingRule.manualDiscount === null) && !expiryDiscount && cuponFlag) {
    let latestData = await PercentageDiscount(product, pricingRule, setCart, cart, orderType, cartObj, iscoupon, couponInput, uniqReferenceId, mPricingCouponId, mPricingRulesId, removeDiscounts);
    return latestData;
  }

  if (pricingRule.type === "PK" && (pricingRule.manualDiscount === "N" || pricingRule.manualDiscount === null) && !expiryDiscount && cuponFlag) {
    let latestData = await PackageDiscount(product, pricingRule, setCart, cart, orderType, cartObj, iscoupon, couponInput, uniqReferenceId, mPricingCouponId, mPricingRulesId, removeDiscounts);
    return latestData;
  }

  if (pricingRule.type === "BXD" && (pricingRule.manualDiscount === "N" || pricingRule.manualDiscount === null) && !expiryDiscount && cuponFlag) {
    let latestData = await BuyXPayYOfDifferent(product, pricingRule, setCart, cart, orderType, cartObj, iscoupon, couponInput, uniqReferenceId, mPricingCouponId, mPricingRulesId, removeDiscounts);
    return latestData;
  }

  if (pricingRule.type === "BXS" && (pricingRule.manualDiscount === "N" || pricingRule.manualDiscount === null) && !expiryDiscount && cuponFlag) {
    let latestData = await BuyXPayYOfSame(product, pricingRule, setCart, cart, orderType, cartObj, iscoupon, couponInput, uniqReferenceId, mPricingCouponId, mPricingRulesId, removeDiscounts);
    return latestData;
  }

  if (pricingRule.type === "XGY" && (pricingRule.manualDiscount === "N" || pricingRule.manualDiscount === null) && !expiryDiscount && cuponFlag) {
    let latestData = await BuyXGetYDiscount(product, pricingRule, setCart, cart, orderType, cartObj, iscoupon, couponInput, uniqReferenceId, mPricingCouponId, mPricingRulesId, removeDiscounts);
    return latestData;
  }

  if (pricingRule.type === "QD" && (pricingRule.manualDiscount === "N" || pricingRule.manualDiscount === null) && !expiryDiscount && cuponFlag) {
    let latestData = await QuantityDiscount(product,pricingRule,setCart,cart,orderType,cartObj,iscoupon,couponInput,uniqReferenceId,mPricingCouponId,mPricingRulesId,removeDiscounts);
    return latestData;
  }

  if (pricingRule.type === "EXD" && (pricingRule.manualDiscount === "N" || pricingRule.manualDiscount === null) && expiryDiscount && product.weight > 0) {
    let enteredDate;
    const productAvailabilityIndex = pricingRule.mPricingExpiryDiscount.findIndex((item) => item.mProductId === product.productId && product.weight > 0);
    let offerIndex;

    if (productAvailabilityIndex !== -1) {
      offerIndex = productAvailabilityIndex;
    } else {
      offerIndex = pricingRule.mPricingExpiryDiscount.length > 0 ? pricingRule.mPricingExpiryDiscount.findIndex((op) => op.mProductId === null) : -1;
    }
    if (offerIndex >= 0) {
      Modal.confirm({
        title: "Enter Expiry Date",
        content: (
          <div>
            <Input type="date" placeholder="Select date" onChange={(e) => (enteredDate = e.target.value)} />
          </div>
        ),
        onOk: async () => {
          const currentDate = moment();
          const selectedDate = moment(enteredDate, "YYYY-MM-DD");
          const dateDifference = selectedDate.diff(currentDate, "days");
          await ExpiryDiscount(product, pricingRule, setCart, cart, orderType, cartObj,  dateDifference);
        },
        onCancel: () => {},
      });
    }
  }
};
