import React from "react";
import PointOfSaleCore from "../PointOfSale/Core";
import PointOfSaleRetail from "../PointOfSale/Retail";
import PointOfSaleRestaurant from "../PointOfSale/Restaurant";
import PointOfSaleMarket from "../PointOfSale/Retail/SuperMarket";
import Kiosk from "../PointOfSale/Retail/KioskComponent/index";

const PointOfSale = () => {
  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillaccess = JSON.parse(tillData?.tillAccess?.userAccessController);
  const tillLayout = parseInt(tillaccess?.layout === null || undefined ? 1 : tillaccess?.layout);
  return tillaccess.pos === "Y" ? <PointOfSaleCore component={tillLayout === 1 ? PointOfSaleRetail :tillLayout === 3 ? Kiosk : tillLayout === 4 ? PointOfSaleMarket : PointOfSaleRestaurant} /> : <h1>Access Denied</h1>;
};

export default PointOfSale;
