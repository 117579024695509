import React, { useState, useEffect, useRef } from "react";
import { Row, Button, Col, Checkbox, Card, Input, Space, Typography } from "antd";
import Scrollbars from "react-custom-scrollbars";
import InfiniteScroll from "react-infinite-scroll-component";
import { CloseOutlined, LoadingOutlined, StopOutlined, SearchOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons";
import SearchIcon from "../../../assets/images/searchIcon.svg";
import defaultImage from "../../../assets/images/no-image.svg";
import Back from "../../../assets/images/backIcon.svg";
import data from "../../../constants/restaurantPos.json";
import removeBg from "../../../assets/images/removebg.png";
import PlusIcon from "../../../assets/images/plusIconRestaurant.svg";
import order from "../../../assets/images/order.svg";

import "./restaurantStyle.css";
import moment from "moment";
import db from "../../../database";

const HomeDeliveryComponent = ({ setKeyValue, deleteCart, cart, userPreferences }) => {
  const { Text, Title } = Typography;
  const [takeAwayOrdes, setTakeAwayOrders] = useState([]);

  const tillData = JSON.parse(localStorage.getItem("tillData"));
  const tillaccess = JSON.parse(tillData.tillAccess.userAccessController);
  const currenciesList = tillData.tillAccess.csBunit.currencies;
  useEffect(() => {
    db.orders
      .filter((order) => order.typeOrder === "Y")
      .toArray()
      .then((filteredOrder) => {
        setTakeAwayOrders(filteredOrder);
      })
      .catch((error) => {
        console.error("Failed to filter products:", error);
      });
  }, [cart]);

  return (
    <div style={{ position: "relative", padding: "2vh 1.3vw", fontSize: "1vw" }}>
      <Row>
        <Col span={10} style={{ fontSize: "1.25em", fontWeight: "600", color: "#0F0718" }}>
          Home Delivery
        </Col>
      </Row>
      <Scrollbars autoHide style={{ height: "90vh" }}>
        <InfiniteScroll
          dataLength={takeAwayOrdes.length}
          hasMore={true}
          endMessage={
            <p className="center">
              <b>Yay! you have seen it all</b>
            </p>
          }
          style={{ overflowX: "hidden" }}
        >
          <Row
            style={{
              marginTop: "3vh",
              backgroundColor: "#fff",
              height: "87vh",
              borderRadius: "15px",
              padding: "1vh 0.5vw",
            }}
          >
            {takeAwayOrdes.map((orderData) => {
              return (
                <Col span={8} style={{ padding: "1vh 0.6vw" }}>
                  <Card
                    // onClick={() => setSelectedTable(orderData)}
                    style={{
                      backgroundColor: "#F3F4F9",
                      padding: "1vh 0.7vw",
                      borderRadius: "10px",
                      height: "18vh",
                      fontSize: "1vw",
                      cursor: "pointer",
                      borderColor: "#F3F4F9",
                    }}
                  >
                    {/* <Space
                      style={{
                        width: "100%",
                        paddingLeft: "7px",
                        paddingRight: "7px",
                      }}
                    ></Space> */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontSize: "1.1em",
                          fontWeight: 600,
                          color: "#0F0718",
                          fontFamily: "Inter",
                        }}
                      >
                        Order {orderData?.documentno || null}
                      </span>
                      <span
                        style={{
                          fontSize: "1em",
                          fontWeight: 600,
                          color: "#0F0718",
                          fontFamily: "Inter",
                        }}
                      >
                        Order {orderData?.customer?.name || null}
                      </span>
                      {orderData.items.length > 1 ? (
                        orderData.items.slice(0, 2).map((item) => (
                          <span
                            style={{
                              fontSize: "0.8em",
                              fontWeight: 500,
                              color: "#929098",
                              fontFamily: "Inter",
                            }}
                          >
                            Order {item.name || null} X {item.weight}
                          </span>
                        ))
                      ) : (
                        <span
                          style={{
                            fontSize: "0.8em",
                            fontWeight: 500,
                            color: "#929098",
                            fontFamily: "Inter",
                          }}
                        >
                          Order {orderData?.items[0]?.name || null} X {orderData?.items[0]?.weight}
                        </span>
                      )}
                      <span
                        style={{
                          fontSize: "1em",
                          fontWeight: 600,
                          color: "#0F0718",
                          fontFamily: "Inter",
                        }}
                      >
                        <img src={order} height="13" /> Order placed today at {moment(orderData.time).format(userPreferences.timeFormat)}
                      </span>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </InfiniteScroll>
      </Scrollbars>
      <Button
        onClick={() => {
          deleteCart();
          setKeyValue("2");
          localStorage.setItem("dineIn", "N");
          localStorage.setItem("homeDelivery", "Y");
        }}
        style={{
          position: "absolute",
          width: "8vw",
          height: "6.5vh",
          backgroundColor: "#2F3856",
          color: "#fff",
          borderRadius: "10px",
          bottom: "4vh",
          right: "2.5vw",
        }}
      >
        New Order
      </Button>
    </div>
  );
};

export default HomeDeliveryComponent;
