import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import local from "../constants/local.json";

const languageSelected = localStorage.getItem("languageSelected");

i18n.use(initReactI18next).init({
    lng: "language", // Provide the language code as a string
    fallbackLng: "language",
    resources: {
        language: { translation: local.english },
        // Hindi: { translation: hinTranslations },
        // Kannada: { translation: kannadaTranslations },
        // Tamil: { translation: tamilTranslations },
        // Bengali: { translation: bengaliTranslations },
        // Malayalam: { translation: malayalamTranslations }
    },
});

export default i18n;
