
import React, { useState } from "react";
import { Button, Form } from "antd";
import kisokLogo from "../../../../assets/images/kioskLogo.svg";
import Settings from "../../../../assets/images/settings.gif";
import Sync from "../../../../assets/images/dataSync.gif";
import Cashup from "../../../../assets/images/cashup.gif";
import volumeImg from "../../../../assets/images/volume.svg";
import wheelchairImg from "../../../../assets/images/wheelchair.svg";
import helpImg from "../../../../assets/images/help.svg";
import backImg from "../../../../assets/images/backImg.svg";
import db from "../../../../database";
import Axios from "axios";
import { getOAuthHeaders } from '../../../../constants/oAuthValidation';
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from 'react-router-dom';
import kioskBGI from "../../../../assets/images/kioskBGI.png"

const AdminComponent = (props) => {
    const {
        kioskUI,
        setKioskUI
    } = props;
  const [showLogin, setShowLogin] = useState(true);
  const history = useHistory();
  let tillData = JSON.parse(localStorage.getItem("tillData"));
  const serverUrl = process.env.REACT_APP_serverUrl;
  const [isLocked, setIsLocked] = useState(false);
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const colorVariable = process.env.REACT_APP_POSBACKGROUND_COLOR;
  const themeJSON = posConfig?.themeJSON && posConfig?.themeJSON !== "N" && Object.keys(posConfig.themeJSON).length > 0 ? JSON.parse(posConfig.themeJSON)?.layout3 : {};
  const isThemeLogo = themeJSON.logoURL;
  const logoURL = isThemeLogo ? themeJSON.logoURL : kisokLogo;

 const logoStyles = isThemeLogo
  ? { height: "8vh", position: "relative", cursor: "pointer",width:"5vw" } // Styles for theme logo
  : { height: "2.8vw", position: "relative", top: "2.3vh", left: "1.2vw", cursor: "pointer" }; // Styles for kisok logo

  const handleLoginClick = () => {
    console.log("Login button clicked");
    setShowLogin(false);
  };

  const mainDivStyles = themeJSON.appBgImageURL
  ? {
      backgroundImage: `url(${themeJSON.appBgImageURL})`,
      backgroundPosition: "100% 100%",
      backgroundSize: "cover",
      // backgroundRepeat: "no-repeat",
      height: "100vh",
      width: "100%",
      display: isLocked ? "none" : "block"
      
    }
  : {
      backgroundImage: `url(${kioskBGI})`,
      backgroundSize:"cover",
      backgroundPosition:"10% 10%",
      height:"100vh",
      width:"100%",
      position:"absolute",
    };


  return (
    <div style={mainDivStyles}>
        {/* className="customer-login" */}
        <div  >
    </div>
        
        
<div style={{ 
  height: "9vh", 
  display: "flex", 
  //   alignItems: "center",
  //   justifyContent: "space-between", 
  width: "100%", 
  padding: "0 1.2vw",
  paddingTop:"3vh" 
 }}>
  <img
    // src={themeJSON.logoURL || kisokLogo}
    alt="Kiosk Logo"
    src={logoURL} 
    style={logoStyles} 
  />
</div>
        {showLogin ? (
          <div style={{ 
            display: "flex", 
            alignItems: "center",
            paddingTop:"5vh", 
            flexDirection: "column",
           }}>
            <div>
              <p style={{ 
                color: "#fff", 
                textAlign: "center", 
                fontFamily: "Inter", 
                fontSize: "3vw", 
                fontWeight: "600", 
                margin: 0, 
                lineHeight: "1.2" 
               }}>
                WELCOME ADMIN
              </p>
              <p style={{ 
                color: "#929098", 
                textAlign: "center", 
                fontFamily: "Inter", 
                fontSize: "1.2vw", 
                fontWeight: "400", 
                marginTop: 0,   
                lineHeight: "2.7" 
               }}>
                Please sign in with your username and password
              </p>
            </div>
            < Form layout="vertical" name="kioskLogin" >
              <Form.Item
                name={"email"}
                label={<p 
                  style={{ margin: "0", 
                    color: "#fff", fontSize: "1.4vw" }}>Username</p>}
                style={{ marginBottom: "1rem" }} 
               >
                <input
                  inputMode="none"
                  className="ad-input-field"
                  autoFocus={true}
                  style={{width: "26.2rem", padding: "0.5rem", boxSizing: "border-box",outline:"none"}}
                />
              </Form.Item>
              <Form.Item
                name={"password"}
                label={<p style={{margin: "0", color: "#fff", fontSize: "1.4vw"}}>Password</p>}
                style={{ marginBottom: "1rem" }} 
               >
                <input
                  inputMode="none"
                  className="ad-input-field"
                  autoFocus={true}
                  style={{width: "26.2rem", padding: "0.5rem", boxSizing: "border-box",outline:"none"}} 
                />
              </Form.Item>
            </Form>
            <Button
              style={{ 
                // backgroundColor: "#EEC302",
                backgroundColor: themeJSON.btnsBgColor || "#EEC302", 
                width: "26.2rem", 
                height: "3.7rem", 
                borderRadius: "7px", 
                color: "#fff", 
                fontSize: "1.4rem", 
                fontWeight: "500",
                marginTop:"2vh"
              }}
              onClick={handleLoginClick}
             >
              Login
            </Button>

            <div className="left-bottom-images">
            <div className="bottom-img"
            //  onClick={()=>setKioskUI(0)} 
            onClick={() => {
              setKioskUI(0);
              const customEvent = new CustomEvent("customStorageChange", {
                detail: { key: "kioskUI", newValue: 0 },
              });
              window.dispatchEvent(customEvent);
              localStorage.setItem("kioskUI",0);
             }}
             >
              <img src={backImg} style={{height: "2.7vw"}} />
              <p style={{ color: "#fff", margin: "0" }}>back</p>
            </div>
            <div className="bottom-img">
              <img src={helpImg} style={{ height: "2.7vw" }} />
              <p style={{color:"#fff",margin:"0"}}>Help</p>
            </div>
           </div>
        </div>
         ) : (
          <div style={{ 
            display: "flex", 
            // justifyContent: "center", 
            alignItems: "center", 
            flexDirection: "column", 
            width: "100%", 
            height: "100%", 
            maxHeight: "90vh",
            paddingTop:"15vh" 
           }}>
            <div style={{ 
              display: "flex", 
              flexWrap: "wrap", 
              justifyContent: "center", 
              gap: "2%", 
              alignItems: "center", 
              width: "100%", 
              maxHeight: "90vh" 
             }}>
              <div
                style={{
                  height: "43vh",
                  width: "22vw",
                  // backgroundColor: "#626367",
                  backgroundColor: themeJSON.productTableBGColor || "#626367",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginRight: "2%",
                  borderRadius: "10px",
                  cursor: "pointer",
                  boxSizing: "border-box"
                }}
                // onClick={() => setShowLogin(true)}
                onClick={() => {
                    let posConfig = JSON.parse(localStorage.getItem("posConfig"));
                    if (posConfig.shiftClose === "Y") {
                      if (posConfig.showDenominations === "Y") {
                        history.push("/close-till");
                      } else if (posConfig.showDenominations === "N") {
                        history.push("/close-till-all");
                      }
                    } else {
                      history.push("/");
                    }
                    // handleKeyvalue("8");
                }}
               >
                <div style={{ height: "35%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img src={Cashup} alt="Cash Up Details" style={{ height: "15vh" }} />
                </div>
                <p
                  style={{
                    wordBreak: "break-word",
                    fontSize: "2vw",
                    color: "#fff",
                    width: "100%",
                    textAlign: "center",
                    lineHeight: "1.2",
                    position: "relative",
                    fontWeight: "500",
                    margin: "9%",
                    boxSizing: "border-box"
                  }}
                 >
                  Close Till
                </p>
              </div>
              <div
                style={{
                  height: "43vh",
                  width: "22vw",
                  // backgroundColor: "#626367",
                  backgroundColor: themeJSON.productTableBGColor || "#626367",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  marginRight: "2%",
                  borderRadius: "10px",
                  cursor: "pointer",
                  boxSizing: "border-box"
                }}
               >
                <div style={{ height: "35%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img src={Sync} alt="Data Sync" style={{ height: "15vh" }} />
                </div>
                <p
                  style={{
                    wordBreak: "break-word",
                    fontSize: "2vw",
                    color: "#fff",
                    width: "100%",
                    textAlign: "center",
                    lineHeight: "1.2",
                    position: "relative",
                    fontWeight: "500",
                    margin: "9%",
                    boxSizing: "border-box"
                  }}
                 >
                  Data Sync
                </p>
              </div>
              {/* <div
                style={{
                  height: "43vh",
                  width: "22vw",
                  backgroundColor: "#626367",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  borderRadius: "10px",
                  cursor: "pointer",
                  boxSizing: "border-box"
                }}
                // onClick={() => setShowLogin(true)}
               >
                <div style={{ height: "35%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <img src={Settings} alt="Kiosk Settings" style={{ height: "15vh" }} />
                </div>
                <p
                  style={{
                    wordBreak: "break-word",
                    fontSize: "2vw",
                    color: "#fff",
                    width: "100%",
                    textAlign: "center",
                    lineHeight: "1.2",
                    position: "relative",
                    fontWeight: "500",
                    margin: "9%",
                    boxSizing: "border-box"
                  }}
                 >
                  Kiosk Settings
                </p>
              </div> 
              */}
            </div>
            <div className="left-bottom-images"
               onClick={() => setShowLogin(true)}
             >
             <div className="bottom-img">
              <img src={backImg} style={{height: "2.7vw"}} />
              <p style={{ color: "#fff", margin: "0" }}>back</p>
             </div>
             <div className="bottom-img">
              <img src={helpImg} style={{height: "2.7vw"}} />
              <p style={{color: "#fff", margin: "0"}}>Help</p>
            </div>
          </div>
          </div>
        )}
      </div>    
  );
};

export default AdminComponent;







