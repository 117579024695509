const TableCustomer = ({ color }) => {
  return (
    <svg height="2.8vh" viewBox="0 0 30 30" fill={color} xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1_97)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20 17.5C21.6106 17.5001 23.159 18.1219 24.3222 19.2358C25.4855 20.3497 26.1739 21.8697 26.2438 23.4788L26.25 23.75V25C26.2502 25.6307 26.012 26.2382 25.5831 26.7007C25.1543 27.1632 24.5664 27.4464 23.9375 27.4938L23.75 27.5H6.25C5.61928 27.5002 5.01179 27.262 4.54931 26.8331C4.08684 26.4043 3.80355 25.8164 3.75625 25.1875L3.75 25V23.75C3.75009 22.1394 4.37193 20.591 5.48582 19.4278C6.59972 18.2645 8.11969 17.5761 9.72875 17.5063L10 17.5H20ZM20 20H10C9.04349 19.9999 8.12311 20.3654 7.42717 21.0216C6.73124 21.6778 6.31237 22.5751 6.25625 23.53L6.25 23.75V25H23.75V23.75C23.7501 22.7935 23.3846 21.8731 22.7284 21.1772C22.0722 20.4812 21.1749 20.0624 20.22 20.0062L20 20ZM15 2.5C16.6576 2.5 18.2473 3.15848 19.4194 4.33058C20.5915 5.50269 21.25 7.0924 21.25 8.75C21.25 10.4076 20.5915 11.9973 19.4194 13.1694C18.2473 14.3415 16.6576 15 15 15C13.3424 15 11.7527 14.3415 10.5806 13.1694C9.40848 11.9973 8.75 10.4076 8.75 8.75C8.75 7.0924 9.40848 5.50269 10.5806 4.33058C11.7527 3.15848 13.3424 2.5 15 2.5ZM15 5C14.5075 5 14.0199 5.097 13.5649 5.28545C13.11 5.47391 12.6966 5.75013 12.3483 6.09835C12.0001 6.44657 11.7239 6.85997 11.5355 7.31494C11.347 7.76991 11.25 8.25754 11.25 8.75C11.25 9.24246 11.347 9.73009 11.5355 10.1851C11.7239 10.64 12.0001 11.0534 12.3483 11.4017C12.6966 11.7499 13.11 12.0261 13.5649 12.2145C14.0199 12.403 14.5075 12.5 15 12.5C15.9946 12.5 16.9484 12.1049 17.6517 11.4017C18.3549 10.6984 18.75 9.74456 18.75 8.75C18.75 7.75544 18.3549 6.80161 17.6517 6.09835C16.9484 5.39509 15.9946 5 15 5Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1_97">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default TableCustomer;
