const SalesHistoryIcon = ({color}) => {
    return (
<svg width="1.8vw" height="1.5vw" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_916_77)">
<path d="M27.301 7.63536V27.1142C27.301 28.7054 26.0063 30 24.4151 30H11.2489C9.65773 30 8.36316 28.7054 8.36316 27.1142V14.4487C8.7748 14.5172 9.19472 14.5616 9.62567 14.5616C9.93128 14.5616 10.2312 14.5389 10.5275 14.5038V27.1142C10.5275 27.5119 10.851 27.8356 11.2489 27.8356H24.4151C24.8129 27.8356 25.1366 27.5119 25.1366 27.1142V7.63536C25.1366 7.23749 24.8128 6.91394 24.4151 6.91394H17.2734C17.2734 6.16152 17.1603 5.43634 16.9577 4.74966H24.4151C26.0063 4.7496 27.301 6.04417 27.301 7.63536ZM9.50516 13.6121C5.75214 13.6121 2.69904 10.559 2.69904 6.80612C2.6991 3.05311 5.75214 0 9.50516 0C13.2581 0 16.3113 3.05311 16.3113 6.80612C16.3113 10.559 13.2581 13.6121 9.50516 13.6121ZM9.50516 12.1692C12.4623 12.1692 14.8683 9.76358 14.8683 6.80612C14.8683 3.84849 12.4626 1.44291 9.50516 1.44291C6.54759 1.44291 4.14194 3.84849 4.14194 6.80612C4.14194 9.76358 6.54795 12.1692 9.50516 12.1692ZM13.587 7.52761C13.9853 7.52761 14.3085 7.20441 14.3085 6.80618C14.3085 6.40796 13.9853 6.08476 13.587 6.08476H10.2266V3.2527C10.2266 2.85447 9.90339 2.53127 9.50516 2.53127C9.10693 2.53127 8.78374 2.85447 8.78374 3.2527V6.80618C8.78374 7.20441 9.10693 7.52761 9.50516 7.52761H13.587ZM21.9137 13.377H14.0981C13.6999 13.377 13.3767 13.7002 13.3767 14.0984C13.3767 14.4967 13.6999 14.8198 14.0981 14.8198H21.9137C22.3121 14.8198 22.6351 14.4966 22.6351 14.0984C22.6352 13.7002 22.3121 13.377 21.9137 13.377ZM21.9264 17.7531H14.1108C13.7126 17.7531 13.3894 18.0761 13.3894 18.4745C13.3894 18.873 13.7126 19.196 14.1108 19.196H21.9264C22.3248 19.196 22.6478 18.873 22.6478 18.4745C22.6478 18.0761 22.3252 17.7531 21.9264 17.7531ZM21.9264 22.3104H14.1108C13.7126 22.3104 13.3894 22.6338 13.3894 23.0318C13.3894 23.4302 13.7126 23.7532 14.1108 23.7532H21.9264C22.3248 23.7532 22.6478 23.4302 22.6478 23.0318C22.6478 22.6337 22.3252 22.3104 21.9264 22.3104Z" fill={color}/>
</g>
<defs>
<clipPath id="clip0_916_77">
<rect width="30" height="30" fill={color}/>
</clipPath>
</defs>
</svg>


        

    )
}
export default SalesHistoryIcon;