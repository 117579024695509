import React, { useEffect,useState } from "react";
import { Row, Col, Button, Input, Form } from "antd";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import "antd/dist/antd.css";
import "./style.css";
import "./email.css";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import Logo from "../../../../assets/images/kioskLogo.svg";
import Email from "../../../../assets/images/email.svg";
import { LeftCircleOutlined } from "@ant-design/icons";
import kisokLogo from "../../../../assets/images/kioskLogo.svg";
import volumeImg from "../../../../assets/images/volume.svg";
import wheelchairImg from "../../../../assets/images/wheelchair.svg";
import helpImg from "../../../../assets/images/help.svg";
import backImg from "../../../../assets/images/backImg.svg";
import kioskBGI from "../../../../assets/images/kioskBGI.png"


const EmailComponent = (props) => {
  const posConfig = JSON.parse(localStorage.getItem("posConfig"));
  const themeJSON = posConfig?.themeJSON && posConfig?.themeJSON !== "N" && Object.keys(posConfig.themeJSON).length > 0 ? JSON.parse(posConfig.themeJSON)?.layout3 : {};
  const isThemeLogo = themeJSON.logoURL;
  const logoURL = isThemeLogo ? themeJSON.logoURL : kisokLogo;
  const {
    setKioskUI,
    layoutName,
    setLayoutName,
    onChange,
    // handleKeyboardInputs,
    keyboardRef,
    inputName,
    setInputFields,
    kioskLogin,
    inputRef,
    setInputName,
    handleCustomerSearch,
    setCustomerSearchInput,
    changeLoginType,
  } = props;
  //   const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [isLocked, setIsLocked] = useState(false);
  const keyBoardColor = themeJSON.keyBoardColor || "#fff"; 
  const isTheme = Boolean(themeJSON);

  const logoStyles = isThemeLogo
  ? { height: "8vh", position: "relative", top: "2.3vh", left: "1.2vw", cursor: "pointer",width:"5vw" } // Styles for theme logo
  : { height: "2.8vw", position: "relative", top: "2.3vh", left: "1.2vw", cursor: "pointer" }; // Styles for kisok logo

  const mainDivStyles = themeJSON.appBgImageURL
  ? {
      backgroundImage: `url(${themeJSON.appBgImageURL})`,
      backgroundPosition: "100% 100%",
      backgroundSize: "cover",
      height: "100vh",
      width: "100%",
      display: isLocked ? "none" : "block"
      
    }
  : {
      backgroundImage: `url(${kioskBGI})`,
      backgroundSize:"cover",
      backgroundPosition:"10% 10%",
      height:"100vh",
      width:"100%",
      position:"absolute",
    };

  //   useEffect(() => {
  //     const applyKeyStyles = (color, textColor) => {
  //       document.querySelectorAll(".hg-button").forEach((key) => {
  //         key.style.backgroundColor = color;
  //         key.style.color = "#000"; // Ensure text is readable
  //       });
  //     };
  //     setTimeout(() => {
  //       applyKeyStyles(keyBoardColor, isTheme ? "#FFF" : "#fff");
  //     }, 100);
  
      
  //     return () => {
  //       document.querySelectorAll(".hg-button").forEach((key) => {
  //         key.style.backgroundColor = "";
  //         key.style.color = "#fff";
  //       });
  //     };
  //   }, [keyBoardColor, isTheme]);

  //   // Sync input field with form and virtual keyboard
  // useEffect(() => {
  //   if (inputRef.current) {
  //     const handleInputChange = () => {
  //       const value = inputRef.current.value;
  //       setEmail(value);
  //       kioskLogin.setFieldsValue({ email: value });
  //       if (keyboardRef.current) {
  //         keyboardRef.current.setInput(value);
  //       }
  //     };

  //     inputRef.current.addEventListener('input', handleInputChange);
  //     return () => {
  //       inputRef.current.removeEventListener('input', handleInputChange);
  //     };
  //   }
  // }, [inputRef, kioskLogin, keyboardRef]);

  // const handleKeyboardInputs = (button) => {
  //   setEmail(prevEmail => {
  //     let updatedEmail = prevEmail;
      
  //     switch (button) {
  //       case '{bksp}':
  //         updatedEmail = updatedEmail.slice(0, -1);
  //         break;
  //       case '{enter}':
  //         handleCustomerSearch();
  //         return updatedEmail;
  //       case '{shift}':
  //         break;
  //       case '{space}':
  //         updatedEmail += ' ';
  //         break;
  //       case '{numbers}':
  //         break;
  //       default:
  //         updatedEmail += button;
  //         break;
  //     }
  
  //     setInputFields({ email: updatedEmail });
  //     kioskLogin.setFieldsValue({ email: updatedEmail });
  //     if (keyboardRef.current) {
  //       keyboardRef.current.setInput(updatedEmail);
  //     }
  //     return updatedEmail;
  //   });
  // };

  useEffect(() => {
    const applyKeyStyles = (color, textColor) => {
      document.querySelectorAll(".hg-button").forEach((key) => {
        key.style.backgroundColor = color;
        key.style.color = "#000"; 
      });
    };

    setTimeout(() => {
      applyKeyStyles(keyBoardColor, isTheme ? "#FFF" : "#fff");
    }, 100);

    return () => {
      document.querySelectorAll(".hg-button").forEach((key) => {
        key.style.backgroundColor = "";
        key.style.color = "#fff";
      });
    };
  }, [keyBoardColor, isTheme]);

  // Reset form fields and clear input fields on component mount
  useEffect(() => {
    kioskLogin.resetFields();
    setInputFields({ email: '' });
  }, [kioskLogin, setInputFields]);

  // Sync input field with form and virtual keyboard
  useEffect(() => {
    if (inputRef.current) {
      const handleInputChange = () => {
        const value = inputRef.current.value;
        setInputFields({ email: value });
        kioskLogin.setFieldsValue({ email: value });
        if (keyboardRef.current) {
          keyboardRef.current.setInput(value);
        }
      };

      inputRef.current.addEventListener('input', handleInputChange);
      return () => {
        inputRef.current.removeEventListener('input', handleInputChange);
      };
    }
  }, [inputRef, kioskLogin, keyboardRef, setInputFields]);

  const handleKeyboardInputs = (button) => {
    setInputFields(prevFields => {
      const updatedEmail = prevFields.email || '';
      let newEmail = updatedEmail;

      switch (button) {
        case '{bksp}':
          newEmail = newEmail.slice(0, -1);
          break;
        case '{enter}':
          handleCustomerSearch();
          return { email: newEmail };
        case '{space}':
          newEmail += ' ';
          break;
        case '{shift}':
        case '{numbers}':
        default:
          newEmail += button;
          break;
      }

      // Update input fields and keyboard
      kioskLogin.setFieldsValue({ email: newEmail });
      if (keyboardRef.current) {
        keyboardRef.current.setInput(newEmail);
      }

      return { email: newEmail };
    });
  };

    // useEffect(()=>{
    //   kioskLogin.resetFields()
    //   setInputFields({})
    //  },[])

    useEffect(() => {
      kioskLogin.resetFields();
      setInputFields({ email: '' }); // Clear input fields using setInputFields
    }, [kioskLogin, setInputFields]);

  return (
    <div style={mainDivStyles}>
      {/* <div className="customer-login">
      </div> */}
      <div style={{height:"9vh",display:"flex",justifyContent:"space-between"}}>
        <img
          src={logoURL} 
          style={logoStyles}
        />
        <img src={backImg} style={{paddingRight:"2vw",cursor:"pointer"}} onClick={() => {
            setKioskUI(0);
            const customEvent = new CustomEvent("customStorageChange", {
              detail: { key: "kioskUI", newValue: 0 },
            });
            window.dispatchEvent(customEvent);
            localStorage.setItem("kioskUI",0);
          }}/>
      </div>

      <div style={{ display: "flex", justifyContent: "end", alignItems: "center", flexDirection: "column", height: "50vh", paddingBottom: "3vh" }}>
        <div>
          <img src={Email} alt="email" className="email-icon" />
        </div>
        <Form layout="vertical" form={kioskLogin} name="kioskLogin">
          <Form.Item
            onFocus={() => {
              setInputName("email");
              setLayoutName("default");
            }}
            ref={inputRef}
            name={"email"}
            label={<p style={{ margin: "0", color: "#fff", fontSize: "1.6vw" }}>Email Address</p>}
           >
            <input
              inputMode="none"
              className="mc-input-field"
              autoFocus={true}
              // onChange={(e) => {
              //   setInputFields({ email: e.target.value });
              //   // setCustomerSearchInput(e.target.value);
              //   kioskLogin.setFieldsValue({ email: e.target.value });
              // }}
              onChange={(e) => {
                const value = e.target.value;
                setInputFields({ email: value });
                kioskLogin.setFieldsValue({ email: value });
                if (keyboardRef.current) {
                  keyboardRef.current.setInput(value);
                }
              }}
            />
          </Form.Item>
        </Form>
        <div className="mc-input-lable"></div>
        <div style={{ height: "1vh", fontSize: "2.5vh", color: "red" }}></div>
        <Button 
        // className="email-continue-btn"
        style={{
          backgroundColor: themeJSON.btnsBgColor || "#EEC302",
          // borderColor:"#EEC302",
          width:"26.2rem",
          height:"3.7rem",
          borderRadius:"7px",
          color:"#fff",
          fontSize:"1.4rem",
          fontWeight:500,
          marginTop:"0.5%"
        }}
        onClick={handleCustomerSearch}>
          Continue
        </Button>
      </div>

      <div className="email-kb-div">
        <Keyboard
          buttonTheme={[
            {
              class: "hg-yellow",
              buttons: "{space}",
            },
            {
              class: "hg-space",
              buttons: "{spaces}",
            },
            {
              class: "hg-mail",
              buttons: "@yahoo.com @gmail.com",
            },
          ]}
          keyboardRef={(r) => (keyboardRef.current = r)}
          inputName={inputName}
          // onChangeAll={(inputs) => {
          //   setInputFields({ ...inputs });
          //   // setCustomerSearchInput(inputs.email);
          //   kioskLogin.setFieldsValue(inputs);
          // }}
          onChange={(e) => {
            const value = e.target.value;
            setInputFields({ email: value });
            kioskLogin.setFieldsValue({ email: value });
            if (keyboardRef.current) {
              keyboardRef.current.setInput(value);
            }
          }}
          onKeyPress={handleKeyboardInputs}
          layoutName={layoutName}
          theme={"hg-theme-default email"}
          display={{
            "{abc}": "ABC",
            "{smileys}": "\uD83D\uDE03",
            "{numbers}": "123",
            "{number}": "123",
            "{bksp}": "⌫",
            "{backspace}": "⌫",
            "{shift}": "⇧",
            "{tab}": "tab",
            "{lock}": "lock",
            "{enter}": "enter",
            "{space}": "Space",
            "{spaces}": "Space",
            "{caps}": "ABC",
            "{small}": "abc",
            "{back}": "Enter",
          }}
          layout={{
            shift: ["q w e r t y u i o p", "a s d f g h j k l", "{shift} z x c v b n m {bksp}", "{number} {space}"],
            numbers: ["1 2 3 4 5 6 7 8 9 0", '- / : ; ( ) $ & @ "', "# . , ? ! ` {bksp}", "{caps} {space}"],
            number: ["1 2 3 4 5 6 7 8 9 0", '- / : ; ( ) $ & @ "', "# . , ? ! ` {bksp}", "{small} {space}"],
            default: ["Q W E R T Y U I O P", "A S D F G H J K L", "{shift} Z X C V B N M {bksp}", "{numbers} {space}"],
          }}
        />
      </div>
    </div>
  );
};

export default EmailComponent;
