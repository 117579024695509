import Axios from "axios";

const PoleDisplay = (PoleDisplayData, type,dueAmount) => {
    let data = "";
    if (type === "Quantity update") {
        data = `
            <line>
                <text align="left" length="15">${PoleDisplayData.name}</text>
                <text align="right" length="5">x${PoleDisplayData.weight}</text>
            </line>
            <line>
                <text align="right" length="20">${PoleDisplayData.realPrice}</text>
                <text align="right" length="20">${parseInt(PoleDisplayData.realPrice * PoleDisplayData.weight)}</text>
            </line>`;
    } else if (type === "Add item to cart") {
        data = `
            <line>
                <text align="left" length="15">${PoleDisplayData.name}</text>
                <text align="right" length="5">x${PoleDisplayData.weight}</text>
            </line>
            <line>
                <text align="right" length="20">${PoleDisplayData.realPrice}</text>
            </line>`;
    } else if (type === "Total to pay" || type === "Show due payment" || type === "Show payment success") {
        data = `
            <line>
                <text align="left" length="10">Total.</text>
                <text align="right" length="10">${PoleDisplayData.total}</text>
            </line>`;
        if (type === "Total to pay") {
            data += `
                <line>
                    <text align="left" length="10">Remaining to Pay</text>
                    <text align="right" length="10">${PoleDisplayData.total}</text>
                </line>`;
        } else if (type === "Show due payment") {
            data += `
                <line>
                    <text align="left" length="10">Remaining to Pay</text>
                    <text align="right" length="10">${dueAmount ? parseInt(PoleDisplayData.total - dueAmount) : ""}</text>
                </line>`;
        } else if (type === "Show payment success") {
            data += `
                <line>
                    <text align="center" length="20">Thank you.</text>
                </line>`;
        }
    }
    


    const tillData = JSON.parse(localStorage.getItem("tillData"));
    const printerURL = tillData.tillAccess.cwrTill.hardwareController.imageUrl;

    let PoleDisplayPayload = `<?xml version="1.0" encoding="UTF-8"?>
    <output>
        <display>
            ${data}
        </display>
    </output>`;
    Axios.post(`${printerURL}printer`, PoleDisplayPayload, {
        headers: {
            "Content-Type": "application/xml; charset=UTF-8",
            Accept: "application/xml",
        },
    })
        .then(() => {
            // console.log("openDrawer success!");
        })
        .catch((response) => {
            console.log("Error", response);
        });
}
export default PoleDisplay;