import db from "../../../database";
import { message, Modal } from "antd";
import i18next from "i18next";
import NotFoundImg from "../../../assets/images/NotFoundImg.svg";

export const barcodeScaner = (
  data,
  tillData,
  tillLayout,
  addDefinedProduct,
  setBatchSetAvailable,
  setDisplayBatchSelection,
  setLayoutType,
  setIsProductsVisible,
  setProductsData,
  chackBarcodeFlag,
  setSelectedProductInCart,
  setProductSearchInput,
  cart
) => {
  const t = i18next.t;
  if (data.length === 24) {
    rfidScaner(
      data,
      tillData,
      tillLayout,
      addDefinedProduct,
      setBatchSetAvailable,
      setDisplayBatchSelection,
      setLayoutType,
      setIsProductsVisible,
      setProductsData,
      chackBarcodeFlag,
      setSelectedProductInCart,
      setProductSearchInput,
      cart
    );
  } else {
    if (tillData.posScanConfigs.length === 0 || tillData.posScanConfigs === null || chackBarcodeFlag) {
      db.products
        .filter((product) => product.name.toLowerCase().includes(data.toLowerCase()) || product.upcIndex === data || product.upc === data || product.value === data)
        .limit(100)
        .toArray()
        .then(async (product) => {
          if (product.length === 1) {
            const obj = product[0];
            if (tillLayout === 3) {
              localStorage.setItem("layoutType", 1);
              setLayoutType(1);
              const customEvent = new CustomEvent("customStorageChange", {
                detail: { key: "layoutType", newValue: 1 },
              });
              window.dispatchEvent(customEvent);
            }
            if (obj.batchedProduct === "Y" && obj.batchedForSale === "Y") {
              const batchProductIndex = obj.mBatch.findIndex((bp) => bp.batchno.toLowerCase() === data.toLowerCase());
              if (batchProductIndex >= 0 && obj.multiPrice === "N") {
                addDefinedProduct(
                  obj,
                  obj.mBatch[batchProductIndex].upc,
                  obj.mBatch[batchProductIndex].batchno,
                  obj.mBatch[batchProductIndex].mBatchId,
                  obj.mBatch[batchProductIndex].price
                );
              } else if (obj.mBatch.length === 1) {
                addDefinedProduct(obj, obj.mBatch[0].upc, obj.mBatch[0].batchno, obj.mBatch[0].mBatchId, obj.mBatch[0].price);
              } else {
                // let upcKeys = 0;
                const productSet = [];
                const localObj = obj;
                for (let i = 0; i < obj.mBatch.length; i += 1) {
                  const batchItem = { ...localObj.mBatch[i] };
                  const obj = { ...localObj };
                  if (obj.overRideTax === "Y" && batchItem.price <= obj.overRideCondition) {
                    // prettier-ignore
                    const originalPrice = batchItem.price - (batchItem.price - (batchItem.price * (100 / (100 + obj.taxRate))));
                    const taxedPrice = originalPrice + (originalPrice * obj.contraRate) / 100;
                    batchItem.price = taxedPrice;
                    obj.cTaxId = obj.contraTaxId;
                    obj.taxRate = obj.contraRate;
                  }
                  const productDefined = {
                    batchno: batchItem.batchno,
                    description: obj.description,
                    discount: 0,
                    discountName: "",
                    imageurl: obj.imageurl,
                    isDecimal: obj.isDecimal,
                    isManualQty: obj.isManualQty,
                    isPromoApplicable: obj.isPromoApplicable,
                    isReturn: false,
                    mBatchId: batchItem.mBatchId,
                    mPricingruleId: null,
                    name: obj.name,
                    nettotal: 0,
                    primaryOrderLine: null,
                    productId: obj.mProductId,
                    realPrice: obj.cTaxId,
                    listPrice: obj.slistprice,
                    sunitprice: obj.sunitprice,
                    returnQty: null,
                    salePrice: obj.cTaxId,
                    mrpPrice: batchItem.listPrice,
                    stock: obj.onhandQty,
                    taxCategory: obj.taxCategory,
                    tax: obj.cTaxId,
                    taxAmount: 0,
                    taxRate: obj.taxRate,
                    uom: obj.csUomId,
                    uom_name: obj.uomName,
                    isDecimalQty: obj.uomData[0]?.decimal === "Y" ? true : false,
                    isQtyDesimal: obj.uomData[0]?.stdprecision ? obj.uomData[0]?.stdprecision : 2,
                    upc: batchItem.upc,
                    value: obj.value,
                    weight: 0,
                    shortDescription: obj.shortDescription,
                    hsncode: obj.hsncode,
                    csBunitId: obj.csBunitId,
                    mProductCategoryId: obj.mProductCategoryId,
                    productManufacturerId: obj.productManufacturerId,
                    productBrandId: obj.productBrandId,
                    batchedProduct: obj.batchedProduct,
                    batchedForSale: obj.batchedForSale,
                    batchedForStock: obj.batchedForStock,
                    multiPrice: obj.multiPrice,
                    shelfLife: obj.shelfLife,
                  };
                  // setSelectedProductInCart(productDefined);
                  // if (batchItem.upc.toLowerCase() === data.toLowerCase()) upcKeys += 1;
                  productSet.push(productDefined);
                }
                setBatchSetAvailable([...productSet]);
                setDisplayBatchSelection(true);
              }
            } else {
              let modifiedQty = obj.weight >= 0 ? obj.weight + 1 : 1;
              addDefinedProduct(obj, obj.upc, null, null, obj.sunitprice, true, modifiedQty, true);
            }
          } else if (product.length > 1) {
            setIsProductsVisible(true);
            setProductsData([...product]);
          } else {
            await upcPricingRule(data, t, addDefinedProduct, setProductSearchInput);
          }
        });
    } else {
      barcodeScanFromConfig(
        data,
        tillData,
        tillLayout,
        addDefinedProduct,
        setBatchSetAvailable,
        setDisplayBatchSelection,
        setLayoutType,
        setIsProductsVisible,
        setProductsData,
        setSelectedProductInCart,
        setProductSearchInput
      );
    }
  }
};

const getProduct = (
  formula,
  newData,
  tillData,
  data,
  tillLayout,
  addDefinedProduct,
  setBatchSetAvailable,
  setDisplayBatchSelection,
  setLayoutType,
  setIsProductsVisible,
  setProductsData,
  setSelectedProductInCart,
  setProductSearchInput
) => {
  const productValue = newData.substr(Number(formula.property["product.value"]?.position - 1), Number(formula.property["product.value"]?.length));
  const operators = ["+", "-", "/", "*"];
  const operatorFunctions = {
    "+": (a, b) => a + b,
    "-": (a, b) => a - b,
    "*": (a, b) => a * b,
    "/": (a, b) => a / b,
  };
  db.products
    .where("value")
    .equalsIgnoreCase(productValue)
    .toArray()
    .then((product) => {
      console.log(product);
      const uomData = product[0]?.uomData[0];
      const currenciesList = tillData.tillAccess.csBunit.currencies;
      let cartlineQty;
      if (formula.property["cartline.qty"] !== undefined) {
        if (typeof formula.property["cartline.qty"] === "number") {
          cartlineQty = formula.property["cartline.qty"];
        } else if (typeof formula.property["cartline.qty"] === "string") {
          const foundOperator = operators.find((op) => formula.property["cartline.qty"].includes(op));
          const splitArr = formula.property["cartline.qty"].split(foundOperator);
          let qtyArr = splitArr.map((splitItem) => {
            let value;
            if (splitItem.includes("cartline")) {
              if (splitItem.includes("qty")) {
                if (typeof formula.property["cartline.qty"] === "number") {
                  value = formula.property["cartline.qty"];
                } else if (typeof formula.property["cartline.qty"] === "object") {
                  value = parseFloat(
                    (
                      Number(newData.substr(Number(formula.property["cartline.qty"]?.position - 1), Number(formula.property["cartline.qty"]?.length))) /
                      (uomData && uomData?.decimal === "Y" ? Math.pow(10, uomData.stdPrecision) : 1000)
                    ).toFixed(uomData && uomData?.decimal === "Y" ? uomData.stdPrecision : 3)
                  );
                }
              } else if (splitItem.includes("total")) {
                if (typeof formula.property["cartline.total"] === "number") {
                  value = formula.property["cartline.total"];
                } else if (typeof formula.property["cartline.total"] === "object") {
                  value = parseFloat(
                    (
                      Number(newData.substr(Number(formula.property["cartline.total"]?.position - 1), Number(formula.property["cartline.total"]?.length))) /
                      (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
                    ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
                  );
                }
              } else if (splitItem.includes("unitprice")) {
                if (typeof formula.property["cartline.unitprice"] === "number") {
                  value = formula.property["cartline.unitprice"];
                } else if (typeof formula.property["cartline.unitprice"] === "object") {
                  value = parseFloat(
                    (
                      Number(newData.substr(Number(formula.property["cartline.unitprice"]?.position - 1), Number(formula.property["cartline.unitprice"]?.length))) /
                      (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
                    ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
                  );
                }
              }
            } else {
              value = product[0].sunitprice;
            }
            return value;
          });
          cartlineQty = operatorFunctions[foundOperator](qtyArr[0], qtyArr[1]);
        } else if (typeof formula.property["cartline.qty"] === "object") {
          cartlineQty = parseFloat(
            (
              Number(newData.substr(Number(formula.property["cartline.qty"]?.position - 1), Number(formula.property["cartline.qty"]?.length))) /
              (uomData && uomData?.decimal === "Y" ? Math.pow(10, uomData.stdPrecision) : 1000)
            ).toFixed(uomData && uomData?.decimal === "Y" ? uomData.stdPrecision : 3)
          );
        }
      }
      let cartlineUnitprice;
      if (formula.property["cartline.unitprice"] !== undefined) {
        if (typeof formula.property["cartline.unitprice"] === "number") {
          cartlineUnitprice = formula.property["cartline.unitprice"];
        } else if (typeof formula.property["cartline.unitprice"] === "string") {
          const foundOperator = operators.find((op) => formula.property["cartline.unitprice"].includes(op));
          const splitArr = formula.property["cartline.unitprice"].split(foundOperator);
          let unitpriceArr = splitArr.map((splitItem) => {
            let value;
            if (splitItem.includes("cartline")) {
              if (splitItem.includes("qty")) {
                if (typeof formula.property["cartline.qty"] === "number") {
                  value = formula.property["cartline.qty"];
                } else if (typeof formula.property["cartline.qty"] === "object") {
                  value = parseFloat(
                    (
                      Number(newData.substr(Number(formula.property["cartline.qty"]?.position - 1), Number(formula.property["cartline.qty"]?.length))) /
                      (uomData && uomData?.decimal === "Y" ? Math.pow(10, uomData.stdPrecision) : 1000)
                    ).toFixed(uomData && uomData?.decimal === "Y" ? uomData.stdPrecision : 3)
                  );
                }
              } else if (splitItem.includes("total")) {
                if (typeof formula.property["cartline.total"] === "number") {
                  value = formula.property["cartline.total"];
                } else if (typeof formula.property["cartline.total"] === "object") {
                  value = parseFloat(
                    (
                      Number(newData.substr(Number(formula.property["cartline.total"]?.position - 1), Number(formula.property["cartline.total"]?.length))) /
                      (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
                    ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
                  );
                }
              } else if (splitItem.includes("unitprice")) {
                if (typeof formula.property["cartline.unitprice"] === "number") {
                  value = formula.property["cartline.unitprice"];
                } else if (typeof formula.property["cartline.unitprice"] === "object") {
                  value = parseFloat(
                    (
                      Number(newData.substr(Number(formula.property["cartline.unitprice"]?.position - 1), Number(formula.property["cartline.unitprice"]?.length))) /
                      (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
                    ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
                  );
                }
              }
            } else {
              value = product[0].sunitprice;
            }
            return value;
          });
          cartlineUnitprice = operatorFunctions[foundOperator](unitpriceArr[0], unitpriceArr[1]);
        } else {
          cartlineUnitprice = parseFloat(
            (
              Number(newData.substr(Number(formula.property["cartline.unitprice"]?.position - 1), Number(formula.property["cartline.unitprice"]?.length))) /
              (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
            ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
          );
        }
      }
      let cartlineTotal;
      if (formula.property["cartline.total"] !== undefined) {
        if (typeof formula.property["cartline.total"] === "number") {
          cartlineTotal = formula.property["cartline.total"];
        } else if (typeof formula.property["cartline.total"] === "string") {
          const foundOperator = operators.find((op) => formula.property["cartline.total"].includes(op));
          const splitArr = formula.property["cartline.total"].split(foundOperator);
          let totalArr = splitArr.map((splitItem) => {
            let value;
            if (splitItem.includes("cartline")) {
              if (splitItem.includes("qty")) {
                if (typeof formula.property["cartline.qty"] === "number") {
                  value = formula.property["cartline.qty"];
                } else if (typeof formula.property["cartline.qty"] === "object") {
                  value = parseFloat(
                    (
                      Number(newData.substr(Number(formula.property["cartline.qty"]?.position - 1), Number(formula.property["cartline.qty"]?.length))) /
                      (uomData && uomData?.decimal === "Y" ? Math.pow(10, uomData.stdPrecision) : 1000)
                    ).toFixed(uomData && uomData?.decimal === "Y" ? uomData.stdPrecision : 3)
                  );
                }
              } else if (splitItem.includes("total")) {
                if (typeof formula.property["cartline.total"] === "number") {
                  value = formula.property["cartline.total"];
                } else if (typeof formula.property["cartline.total"] === "object") {
                  value = parseFloat(
                    (
                      Number(newData.substr(Number(formula.property["cartline.total"]?.position - 1), Number(formula.property["cartline.total"]?.length))) /
                      (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
                    ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
                  );
                }
              } else if (splitItem.includes("unitprice")) {
                if (typeof formula.property["cartline.unitprice"] === "number") {
                  value = formula.property["cartline.unitprice"];
                } else if (typeof formula.property["cartline.unitprice"] === "object") {
                  value = parseFloat(
                    (
                      Number(newData.substr(Number(formula.property["cartline.unitprice"]?.position - 1), Number(formula.property["cartline.unitprice"]?.length))) /
                      (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
                    ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
                  );
                }
              }
            } else {
              value = product[0].sunitprice;
            }
            return value;
          });
          cartlineTotal = operatorFunctions[foundOperator](totalArr[0], totalArr[1]);
        } else {
          cartlineTotal = parseFloat(
            (
              Number(newData.substr(Number(formula.property["cartline.total"]?.position - 1), Number(formula.property["cartline.total"]?.length))) /
              (currenciesList[0]?.stdPrecision ? Math.pow(10, currenciesList[0].stdPrecision) : 100)
            ).toFixed(currenciesList[0].stdPrecision ? currenciesList[0].stdPrecision : 2)
          );
        }
      }

      if (product.length <= 0) {
        let chackBarcodeFlag = true;
        barcodeScaner(
          data,
          tillData,
          tillLayout,
          addDefinedProduct,
          setBatchSetAvailable,
          setDisplayBatchSelection,
          setLayoutType,
          setIsProductsVisible,
          setProductsData,
          chackBarcodeFlag,
          setSelectedProductInCart,
          setProductSearchInput
        );
      } else {
        addDefinedProduct(
          product[0],
          product[0].upc,
          null,
          null,
          cartlineUnitprice ? cartlineUnitprice : product[0].sunitprice,
          true,
          cartlineQty ? cartlineQty : 1,
          false,
          cartlineTotal
        );
      }
    });
};

const barcodeScanFromConfig = (
  data,
  tillData,
  tillLayout,
  addDefinedProduct,
  setBatchSetAvailable,
  setDisplayBatchSelection,
  setLayoutType,
  setIsProductsVisible,
  setProductsData,
  setSelectedProductInCart,
  setProductSearchInput
) => {
  let newData = data.toString();
  let productFlag;
  let posConfig = tillData.posScanConfigs.filter((item) => {
    if (item.formula !== null) {
      if (item.scanTrxType === "PR" && item.barcodeLength === newData.length) {
        return item;
      }
    }
  });
  if (posConfig.length === 1) {
    const formula = JSON.parse(posConfig[0]?.formula);
    getProduct(
      formula,
      newData,
      tillData,
      data,
      tillLayout,
      addDefinedProduct,
      setBatchSetAvailable,
      setDisplayBatchSelection,
      setLayoutType,
      setIsProductsVisible,
      setProductsData,
      setSelectedProductInCart,
      setProductSearchInput
    );
    productFlag = true;
  } else {
    const posConfigItem = posConfig.filter((item) => data.startsWith(item.startsWith));
    if (posConfigItem.length > 0) {
      productFlag = true;
      const formula = JSON.parse(posConfigItem[0]?.formula);
      getProduct(
        formula,
        newData,
        tillData,
        data,
        tillLayout,
        addDefinedProduct,
        setBatchSetAvailable,
        setDisplayBatchSelection,
        setLayoutType,
        setIsProductsVisible,
        setProductsData,
        setSelectedProductInCart,
        setProductSearchInput
      );
    }
  }

  if (!productFlag) {
    let chackBarcodeFlag = true;
    barcodeScaner(
      data,
      tillData,
      tillLayout,
      addDefinedProduct,
      setBatchSetAvailable,
      setDisplayBatchSelection,
      setLayoutType,
      setIsProductsVisible,
      setProductsData,
      chackBarcodeFlag,
      setSelectedProductInCart,
      setProductSearchInput
    );
  }
};

const rfidScaner = (
  data,
  tillData,
  tillLayout,
  addDefinedProduct,
  setBatchSetAvailable,
  setDisplayBatchSelection,
  setLayoutType,
  setIsProductsVisible,
  setProductsData,
  chackBarcodeFlag,
  setSelectedProductInCart,
  setProductSearchInput,
  cart
) => {
  db.rfidData
    .where("tag_value")
    .equalsIgnoreCase(data)
    .toArray()
    .then((item) => {
      if (item.length > 0) {
        const scannedProductCode = item[0].product_code;
        const existingIndex = cart?.items?.findIndex((item) => item.value === scannedProductCode);
        if (existingIndex === -1) {
          barcodeScaner(
            item[0].product_code,
            tillData,
            tillLayout,
            addDefinedProduct,
            setBatchSetAvailable,
            setDisplayBatchSelection,
            setLayoutType,
            setIsProductsVisible,
            setProductsData,
            chackBarcodeFlag,
            setSelectedProductInCart,
            setProductSearchInput,
            cart
          );
          db.rfidData.update(item[0].tag_value, { tag_status: "SO" });
          if (tillLayout === 3) {
            localStorage.setItem("layoutType", 1);
            setLayoutType(1);
            const customEvent = new CustomEvent("customStorageChange", {
              detail: { key: "layoutType", newValue: 1 },
            });
            window.dispatchEvent(customEvent);
          }
        } else {
          message.info("Product Already Scanned");
        }
      }
    });
};

const upcPricingRule = async (data, t, addDefinedProduct, setProductSearchInput) => {
  const pr = await db.pricingRules.toArray();
  let productFlag = false;

  for (const ele of pr) {
    if (ele.upc === data) {
      const pro = await db.products.where("mProductId").equalsIgnoreCase(ele.mPricingXProducts[0].mProductId).toArray();

      if (pro.length > 0) {
        pro[0].upcPricingRule = ele.upc;
        productFlag = true;
        addDefinedProduct(pro[0], pro[0].upc, null, null, pro[0].sunitprice, true, ele.mPricingXProducts[0].quantity);
      }
    }
  }

  if (!productFlag) {
    // message.warning(`${t('Product not found')}`);
    Modal.info({
      icon: null,
      okButtonProps: { style: { display: "none" } },
      okText: "Go Back",
      content: (
        <div
          style={{
            fontSize: "18px",
            fontWeight: "500",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: 0,
            fontSize: "1vw",
          }}
        >
          <img src={NotFoundImg} alt="Not Found" style={{ width: "120px", height: "100px", marginBottom: "5px", marginTop: "10px" }} />
          <p style={{ margin: "5px 0", fontSize: "2em", fontWeight: "600", color: "#0F0718" }}>Product Not Found</p>
          <hr style={{ width: "70%", color: "rgba(146,144,152,0.5" }} />
          <p style={{ color: "#0F0718", fontWeight: 400, fontSize: "1.25em", padding: "15px 15px 0 15px" }}>
            The scanned product is not in the system. Please check the barcode or enter the item manually.
          </p>
        </div>
      ),
      style: {
        textAlign: "center",
        height: "50vh",
        width: "50%",
      },
      bodyStyle: {
        padding: "0",
      },
      closable: false,
      onOk: null,
    });

    setTimeout(() => {
      Modal.destroyAll();
    }, 1500);
  }
  setProductSearchInput("");
};
